<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="9"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="申报记录"></Title>
          <!-- 标题 -->
          <div class="sub-title">基本信息</div>
          <!-- 表框 -->
          <!-- <el-form ref="form" :model="form" :rules="rules"> -->
          <el-form ref="form" :model="detail">
            <div class="form">
              <div class="form-item percent">
                <span>企业名称：</span>
                <el-form-item class="form-item-content">
                  {{ detail.subjectName }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span>法人姓名：</span>
                <el-form-item class="form-item-content" prop="status">
                  {{ detail.legalerName }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span>法人联系电话：</span>
                <el-form-item class="form-item-content" prop="leader">
                  {{ detail.legalerPhone }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span>申请人：</span>
                <el-form-item class="form-item-content">
                  {{ detail.applyBy }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span>申请人联系电话：</span>
                <el-form-item class="form-item-content">
                  {{ detail.phonenumber }}
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>申请人联系地址：</span>
                <el-form-item class="form-item-content" prop="baseName">
                  {{ detail.address }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span>状态：</span>
                <el-form-item class="form-item-content">
                  {{ textLabel(detail.status) }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span>申报时间：</span>
                <el-form-item class="form-item-content">
                  {{ detail.updateTime }}
                </el-form-item>
              </div>
            </div>
            <div class="sub-title position">申报所需材料</div>
            <div>
              <el-table border :data="Attachment">
                <el-table-column
                  type="index"
                  label="序号"
                  width="50"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="name" label="材料名称">
                </el-table-column>
                <el-table-column prop="suffix" label="材料格式">
                </el-table-column>
                <el-table-column prop="attachmentType" label="材料类型">
                  <template slot-scope="scope">
                    {{ forattachmentType(scope.row.attachmentType) }}
                  </template>
                </el-table-column>
                <el-table-column prop="isNeed" label="是否必需">
                  <template slot-scope="scope">
                    <div>{{ scope.row.isNeed == "Y" ? "是" : "否" }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="documentNumber"
                  label="已上传材料"
                  width="200"
                >
                  <template slot-scope="scope">
                    <div class="textName">
                      <div>
                        {{
                          attachDataDetail(scope.row.policyAttachmentId).name
                        }}
                      </div>
                      <a
                        :download="
                          attachDataDetail(scope.row.policyAttachmentId).name
                        "
                        :href="
                          '/chainer' +
                            attachDataDetail(scope.row.policyAttachmentId).url
                        "
                      >
                        <i class="el-icon-download"></i>
                      </a>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList(this.$route.query.id);
    this.getAttachData(this.$route.query.id, this.$route.query.pageId);
    this.getText();
    this.getAttachmentType();
    this.getAttachment();
  },
  data() {
    return {
      detail: {},
      detailLabel: [],
      attachData: [],
      Attachment: [],
      list: []
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/support/apply/getApply",
        method: "post",
        data: {
          acceptId: id
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    },
    getAttachData(id) {
      this.$axios({
        url: "/p/support/applyAttachment/getList",
        method: "post",
        data: { applyId: id }
      }).then(res => {
        this.attachData = res.data.data.rows;
      });
    },
    attachDataDetail(id) {
      for (var i = 0; i < this.attachData.length; i++) {
        if (this.attachData[i].policyAttachmentId == id) {
          return { name: this.attachData[i].name, url: this.attachData[i].url };
          // this.list = this.Attachment.name + this.Attachment.url;
          // console.log(this.list);
          // return this.list;
        }
      }
    },
    getAttachment() {
      this.$axios({
        url: "/p/support/attachment/getList",
        method: "post",
        data: {
          policyId: this.$route.query.pageId
        }
      }).then(res => {
        this.Attachment = res.data.data.rows;
      });
    },
    getText() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "support_policy_accept_status"
        }
      }).then(res => {
        this.detailLabel = res.data.data;
      });
    },
    textLabel(vaule) {
      for (var i = 0; i < this.detailLabel.length; i++) {
        if (this.detailLabel[i].dictValue === vaule) {
          return this.detailLabel[i].dictLabel;
        }
      }
    },
    handleBack() {
      this.$router.back();
    },
    getAttachmentType() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "policy_apply_attachment_type"
        }
      }).then(res => {
        this.attachmentType = res.data.data;
      });
    },
    forattachmentType(value) {
      for (var i = 0; i < this.attachmentType.length; i++) {
        if (this.attachmentType[i].dictValue === value) {
          return this.attachmentType[i].dictLabel;
        }
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.sub-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 8px;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.textName {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textName div {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
