import Vue from "vue";
import Router from "vue-router";
import Index from "./components/Index.vue";
import Home from "./components/Home.vue";
import News from "./components/News.vue";
import Special from "./components/Special.vue";
import Specialtext from "./components/Specialtext.vue";
import Policy from "./components/Policy.vue";

import Disclosure from "./components/Disclosure.vue";
import Agriculture from "./components/Agriculture.vue";
import Explicit from "./components/Explicit.vue";
import Chain from "./components/Chain.vue";
import ChainView from "./components/ChainView.vue";
import Login from "./pages/Login.vue";
import Complaint from "./pages/Complaint.vue";
import ComplaintList from "./pages/ComplaintList.vue";
import ComplaintAdd from "./pages/ComplaintAdd.vue";
import Register from "./pages/Register.vue";
import Basic from "./pages/message/Basic.vue";
import Mail from "./pages/message/Mail.vue";
import MailList from "./pages/message/MailList.vue";
import Project from "./pages/message/Project.vue";
import ProjectList from "./pages/message/ProjectList.vue";
import ProjectAdd from "./pages/message/ProjectAdd.vue";
import ProjectEdit from "./pages/message/ProjectEdit.vue";
import Base from "./pages/message/Base.vue";
import BaseEdit from "./pages/message/BaseEdit.vue";
import BaseAdd from "./pages/message/BaseAdd.vue";
import BaseList from "./pages/message/BaseList.vue";
import Sales from "./pages/message/Sales.vue";
import SalesList from "./pages/message/SalesList.vue";
import SalesAdd from "./pages/message/SalesAdd.vue";
import SalesEdit from "./pages/message/SalesEdit.vue";
import ReportAdd from "./pages/message/ReportAdd.vue";
import Report from "./pages/message/Report.vue";
import ReportList from "./pages/message/ReportList.vue";
import ReportEdit from "./pages/message/ReportEdit.vue";
import Product from "./pages/message/Product.vue";
import ProductAdd from "./pages/message/ProductAdd.vue";
import ProductList from "./pages/message/ProductList.vue";
import ProductEdit from "./pages/message/ProductEdit.vue";
import Answering from "./pages/message/Answering.vue";
import AnsweringAdd from "./pages/message/AnsweringAdd.vue";
import AnsweringList from "./pages/message/AnsweringList.vue";
import Support from "./pages/message/Support.vue";
import SupportList from "./pages/message/SupportList.vue";
import SupportRecord from "./pages/message/SupportRecord.vue";
import SupportRecordList from "./pages/message/SupportRecordList.vue";
import SupportRecordAdd from "./pages/message/SupportRecordAdd.vue";
import Demand from "./pages/message/Demand.vue";
import DemandAdd from "./pages/message/DemandAdd.vue";
import DemandEdit from "./pages/message/DemandEdit.vue";
import DemandList from "./pages/message/DemandList.vue";

import Auth from "./pages/userCenter/Auth.vue";
import Dynamic from "./pages/three/Dynamic.vue";

import Camera from "./pages/message/Camera.vue"
import CameraList from "./pages/message/CameraList.vue"

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      component: Index,
      children: [
        { path: "/", component: Home },
        { path: "/News", component: News },
        { path: "/Special", component: Special },
        { path: "/Specialtext", component: Specialtext },
        { path: "/Policy", component: Policy },
        { path: "/Disclosure", component: Disclosure },
        { path: "/Agriculture", component: Agriculture },
        { path: "/Explicit", component: Explicit },
        { path: "/Chain", component: Chain },
        { path: "/ChainView", component: ChainView },
        { path: "/Login", component: Login },
        { path: "/Register", component: Register },
        { path: "/Complaint", component: Complaint },
        { path: "/Complaint/ComplaintList", component: ComplaintList },
        { path: "/Complaint/ComplaintAdd", component: ComplaintAdd },
        // 子页面
        { path: "/Basic", component: Basic },
        { path: "/Mail", component: Mail },
        { path: "/Mail/MailList", component: MailList },

        { path: "/Project", component: Project },
        { path: "/Project/ProjectList", component: ProjectList },
        { path: "/Project/ProjectAdd", component: ProjectAdd },
        { path: "/Project/ProjectEdit", component: ProjectEdit },

        { path: "/Base", component: Base },
        { path: "/Base/BaseEdit", component: BaseEdit },
        { path: "/Base/BaseAdd", component: BaseAdd },
        { path: "/Base/BaseList", component: BaseList },

        { path: "/Sales", component: Sales },
        { path: "/Sales/SalesList", component: SalesList },
        { path: "/Sales/SalesAdd", component: SalesAdd },
        { path: "/Sales/SalesEdit", component: SalesEdit },

        { path: "/Report", component: Report },
        { path: "/Report/ReportAdd", component: ReportAdd },
        { path: "/Report/ReportList", component: ReportList },
        { path: "/Report/ReportEdit", component: ReportEdit },

        { path: "/Product", component: Product },
        { path: "/Product/ProductAdd", component: ProductAdd },
        { path: "/Product/ProductList", component: ProductList },
        { path: "/Product/ProductEdit", component: ProductEdit },

        { path: "/Answering", component: Answering },
        { path: "/Answering/AnsweringAdd", component: AnsweringAdd },
        { path: "/Answering/AnsweringList", component: AnsweringList },

        { path: "/Support", component: Support },
        { path: "/Support/SupportList", component: SupportList },
        { path: "/Support/SupportRecord", component: SupportRecord },
        {
          path: "/Support/SupportRecord/SupportRecordList",
          component: SupportRecordList
        },
        {
          path: "/Support/SupportRecord/SupportRecordAdd",
          component: SupportRecordAdd
        },

        { path: "/Demand", component: Demand },
        { path: "/Demand/DemandAdd", component: DemandAdd },
        { path: "/Demand/DemandEdit", component: DemandEdit },
        { path: "/Demand/DemandList", component: DemandList },

        { path: "/UserCenter/Auth", component: Auth },
        { path: "/Dynamic", component: Dynamic },

        { path: '/Camera', component: Camera },
        { path: '/Camera/CameraList', component: CameraList }
      ]
    }
  ]
});

export default router;
