<template>
  <div>
    <div class="pc-banner">咨询投诉</div>
    <div class="w">
      <div class="main">
        <div class="complaint">
          <div class="table-search">
            <el-row :gutter="10">
              <el-col :span="6"
                ><div class="form-group">
                  <span>流水号：</span>
                  <el-input v-model="tableData.billNo" size="mini">
                  </el-input></div
              ></el-col>
              <el-col :span="8"
                ><div class="form-group">
                  <span>主题：</span>
                  <el-input v-model="tableData.title" size="mini">
                  </el-input></div
              ></el-col>
              <el-col :span="6"
                ><div class="form-group">
                  <span>姓名：</span>
                  <el-input v-model="tableData.name" size="mini">
                  </el-input></div
              ></el-col>
              <el-col :span="4">
                <div>
                  <el-button size="mini" type="primary" @click="search"
                    >查询</el-button
                  >
                  <el-button size="mini" type="primary" @click="PagesAdd"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <el-table :data="detail" border>
            <el-table-column
              prop="billNo"
              label="流水号"
              align="center"
              width="140"
            >
            </el-table-column>
            <el-table-column prop="title" label="主题"> </el-table-column>
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column prop="evaluateTime" label="日期">
            </el-table-column>
            <el-table-column label="操作" width="70">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-view"
                  @click="handleView(scope.row.letterId)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="5"
              :total="Total"
              @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.getList();
  },
  data() {
    return {
      tableData: {
        billNo: "",
        name: "",
        pageSize: 5,
        pageNum: 1,
        title: ""
      },
      detail: [],
      Total: 0
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/web/letter/getLetterList",
        method: "post",
        data: this.tableData
      }).then(res => {
        this.detail = res.data.data.rows;
        this.Total = res.data.data.total;
        console.log(this.Total);
      });
    },
    handleCurrent(page) {
      this.tableData.pageNum = page;
      this.getList();
    },
    handleView(id) {
      this.$router.push("/Complaint/ComplaintList?id=" + id);
    },
    search() {
      this.tableData.pageNum = 1;
      this.getList();
    },
    PagesAdd() {
      this.$router.push("/Complaint/ComplaintAdd");
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.complaint {
  width: 100%;
}
.demonstration {
  margin-right: 10px;
}
</style>
