<template>
  <div class="register">
    <!-- 左侧楼层，暂时不要了 -->
    <!-- <div class="register-floor" v-if="floorMenu">
      <div class="floor-menu" ref="floorMenu">
        <ul>
          <li class="floor-menu-start"></li>
          <li
            class="floor-menu-item"
            :class="{ active: index === floorMenuActive }"
            v-for="(item, index) in options"
            :key="index"
            @click="handleFloorMenu(index)"
          >
            {{ item.title }}
          </li>
        </ul>
        <span
          class="floor-menu-active"
          :style="{ top: activeTop + 'px' }"
        ></span>
      </div>
    </div> -->
    <div class="register-content">
      <el-form ref="form" :model="form">
        <div
          class="register-form"
          v-for="(item, index) in options"
          :key="index"
        >
          <Title :text="item.title"></Title>
          <!-- 普通的表单 -->
          <el-descriptions :column="2" border v-if="item.option">
            <el-descriptions-item :key="idx" v-for="(el, idx) in item.option">
              <template slot="label">
                <div class="register-form-label">
                  <span v-if="el.required">*</span>
                  {{ el.label }}:
                </div>
              </template>
              <!-- 外层form -->
              <el-form-item v-if="item.outerProp">
                <el-input
                  class="entry"
                  v-model="form[item.outerProp][el.prop]"
                  :disabled="disabled"
                >
                  <i
                    slot="suffix"
                    :class="el.suffix"
                    class="el-input__icon"
                    v-if="el.suffix"
                  ></i>
                  <template slot="append" v-if="el.append"
                    >{{ el.append }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item :prop="el.prop" :rules="initRules(el)" v-else>
                <!-- 进行判断样式type -->
                <template v-if="el.type === 'upload'">
                  <template>
                    <div class="upload-div" v-if="form[el.prop]">
                      <el-image
                        class="upload-div-img"
                        :src="form[el.prop]"
                        :preview-src-list="[form[el.prop]]"
                        fit="cover"
                      ></el-image>
                      <i
                        class="el-icon-error"
                        @click="delUpload(el.prop)"
                        v-if="!disabled"
                      ></i>
                    </div>
                    <el-upload
                      v-if="!disabled && !form[el.prop]"
                      accept=".jpg, .jpeg, .png, .gif"
                      action="/chainer/p/utils/common/upload"
                      :limit="1"
                      :show-file-list="false"
                      :on-success="
                        (response, file, fileList) =>
                          uploadSuccess(response, el.prop)
                      "
                    >
                      <el-button icon="el-icon-upload2" size="small"
                        >点击上传
                      </el-button>
                    </el-upload>
                    <span v-if="disabled && !form[el.prop]">暂未上传</span>
                  </template>
                </template>
                <!-- 日期选择器 -->
                <template v-else-if="el.type === 'date'">
                  <el-date-picker
                    :disabled="disabled"
                    v-model="form[el.prop]"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </template>
                <!-- 多行文本框 -->
                <template v-else-if="el.type === 'textarea'">
                  <el-input
                    :disabled="disabled"
                    v-model="form[el.prop]"
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                  >
                  </el-input>
                </template>
                <!-- 下拉选择器 -->
                <template v-else-if="el.type === 'select' && initSelect">
                  <el-select
                    filterable
                    :disabled="disabled"
                    style="width: 100%"
                    v-model="form[el.prop]"
                    placeholder=""
                    @visible-change="$forceUpdate()"
                  >
                    <el-option
                      v-for="option in el.dictUrl
                        ? selectOptions[el.prop]
                        : el.dicData"
                      :key="
                        option[el.dictProps ? el.dictProps['value'] : 'value']
                      "
                      :label="
                        option[el.dictProps ? el.dictProps['label'] : 'label']
                      "
                      :value="
                        option[el.dictProps ? el.dictProps['value'] : 'value']
                      "
                    >
                    </el-option>
                  </el-select>
                </template>
                <!-- 级联选择器 -->
                <template v-else-if="el.type === 'cascader' && initSelect">
                  <el-cascader
                    placeholder=""
                    :disabled="disabled"
                    v-model="form[el.prop]"
                    :ref="el.prop"
                    style="width: 100%"
                    :options="selectOptions[el.prop]"
                    @change="value => handleCascader(value, el)"
                  ></el-cascader>
                </template>
                <template v-else>
                  <el-input
                    class="entry"
                    v-model="form[el.prop]"
                    :disabled="disabled"
                  >
                    <i
                      slot="suffix"
                      :class="el.suffix"
                      class="el-input__icon"
                      v-if="el.suffix"
                    ></i>
                    <template slot="append" v-if="el.append"
                      >{{ el.append }}
                    </template>
                  </el-input>
                </template>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
          <!-- 表格 -->
          <div class="register-table" v-if="item.tableOption">
            <el-upload
              v-if="!disabled"
              multiple
              accept=".jpg, .jpeg, .png, .gif"
              class="upload-demo"
              action="/chainer/p/utils/common/upload"
              :show-file-list="false"
              :on-success="
                (response, file, fileList) => addRow(response, item.tableProp)
              "
            >
              <el-button type="primary" icon="el-icon-plus" size="small"
                >上 传
              </el-button>
            </el-upload>
            <!-- 这是为了解决数据没法双向绑定的问题 -->
            <input type="hidden" v-model="form[item.tableProp]" />
            <!-- 这是为了解决数据没法双向绑定的问题 -->
            <el-table
              :data="tableFiles[item.tableProp]"
              border
              :style="{ marginTop: disabled ? '20px' : '0' }"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                :prop="el.prop"
                :label="el.label"
                v-for="(el, idx) in item.tableOption"
                :key="idx"
              >
                <template slot-scope="scope">
                  {{
                    el.formatter
                      ? el.formatter(scope.row[el.prop])
                      : scope.row[el.prop]
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="100"
                align="center"
                v-if="!disabled"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="delRow(scope.$index, item.tableProp)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column label="缩略图" width="100" align="center" v-else>
                <template slot-scope="scope">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="'chainer' + scope.row.fileUrl"
                    :preview-src-list="['chainer' + scope.row.fileUrl]"
                  ></el-image>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 地图 -->
          <div class="register-map" v-if="item.map">
            <div class="register-map-input" v-if="!disabled">
              <el-input
                v-model="mapKeyword"
                placeholder="搜索企业名称"
                size="small"
              ></el-input>
            </div>

            <baidu-map
              class="register-map-container"
              ak="t8HWeSGI4l0HddrS5MGGkLZTw7VSNsOn"
              :scroll-wheel-zoom="true"
              :center="{ lng: 116.368824, lat: 23.548856 }"
              :zoom="12"
              @click="clickMap"
            >
              <bm-local-search
                :keyword="mapKeyword"
                :panel="false"
                location="揭阳"
              ></bm-local-search>
            </baidu-map>
          </div>
        </div>
      </el-form>
    </div>
    <div class="form-button">
      <template v-if="!disabled">
        <el-button type="info" @click="handleSubmit('save')" v-if="saveBtn"
          >暂 存</el-button
        >
        <el-button type="primary" @click="handleSubmit('submit')"
          >提 交
        </el-button>
      </template>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";
import { $http } from "../../api/utils";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch";
export default {
  components: {
    Title,
    BaiduMap,
    BmLocalSearch
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    // 是否需要左侧楼层（暂时不要了）
    // floorMenu: {
    //   type: Boolean,
    //   default: false
    // },
    options: {
      type: Array,
      default: () => []
    },
    // 是否禁用操作
    disabled: {
      type: Boolean,
      default: false
    },
    // 暂存按钮
    saveBtn: {
      type: Boolean,
      default: true
    }
  },
  //   computed: {
  //     选中方块的 top
  //     activeTop() {
  //       return (this.floorMenuActive + 1) * 40 + 12;
  //     }
  //   },
  data() {
    return {
      // floorMenuActive: 0
      initSelect: false,
      // 全部下拉框选项集合
      selectOptions: {},
      // 表格附件集
      tableFiles: {},
      // 结果集
      form: this.value,

      mapKeyword: ""
    };
  },
  watch: {
    value(val) {
      this.form = val;
      // 次联回显，这里不是很严谨...
      if (val.countyCode && val.chainId) {
        this.$set(this.form, "Chain", [
          val.countyCode.toString(),
          val.chainId.toString()
        ]);
      }
      // 回显表格文件，需要和后端约定好返回的值必须为 字段+FileList
      this.initTableFiles(val);
    }
  },
  mounted() {
    this.initForm();
    // this.initFloorMenu();
    this.initSelectOption();
  },
  methods: {
    initForm() {
      this.options.forEach(item => {
        item.option.forEach(el => {
          this.$set(this.form, el.prop, "");
        });

        if (item.outerProp) {
          this.$set(this.form, item.outerProp, {});
        }
      });
    },
    // 初始化校验
    initRules(el) {
      let rules = [];
      if (el.required) {
        rules.push({
          type: el.type === "cascader" ? "array" : undefined,
          required: true,
          message: `请${
            el.type === "select" || el.type === "cascader" ? "选择" : "输入"
          }${el.label}`,
          trigger: `${
            el.type === "select" || el.type === "cascader" ? "change" : "blur"
          }`
        });
      }
      return rules;
    },
    // 初始化下拉选择框
    initSelectOption() {
      let selectOptions = [];
      this.options.forEach(item => {
        selectOptions.push(
          ...item.option.filter(
            el => (el.type === "select" && el.dictUrl) || el.type === "cascader"
          )
        );
      });
      // 请求字典值
      if (selectOptions.length > 0) {
        this.getSelectData(selectOptions);
      }
    },
    // 回显表格文件
    initTableFiles(formData) {
      let tableFiles = [];
      tableFiles.push(
        ...this.options.filter(item => item.tableOption && item.tableProp)
      );
      tableFiles.forEach(item => {
        this.tableFiles[item.tableProp] = formData[`${item.tableProp}FileList`];
      });
    },
    getSelectData(selectOptions) {
      let initFlag = 0;
      selectOptions.forEach(item => {
        $http(item.dictUrl, item.dictData)
          .then(res => {
            const info = res.data;
            if (info.code == 0) {
              this.selectOptions[item.prop] = info.data;
            } else {
              this.$message.error(info.msg);
            }
          })
          .then(() => {
            initFlag++;
            this.initSelect = initFlag === selectOptions.length;
          });
      });
    },
    // initFloorMenu() {
    //   const floorMenu = this.$refs.floorMenu;
    //   const floorMenuOffsetTop = floorMenu.offsetTop;
    //   window.addEventListener(
    //     "scroll",
    //     () => {
    //       const scrollTop = window.pageYOffset;
    //       if (scrollTop >= floorMenuOffsetTop) {
    //         floorMenu.style.position = "fixed";
    //       } else {
    //         floorMenu.style.position = "relative";
    //       }
    //     },
    //     true
    //   );
    // },
    // 点击楼层
    // handleFloorMenu(index) {
    //   this.floorMenuActive = index;
    // },
    // 添加表格附件
    addRow(res, prop) {
      if (res.code == 0) {
        let imgArr = [];
        if (!this.tableFiles[prop]) {
          imgArr.push(res.data);
        } else {
          imgArr = [...this.tableFiles[prop], res.data];
        }
        this.$set(this.tableFiles, prop, imgArr);
        this.$set(this.form, prop, imgArr.map(item => item.fileUrl).join(","));
      }
    },
    // 删除表格附件
    delRow(index, prop) {
      this.tableFiles[prop].splice(index, 1);
      const imgArr = this.tableFiles[prop].map(item => item.fileUrl);
      this.form[prop] = imgArr.join(",");
    },
    viewRow(index) {
      this.$refs[`onlyPreview${index}`].showViewer = true;
    },
    // 上传成功后
    uploadSuccess(res, prop) {
      if (res.code == 0) {
        this.form[prop] = `/chainer${res.data.fileUrl}`;
      }
    },
    // 删除附件
    delUpload(prop) {
      this.form[prop] = "";
    },
    // 处理级联选择器
    handleCascader(value, el) {
      this.form[el.prop] = value;

      const parentEl = this.selectOptions[el.prop].find(
        item => item.value === value[0]
      );

      this.form[el.cascaderLabelProps[0]] = parentEl.label;
      this.form[el.cascaderLabelProps[1]] = parentEl.children.find(
        item => item.value === value[1]
      ).label;
      el.cascaderValueProps.forEach((item, index) => {
        this.form[item] = value[index];
      });

      if (typeof el.change === "function") {
        el.change();
        $http("/p/common/getTownsByCountyCode", { areaCode: value[0] }).then(
          res => {
            this.selectOptions["areaCode"] = res.data.data;
          }
        );
      }
    },
    // 点击地图
    clickMap(e) {
      if (this.disabled) return;
      const { lat, lng } = e.point;
      this.form.longitude = lng;
      this.form.latitude = lat;
    },
    // 点击提交
    handleSubmit(type) {
      this.$refs.form.validate(valid => {
        if (valid) {
          // type：save（暂存），submit（提交）
          this.form["operateType"] = type;
          this.$emit("input", this.form);
          this.$emit("submit", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style>
.register {
  width: 100%;
}

.register-form-label span {
  color: #f56c6c;
  vertical-align: middle;
}

.register-floor {
  padding-top: 25px;
  width: 180px;
  height: 100%;
}

.register-content {
  flex: 1;
}

.register-table button {
  margin: 20px 0 10px;
}

/* 重写elementUI样式 */
.register-form .el-form-item {
  margin-bottom: 0;
}

.register-form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}

/* 重写elementUI样式 */
.upload-div {
  display: flex;
  width: 100px;
  height: 100px;
  position: relative;
}
.upload-div i {
  color: #f56c6c;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: -8px;
  right: -8px;
  background: #fff;
  border-radius: 50%;
}
.upload-div-img {
  width: 100%;
  height: 100%;
}

.register-form .el-input.is-disabled .el-input__inner,
.register-form .el-textarea.is-disabled .el-textarea__inner {
  color: #000;
}

/* 地图 */
.register-map {
  border: 1px solid #d2d2d2;
  margin-top: 20px;
  width: 100%;
  position: relative;
}
.register-map-input {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
  display: flex;
}
.register-map-input button {
  margin-left: 10px;
}
.register-map-container {
  height: 300px;
}
/* 楼层菜单 */
/* .floor-menu {
  width: 180px;
  position: relative;
  top: 0;
}
.floor-menu li {
  padding-left: 50px;
  height: 40px;
  line-height: 40px;
  position: relative;
  color: #999;
  cursor: pointer;
} */
/* 选中状态 */
/* .floor-menu li.active {
  color: #000;
} */
/* 选中状态 */
/* .floor-menu li::before {
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  box-sizing: border-box;
}
.floor-menu li::after {
  content: "";
  height: 100%;
  width: 1px;
  background: #d8d8d8;
  position: absolute;
  left: 24px;
  top: 14px;
}
.floor-menu .floor-menu-start::before {
  border: 2px solid #d8d8d8;
  background: #fff;
}
.floor-menu .floor-menu-item::before {
  background: #d8d8d8;
} */

/* 小方块 */
/* .floor-menu .floor-menu-active {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #0453a0;
  position: absolute;
  left: 15px;
  z-index: 2;
  transition: all 0.2s;
}
.floor-menu .floor-menu-active::after {
  content: "";
  border-top: 8px solid transparent;
  border-left: 10px solid #0453a0;
  border-bottom: 8px solid transparent;
  position: absolute;
  right: -10px;
} */
</style>
