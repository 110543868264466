import $axios from "../../utils/axios";
const prefix = "/p/industry/subject";
// 注册企业
export const registerSubject = data => {
  return $axios({
    url: `${prefix}/registerSubject`,
    method: "post",
    json: true,
    data
  });
};

// 获取账号认证企业信息
export const getSubjectDetail = () => {
  return $axios({
    url: `${prefix}/getSubjectDetail`,
    method: "post"
  });
};

// 变更信息
export const updateModifyInfo = data => {
  return $axios({
    url: `${prefix}/updateModifyInfo`,
    method: "post",
    json: true,
    data
  });
};
