<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="10"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="新增企业需求"></Title>
          <el-form ref="form" :model="detail" :rules="rules">
            <div class="form">
              <div class="form-item percent">
                <span><i class="color-red">*</i> 需求标题：</span>
                <el-form-item class="form-item-content" prop="title">
                  <el-input v-model="detail.title"></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span><i class="color-red">*</i>需求详情：</span>
                <el-form-item class="form-item-content" prop="content">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    v-model="detail.content"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent percent">
                <span><i class="color-red">*</i>相关文件：</span>
                <el-form-item class="form-item-content" prop="attachment">
                  <template>
                    <el-upload
                      action="/chainer/p/utils/common/upload"
                      multiple
                      :on-success="uploadFile"
                      :on-remove="removeFile"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </template>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="info" @click="handleHold('draft')"
              >暂 存</el-button
            >
            <el-button type="primary" @click="handleSubmit('submitted')"
              >提 交</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: { LeftTabberVue, Title, Tabbar },
  data() {
    return {
      DemandAddData: "",
      optionData: "",
      detail: {
        attachment: "",
        content: "",
        demandType: "subject_demand",
        title: "",
        status: ""
      },
      fileList: [{ id: "this.attachment" }],
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        demandType: [
          { required: true, message: "请选择类型", trigger: "blur" }
        ],
        content: [{ required: true, message: "请输入详情", trigger: "blur" }],
        attachment: [
          { required: true, message: "请上传相关文件", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    getData() {
      this.$axios({
        url: "/p/demand/save",
        method: "post",
        data: this.detail
      }).then(res => {
        if (res.data.code == 0) {
          this.$alert("您已成功提交！", "成功", {
            onfirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push("/Demand");
            }
          }).catch(() => {
            this.$message({
              type: "error",
              message: "成功取消"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },
    handleBack() {
      this.$router.back();
    },
    handleHold(type) {
      this.$confirm("是否提交为暂存", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.detail.status = type;
        this.$axios({
          url: "/p/demand/save",
          method: "post",
          data: this.detail
        }).then(() => {
          this.$router.push("/Demand");
        });
      });
    },
    handleSubmit(type) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.detail.status = type;
          this.getData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // file形参
    uploadFile(response, file, fileList) {
      this.finishFile(fileList);
    },
    removeFile(file, fileList) {
      this.finishFile(fileList);
    },
    finishFile(fileList) {
      const Ids = [];
      fileList.forEach(item => {
        Ids.push(item.response.data.fileId);
      });
      this.detail.attachment = Ids.join(",");
    }
  }
};
</script>
<style scoped>
.all {
  background-color: #eee;
}
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
</style>
