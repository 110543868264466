<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="3"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业项目管理"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="6"
                ><div class="form-group">
                  <span>项目名称：</span>
                  <el-input size="mini" v-model="ProjectData.projectTitle">
                  </el-input></div
              ></el-col>
              <el-col :span="6"
                ><div class="form-group">
                  <span>项目状态：</span>
                  <template>
                    <el-select
                      v-model="ProjectData.declareStatus"
                      size="mini"
                      placeholder=""
                    >
                      <el-option
                        v-for="item in diction"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="item.dictValue"
                      >
                      </el-option>
                    </el-select>
                  </template></div
              ></el-col>
              <el-col :span="8"
                ><div class="form-group">
                  <span>申报日期：</span>
                  <el-date-picker
                    size="mini"
                    v-model="rangeTime"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="handlequery"
                    >查询</el-button
                  ><el-button size="mini" type="primary" @click="handleAdd"
                    >申报</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="detail" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="projectTitle" label="项目名称">
              </el-table-column>
              <el-table-column prop="declareDate" label="申报日期" width="100">
              </el-table-column>
              <el-table-column
                prop="declareStatus"
                label="项目状态"
                width="85"
                align="center"
              >
                <template slot-scope="scope">
                  {{ forGetDiction(scope.row.declareStatus) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.projectId)"
                    v-if="scope.row.declareStatus !== 'draft'"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleRecall(scope.row.projectId)"
                    v-if="scope.row.declareStatus === 'dealing'"
                    >撤回</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleEdit(scope.row.projectId)"
                    v-if="
                      scope.row.declareStatus === 'draft' ||
                        scope.row.declareStatus === 'repulse'
                    "
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    v-if="
                      scope.row.declareStatus === 'draft' ||
                        scope.row.declareStatus === 'repulse'
                    "
                    @click="handleDelete(scope.row.projectId)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="5"
              :total="Total"
              @current-change="handlechange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  data() {
    return {
      rangeTime: [],
      detail: [],
      diction: [],
      pageNum: 1,
      Total: 0,
      // 产品信息
      ProjectData: {
        declareEndtDate: "",
        declareStartDate: "",
        declareStatus: "",
        pageNum: 1,
        pageSize: 5,
        projectTitle: ""
      }
    };
  },
  created() {
    this.getList();
    this.getDiction();
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/project/project/getProjectList",
        method: "post",
        data: this.ProjectData
      }).then(res => {
        this.detail = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    handlequery() {
      if (this.rangeTime && this.rangeTime.length > 0) {
        this.ProjectData.declareStartDate = this.rangeTime[0];
        this.ProjectData.declareEndtDate = this.rangeTime[1];
      } else {
        this.ProjectData.declareStartDate = "";
        this.ProjectData.declareEndtDate = "";
      }
      this.ProjectData.pageNum = 1;
      this.getList();
    },
    // 字典
    getDiction() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "project_declare_status"
        }
      }).then(res => {
        this.diction = res.data.data;
      });
    },
    forGetDiction(value) {
      for (var i = 0; i < this.diction.length; i++) {
        if (this.diction[i].dictValue == value) {
          return this.diction[i].dictLabel;
        }
      }
    },
    handleAdd() {
      this.$router.push("/Project/ProjectAdd");
    },
    handleView(id) {
      this.$router.push("/Project/ProjectList?id=" + id);
    },
    handleEdit(id) {
      this.$router.push("/Project/ProjectEdit?id=" + id);
    },
    handlechange(page) {
      this.ProjectData.pageNum = page;
      this.getList();
    },
    handleDelete(projectId) {
      this.$confirm("此操作将永久删除该企业项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/p/project/project/removeProjectById",
          method: "post",
          data: {
            ids: projectId
          }
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    },
    handleRecall(projectId) {
      this.$confirm("此操作将永久撤回该企业项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/p/project/project/withdrawProjectById",
          method: "post",
          data: {
            projectId: projectId
          }
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.el-select .el-input__inner {
  height: 30px;
  line-height: 30px;
}
</style>
