<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabber :active="8"></LeftTabber>
        </div>
        <div class="right">
          <Title text="专家答疑互动"></Title>
          <div class="form">
            <div class="form-item">
              <span>产业链名称：</span>
              <div class="form-item-content">
                {{ detail.chainName }}
              </div>
            </div>
            <div class="form-item">
              <span>主体名称：</span>
              <div class="form-item-content">
                {{ detail.chainName }}
              </div>
            </div>
            <div class="form-item">
              <span>疑问：</span>
              <div class="form-item-content">
                {{ detail.question }}
              </div>
            </div>
            <div class="form-item">
              <span>具体专家：</span>
              <div class="form-item-content">
                {{ detail.chainName }}
              </div>
            </div>
            <div class="form-item">
              <span>状态：</span>
              <div class="form-item-content">
                {{ getDictLabel(getStatus, detail.status) }}
              </div>
            </div>
            <div class="form-item">
              <span>疑问类型：</span>
              <div class="form-item-content">
                {{ getDictLabel(getQuestion, detail.questionType) }}
              </div>
            </div>
          </div>
          <div v-if="detail.status == 1">
            <Title text="回复信息"></Title>
            <div class="form">
              <div class="form-item">
                <span>回复人员：</span>
                <div class="form-item-content">
                  {{ detail.replyName }}
                </div>
              </div>
              <div class="form-item">
                <span>回复时间：</span>
                <div class="form-item-content">
                  {{ detail.replyTime }}
                </div>
              </div>
              <div class="form-item percent">
                <span>回复内容：</span>
                <div class="form-item-content">
                  {{ detail.replyContents }}
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
import LeftTabber from "../../components/assembly/LeftTabber";

export default {
  components: {
    Title,
    Tabbar,
    LeftTabber
  },
  created() {
    this.getList(this.$route.query.id);
    this.getDictData("interaction_status", "getStatus");
    this.getDictData("interaction_question_type", "getQuestion");
  },
  data() {
    return {
      detail: [],
      getStatus: [],
      getQuestion: []
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/web/interaction/getInteraction",
        method: "post",
        params: {
          interactionId: id
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    },
    // 字典封装
    getDictData(dictType, targetData) {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: dictType
        }
      }).then(res => {
        this[targetData] = res.data.data;
      });
    },
    // 循环
    getDictLabel(dictArray, value) {
      for (var i = 0; i < dictArray.length; i++) {
        if (dictArray[i].dictValue === value) {
          return dictArray[i].dictLabel;
        }
      }
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.all {
  background-color: #eee;
}
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
