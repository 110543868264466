<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="5"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="产品销售填报"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="6"
                ><div class="form-group">
                  <span class="demonstration">年份：</span>
                  <el-date-picker
                    v-model="detail.year"
                    type="year"
                    value-format="yyyy"
                    size="mini"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="6"
                ><div class="form-group">
                  <span class="demonstration">月份：</span>
                  <el-date-picker
                    v-model="detail.month"
                    type="month"
                    value-format="MM"
                    format="MM月"
                    size="mini"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="8"
                ><div class="form-group">
                  <span class="demonstration">日期：</span>
                  <el-date-picker
                    v-model="rangeTime"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                    size="mini"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >查询</el-button
                  ><el-button size="mini" type="primary" @click="handleAdd"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="SalesData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="chainName" label="产业链">
              </el-table-column>
              <el-table-column prop="areaName" label="地区名称">
              </el-table-column>
              <!-- <el-table-column prop="subjectName" label="主体名称">
              </el-table-column> -->
              <el-table-column prop="reportDate" label="日期" width="80">
              </el-table-column>
              <el-table-column prop="saleGross" label="销售总额">
                <template slot-scope="scope">
                  {{ scope.row.saleGross || 0 }}万元
                </template>
              </el-table-column>
              <el-table-column prop="saleTotal" label="销售总量">
                <template slot-scope="scope">
                  {{ scope.row.saleTotal || 0 }}公斤
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="状态"
                width="70"
                align="center"
              >
                <template slot-scope="scope">
                  {{ forGetStatus(scope.row.status) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    v-if="scope.row.status === 'submitted'"
                    @click="handleView(scope.row.subjectReportId)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    v-if="scope.row.status === 'draft'"
                    @click="handleEdit(scope.row.subjectReportId)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(scope.row.subjectReportId)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="5"
              :total="Total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList();
    this.getStatue();
  },

  data() {
    return {
      Total: 0,
      salesStatus: [],
      rangeTime: [],

      detail: {
        endTime: "",
        month: "",
        startTime: "",
        year: "",
        pageNum: 1,
        pageSize: 5
      },
      // 产品信息
      SalesData: []
    };
  },
  methods: {
    handleAdd() {
      this.$router.push("/Sales/SalesAdd");
    },
    handleEdit(id) {
      this.$router.push("/Sales/SalesEdit?id=" + id);
    },
    handleView(id) {
      this.$router.push("/Sales/SalesList?id=" + id);
    },

    handleDelete(subjectReportId) {
      this.$confirm("此操作将永久删除该产品销售情况, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        // 成功删除请求
        this.$axios({
          url: "/p/industry/subject/salesReport/removeSalesReportById",
          method: "post",
          data: {
            ids: subjectReportId
          }
        }).then(() => {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    },
    getList() {
      this.$axios({
        url: "/p/industry/subject/salesReport/getSalesReportList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.SalesData = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    search() {
      // 时间的起始于结束
      if (this.rangeTime.length > 0) {
        this.detail.startTime = this.rangeTime[0];
        this.detail.endTime = this.rangeTime[1];
      }
      this.detail.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.detail.pageNum = page;
      this.getList();
    },
    // 字典
    getStatue() {
      this.$axios({
        // 查个地址
        url: "/p/utils/dict/getType",
        params: {
          // 固定名称，哪里查看这个需要请求值
          dictType: "industry_subject_report_status"
        }
      }).then(res => {
        this.salesStatus = res.data.data;
      });
    },
    forGetStatus(value) {
      for (var i = 0; i < this.salesStatus.length; i++) {
        if (this.salesStatus[i].dictValue === value) {
          return this.salesStatus[i].dictLabel;
        }
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
