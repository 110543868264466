<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="6"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="产品产值管理"></Title>

          <el-form ref="form" :model="detail" :rules="rules">
            <div class="form">
              <div class="form-item">
                <span>年份:</span>
                <el-form-item class="form-item-content" prop="year">
                  <el-date-picker
                    v-model="detail.year"
                    type="year"
                    value-format="yyyy"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>

              <div class="form-item">
                <span>月份:</span>
                <el-form-item class="form-item-content" prop="month">
                  <el-date-picker
                    v-model="detail.month"
                    type="month"
                    value-format="MM"
                    format="MM月"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>产值总量：</span>
                <el-form-item class="form-item-content" prop="productionGross">
                  <el-input v-model="detail.productionTotal">
                    <template slot="append">公斤</template></el-input
                  >
                </el-form-item>
              </div>
              <div class="form-item">
                <span>库存量：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.stockCount">
                    <template slot="append">个</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>一产产值：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.firstWorth">
                    <template slot="append">万元</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>二产产值：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.secondWorth">
                    <template slot="append">万元</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>二产加工量：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.machineCount">
                    <template slot="append">公斤</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>三产产值：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.thirdWorth">
                    <template slot="append">万元</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>毛利率：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.grossProfitRate">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>增长率：</span>
                <el-form-item class="form-item-content" prop="saleGross">
                  <el-input v-model="detail.increaseRate">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>

          <!-- 怎么更改样式 -->
          <div class="region">
            <el-table :data="detail.firstList" border>
              <el-table-column prop="indicesName" label="一产投入对象">
              </el-table-column>
              <el-table-column prop="value" label="费用总额（万元）">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.value"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="region">
            <el-table :data="detail.secondList" border>
              <el-table-column prop="indicesName" label="二产投入对象">
              </el-table-column>
              <el-table-column prop="value" label="费用总额（万元）">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.value"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="region">
            <el-table :data="detail.thirdList" border>
              <el-table-column prop="indicesName" label="三产投入对象">
              </el-table-column>
              <el-table-column prop="value" label="费用总额（万元）">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.value"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="info" @click="handleSubmit('save')"
              >暂 存
            </el-button>
            <el-button type="primary" @click="handleSubmit('submit')"
              >提 交
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Tabbar from "../../components/assembly/Tabbar";
import Title from "../../components/assembly/Title";

export default {
  components: {
    LeftTabberVue,
    Tabbar,
    Title
  },
  created() {
    this.getList(this.$route.query.id);
  },
  data() {
    return {
      detail: {},
      rules: {
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        month: [{ required: true, message: "请选择月份", trigger: "change" }]
      }
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/industry/subject/productionReport/getProductionReportById",
        method: "post",
        data: {
          // productionId: id,
          reportId: id
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    },
    getForm() {
      this.$axios({
        url: "/p/industry/subject/productionReport/saveProductionReport",
        method: "post",
        data: this.detail,
        json: true
      }).then(res => {
        if (res.data.code == 0) {
          this.$alert("您已成功提交！", "成功", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push("/Report");
            }
          }).catch(() => {
            this.$message({
              type: "error",
              message: "成功取消"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },
    handleBack() {
      this.$router.back();
    },
    // 提交验证
    handleSubmit(type) {
      // 父传子，进行验证
      this.$refs.form.validate(valid => {
        if (valid) {
          this.detail.operateType = type;
          this.getForm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.el-form-item {
  margin-bottom: 0;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.region {
  margin: 20px 0;
}
</style>
