<template>
  <div class="tab_list" :style="'justify-content:' + posi">
    <ul>
      <!-- class是类名，进行类名：判断条件是否为索引号则显示这个样式 -->
      <!-- v-for循环，一有v不需要动态，item是自命名，index是其索引号 key是对应必写 -->
      <!-- 点击事件并进行传参前为索引，索引是使其默认为0，后是进行传递id获取 -->
      <li
        :class="{ on: now == index }"
        v-for="(item, index) in title"
        :key="index"
        @click="handleClick(index, item.id)"
        @mouseover="handleMouseOver(index)"
      >
        <!-- 兼容事件。两者为一 -->
        {{ item.label || item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: Array,
      //   数组
      default: () => []
    },
    trigger: {
      type: String,
      default: "click"
    },
    posi: {
      type: String,
      default: "center"
    }
  },
  data() {
    return {
      // 记录当前选中的 title 索引
      now: 0
    };
  },
  methods: {
    handleMouseOver(index) {
      if (this.trigger === "hover") {
        this.now = index;
      }
      //   传递id实参
      this.$emit("hover", index);
    },
    handleClick(index, id) {
      if (this.trigger === "click") {
        this.now = index;
      }
      //   传递id实参
      this.$emit("click", id);
    }
  }
};
</script>
<style scoped>
.tab_list {
  display: flex;
  /* justify-content: center; */
  border-bottom: 1px solid #d3d4d5;
  cursor: pointer;
}
ul {
  display: flex;
}
ul > li {
  display: flex;
  align-content: center;
  padding: 15px 25px 10px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #141515;
}
ul > li.on {
  color: #0b57a1;
  border-bottom: 2px solid #0654a1;
}
</style>
