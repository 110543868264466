var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"w"},[_c('Tabbar',{attrs:{"title":['企业工作台']}}),(
        (_vm.registerForm.approveOpinion &&
          _vm.registerForm.approveStatus === 'repulse') ||
          _vm.registerForm.approveStatus === 'approving'
      )?_c('div',{staticClass:"main",staticStyle:{"padding-bottom":"0"}},[(
          _vm.registerForm.approveOpinion &&
            _vm.registerForm.approveStatus === 'repulse'
        )?_c('el-alert',{attrs:{"closable":false,"title":"认证失败","type":"error","description":'打回原因为：' + _vm.registerForm.approveOpinion,"show-icon":""}}):_vm._e(),(_vm.registerForm.approveStatus === 'approving')?_c('el-alert',{attrs:{"closable":false,"title":"资料审核中，请耐心等待...","type":"info","show-icon":""}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"main",style:({
        paddingTop:
          (_vm.registerForm.approveOpinion &&
            _vm.registerForm.approveStatus === 'repulse') ||
          _vm.registerForm.approveStatus === 'approving'
            ? 0
            : 20
      })},[_c('RegisterForm',{ref:"registerForm",attrs:{"options":_vm.registerOptions,"disabled":_vm.registerForm.approveStatus === 'approving',"saveBtn":_vm.$route.query.type != 'update'},on:{"submit":_vm.handleSubmit},model:{value:(_vm.registerForm),callback:function ($$v) {_vm.registerForm=$$v},expression:"registerForm"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }