import { MessageBox } from "element-ui";

export default {
  /**
   * 弹窗控件
   * @param {type}              弹窗类型：success / error / warning / info（默认，无图标）
   * @param {message}           弹窗信息
   * @param {confirmButtonText} 确认按钮文本
   * @param {callback}          点击按钮后的回调函数
   */
  alertMessage: ({
    type,
    message,
    callback,
    confirmButtonText = "我知道了"
  } = {}) => {
    MessageBox.alert(message, "提示", {
      confirmButtonText,
      type,
      callback
    });
  },
  /**
   * 获取用户信息缓存
   */
  getUserInfo: () => {
    return sessionStorage.getItem("userInfo");
  },
};