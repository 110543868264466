<template>
  <div class="home">
    <div class="w">
      <!-- 企业工作台 -->
      <Tabbar :title="['企业工作台']"></Tabbar>
      <!-- 企业工作台下方 -->
      <div
        class="main"
        style="padding-bottom: 0"
        v-if="
          (registerForm.approveOpinion &&
            registerForm.approveStatus === 'repulse') ||
            registerForm.approveStatus === 'approving'
        "
      >
        <el-alert
          :closable="false"
          title="认证失败"
          type="error"
          :description="'打回原因为：' + registerForm.approveOpinion"
          show-icon
          v-if="
            registerForm.approveOpinion &&
              registerForm.approveStatus === 'repulse'
          "
        >
        </el-alert>
        <el-alert
          :closable="false"
          title="资料审核中，请耐心等待..."
          type="info"
          show-icon
          v-if="registerForm.approveStatus === 'approving'"
        >
        </el-alert>
      </div>
      <div
        class="main"
        :style="{
          paddingTop:
            (registerForm.approveOpinion &&
              registerForm.approveStatus === 'repulse') ||
            registerForm.approveStatus === 'approving'
              ? 0
              : 20
        }"
      >
        <RegisterForm
          ref="registerForm"
          v-model="registerForm"
          :options="registerOptions"
          :disabled="registerForm.approveStatus === 'approving'"
          :saveBtn="$route.query.type != 'update'"
          @submit="handleSubmit"
        ></RegisterForm>
      </div>
    </div>
  </div>
</template>
<script>
import Tabbar from "../../components/assembly/Tabbar";
import RegisterForm from "../../components/assembly/RegisterForm";
import {
  registerSubject,
  getSubjectDetail,
  updateModifyInfo
} from "../../api/subject";
export default {
  components: {
    Tabbar,
    RegisterForm
  },
  data() {
    return {
      registerForm: {},
      registerOptions: [
        {
          title: "企业基本信息",
          option: [
            { label: "主体全称", prop: "subjectName", required: true },
            { label: "主体简称", prop: "abbreviation" },
            { label: "曾用名", prop: "usedName" },
            { label: "组织机构代码", prop: "organizationCode", required: true },
            {
              label: "统一社会信用代码",
              prop: "socialCreditCode",
              required: true
            },
            { label: "成立时间", type: "date", prop: "establishDate" },
            {
              label: "主体类型",
              type: "select",
              prop: "subjectType",
              dictUrl: "/p/utils/dict/getType?dictType=industry_subject_type",
              dictProps: {
                value: "dictValue",
                label: "dictLabel"
              },
              required: true
            },
            {
              label: "主体级别",
              type: "select",
              prop: "subjectLevel",
              dictUrl: "/p/utils/dict/getType?dictType=industry_subject_level",
              dictProps: {
                value: "dictValue",
                label: "dictLabel"
              }
            },
            {
              label: "企业类型",
              type: "select",
              prop: "enterpriseType",
              dictUrl: "/p/utils/dict/getType?dictType=enterprise_type",
              dictProps: {
                value: "dictValue",
                label: "dictLabel"
              }
            },
            {
              label: "登记状态",
              type: "select",
              prop: "registerStatus",
              dictUrl: "/p/utils/dict/getType?dictType=register_status",
              dictProps: {
                value: "dictValue",
                label: "dictLabel"
              }
            },
            { label: "注册资本", prop: "registerCapital", append: "万元" },
            // { label: "所属地区", prop: "areaName", required: true },
            {
              label: "所属产业链",
              type: "cascader",
              prop: "Chain",
              // 因为接口要求保存 label 和 value
              cascaderValueProps: ["countyCode", "chainId"],
              cascaderLabelProps: ["countyName", "chainName"],
              dictUrl: "/p/common/getAreaAndChain",
              required: true,
              change: () => {
                if (this.registerForm) {
                  this.registerForm.areaCode = "";
                }
              }
            },
            {
              label: "所属镇",
              type: "select",
              prop: "areaCode",
              dictUrl: "/p/common/getTownsByCountyCode",
              dictProps: {
                value: "code",
                label: "name"
              },
              required: true
            },
            {
              label: "是否上市",
              type: "select",
              prop: "isPublic",
              dictUrl: "/p/utils/dict/getType?dictType=sys_yes_no",
              dictProps: {
                value: "dictValue",
                label: "dictLabel"
              }
            },
            { label: "所在地址", prop: "address", required: true },
            { label: "占地面积", prop: "coverSize", append: "亩" },
            { label: "人员规模", prop: "staffScale", required: true },
            {
              label: "联系电话",
              prop: "telephone",
              suffix: "el-icon-mobile-phone",
              required: true
            },
            { label: "邮箱", prop: "email", suffix: "el-icon-message" },
            { label: "官网", prop: "officialWeb" },
            {
              label: "经营范围",
              prop: "businessScope",
              type: "textarea",
              required: true
            }
          ]
        },
        {
          title: "企业财务信息",
          outerProp: "financeInfo",
          option: [
            { label: "主营业务收入", prop: "mainIncome", append: "万元" },
            { label: "其他业务收入", prop: "otherIncome", append: "万元" },
            { label: "主营业务成本", prop: "mainCost", append: "万元" },
            { label: "销售费用", prop: "salesCost", append: "万元" },
            { label: "管理费用", prop: "manageCost", append: "万元" },
            { label: "财务支出", prop: "financeCost", append: "万元" },
            { label: "营业外支出", prop: "businessSpending", append: "万元" }
          ]
        },
        {
          title: "企业法人信息",
          option: [
            { label: "姓名", prop: "legalerName" },
            { label: "身份证", prop: "legalerIdcard" },
            { label: "联系方式", prop: "legalerPhone" },
            { label: "职务", prop: "legalerPosition" },
            { label: "身份证正面", prop: "legalerIdcardFront", type: "upload" },
            {
              label: "身份证反面",
              prop: "legalerIdcardReverse",
              type: "upload"
            }
          ]
        },
        {
          title: "企业资质信息",
          option: [
            { label: "营业执照", prop: "businessLicense", type: "upload" },
            {
              label: "税务登记证",
              prop: "taxRegisterCertificate",
              type: "upload"
            }
          ],
          // 表格字段
          tableProp: "otherCertificate",
          // 表头配置
          tableOption: [
            { label: "文件名称", prop: "fileName" },
            {
              label: "文件大小",
              prop: "size",
              formatter: value => {
                return (value / 1024).toFixed(2) + "KB";
              }
            },
            {
              label: "上传时间",
              prop: "createTime",
              formatter: value => {
                return value.replace("T", " ");
              }
            }
          ]
        },
        {
          title: "企业地址",
          option: [
            { label: "经度", prop: "longitude" },
            { label: "纬度", prop: "latitude" }
          ],
          map: true
        }
      ]
    };
  },
  created() {
    this.getSubjectDetail();
  },
  methods: {
    getSubjectDetail() {
      getSubjectDetail().then(res => {
        this.registerForm = res.data.data;
      });
    },
    handleSubmit() {
      const { type } = this.$route.query;
      if (type === "update") {
        updateModifyInfo(this.registerForm).then(res => {
          const info = res.data;
          if (info.code == 0) {
            this.$utils.alertMessage({
              message: "提交变更信息成功，请等待审核...",
              callback: () => {
                this.$router.push("/Basic");
              }
            });
          }
        });
      } else {
        registerSubject(this.registerForm).then(res => {
          const info = res.data;
          if (info.code == 0) {
            this.$utils.alertMessage({
              message: info.msg,
              callback: () => {
                this.getSubjectDetail();
              }
            });
          } else {
            this.$utils.alertMessage({
              message: info.msg,
              type: "error"
            });
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.main {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}
.left {
  padding-top: 25px;
  width: 180px;
  height: 100%;
}
.right {
  flex: 1;
}
</style>
