<template>
  <div class="chain-box">
    <div class="chain-box-title">
      <span>{{ title }}</span>
    </div>
    <div class="chain-box-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
.chain-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.chain-box .chain-box-title span {
  display: inline-block;
  font-size: 20px;
  color: #0453a0;
  border-bottom: 2px solid #0453a0;

  font-weight: 700;
  padding: 15px 25px 10px;
}
.chain-box .chain-box-content {
  padding: 20px 0;
  flex: 1;
}
@media (max-width: 768px) {
  .chain-box .chain-box-title span {
    font-size: 22px;
    color: #333;
    border-bottom: none;
    padding: 0;
    font-weight: 500;
  }
  .chain-box .chain-box-content {
    padding: 15px 0;
  }
}
</style>
