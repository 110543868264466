<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="9"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="申报扶持政策"></Title>
          <!-- 标题 -->
          <div class="sub-title">基本信息</div>
          <!-- 表框 -->
          <!-- <el-form ref="form" :model="form" :rules="rules"> -->
          <el-form ref="form" :model="applydata" :rules="rules">
            <div class="form">
              <div class="form-item percent">
                <span>企业名称：</span>
                <el-form-item class="form-item-content" prop="breed">
                  <el-input
                    v-model="applydata.subjectName"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>法人姓名：</span>
                <el-form-item class="form-item-content" prop="status">
                  <el-input
                    v-model="applydata.legalerName"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>法人联系电话：</span>
                <el-form-item class="form-item-content" prop="leader">
                  <el-input v-model="applydata.legalerPhone"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>申请人：</span>
                <el-form-item class="form-item-content" prop="telephone">
                  <el-input v-model="applydata.applyBy"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>申请人联系电话：</span>
                <el-form-item class="form-item-content" prop="townCode">
                  <el-input v-model="applydata.phonenumber"></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>申请人联系地址：</span>
                <el-form-item class="form-item-content" prop="baseName">
                  <el-input
                    type="textarea"
                    v-model="applydata.address"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="sub-title position">申报所需材料</div>
            <div>
              <el-table :data="Attachment" border>
                <el-table-column
                  type="index"
                  label="序号"
                  width="50"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="name" label="材料名称">
                </el-table-column>
                <el-table-column prop="suffix" label="材料格式">
                </el-table-column>
                <el-table-column prop="attachmentType" label="材料类型">
                  <template slot-scope="scope">
                    {{ forattachmentType(scope.row.attachmentType) }}
                  </template>
                </el-table-column>
                <el-table-column prop="isNeed" label="是否必需">
                  <template slot-scope="scope">
                    <div>{{ scope.row.isNeed == "Y" ? "是" : "否" }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="documentNumber"
                  label="材料上传"
                  width="200"
                >
                  <!-- :on-success="
                        function(response) {
                          return uploadImg(response, scope.$index);
                        }这个是es5写法
                      " -->
                  <template slot-scope="scope">
                    <div v-if="scope.row.fileName" class="textshow">
                      <span class="textwidth">{{ scope.row.fileName }}</span>
                      <i
                        class="el-icon-error"
                        @click="handledel(scope.$index)"
                        style="color: red; cursor: pointer"
                      ></i>
                    </div>

                    <el-upload
                      action="/chainer/p/support/applyAttachment/save"
                      :on-success="
                        response => {
                          uploadtext(response, scope.$index);
                        }
                      "
                      :show-file-list="false"
                      v-else
                      :data="uploadData"
                      :before-upload="
                        () => {
                          beforeUpload(scope.row.policyAttachmentId);
                        }
                      "
                    >
                      <el-button
                        size="small"
                        icon="el-icon-upload2"
                        class="uploadchange"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>

          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="primary" @click="handleSubmit">提 交 </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getAttachment(this.$route.query.id);
    const userInfo = JSON.parse(this.$utils.getUserInfo());
    this.userInfo = userInfo;
    this.applydata.subjectName = this.userInfo.industrySubject.subjectName;
    this.applydata.legalerName = this.userInfo.industrySubject.legalerName;
    this.applydata.legalerPhone = this.userInfo.industrySubject.legalerPhone;
    this.getAttachmentType();
  },
  data() {
    return {
      show: [],
      Attachment: [],
      attachmentType: "",
      userInfo: "",
      fileList: [],
      applydata: {
        policyId: this.$route.query.id,
        subjectId: "",
        subjectName: "",
        legalerName: "",
        legalerPhone: "",
        address: "",
        phonenumber: "",
        fileList: "",
        applyBy: ""
      },
      detail: [],
      attachData: [],
      attachName: [],
      uploadData: {
        policyAttachmentId: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        isNeed: [
          { required: true, message: "请选择活动区域", trigger: "change" }
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change"
          }
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    // 上传前
    beforeUpload(id) {
      this.uploadData.policyAttachmentId = id;
    },
    getAttachment(id) {
      this.$axios({
        url: "/p/support/attachment/getList",
        method: "post",
        data: {
          policyId: id
        }
      }).then(res => {
        res.data.data.rows.forEach(item => {
          item.fileName = "";
        });
        this.Attachment = res.data.data.rows;
        // this.Attachment.policyAttachmentId.join(",");
        // console.log(this.Attachment);
      });
    },
    uploadtext(response, index) {
      this.Attachment[index].fileName = response.data.name;
      this.Attachment[index].attachmentId = response.data.attachmentId;
    },
    handledel(index) {
      this.Attachment[index].fileName = "";
      this.Attachment[index].attachmentId = "";
    },

    handleBack() {
      this.$router.back();
    },
    handleSubmit() {
      this.attachName = [];
      this.$refs.form.validate(valid => {
        if (valid) {
          for (var i = 0; i < this.Attachment.length; i++) {
            if (
              this.Attachment[i].isNeed === "Y" &&
              !this.Attachment[i].attachmentId
            ) {
              this.attachName.push(this.Attachment[i].name);
            }
          }
          if (this.attachName.length > 0) {
            this.$alert("请上传" + this.attachName, "提示", {
              confirmButtonText: "确定",
              type: "error"
            });
          } else {
            this.getForm();
          }
        }
      });
    },
    getForm() {
      this.attachData = [];
      this.Attachment.forEach(item => {
        if (item.attachmentId != null) {
          this.attachData.push(item.attachmentId);
        }
      });
      this.applydata.attachmentId = this.attachData.join(",");
      this.$axios({
        url: "/p/support/apply/saveApply",
        method: "post",
        data: this.applydata
      }).then(res => {
        if (res.data.code == 0) {
          console.log(res);
          this.$alert("您已成功提交！", "成功", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push({
                path: "/Support/SupportRecord",
                // path: "/Support/SupportRecord?id=" + id + "$title" + title,
                query: {
                  id: this.$route.query.id,
                  title: this.$route.query.title
                }
              });
            }
          }).catch(() => {
            this.$message({
              type: "error",
              message: "成功取消"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },
    getAttachmentType() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "policy_apply_attachment_type"
        }
      }).then(res => {
        this.attachmentType = res.data.data;
      });
    },
    forattachmentType(value) {
      for (var i = 0; i < this.attachmentType.length; i++) {
        if (this.attachmentType[i].dictValue === value) {
          return this.attachmentType[i].dictLabel;
        }
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.sub-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 8px;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.upload-flex {
  display: flex;
  flex-wrap: wrap;
}
.upload-img {
  position: relative;
  width: 73px;
  height: 73px;
  padding: 5px;
  display: flex;
}
.disUoloadSty .uploadchange {
  display: none;
}
.textwidth {
  display: inline-block;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.textshow {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
