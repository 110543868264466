<template>
  <div class="message">
    <ul>
      <li
        v-for="(item, index) in text"
        :key="index"
        :class="{ on: now == index }"
      >
        <router-link :to="item['path']">{{ item["label"] }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      now: this.active - 1,
      text: [
        { label: "企业基本信息", path: "/Basic" },
        { label: "站内信件管理", path: "/Mail" },
        { label: "企业项目管理", path: "/Project" },
        { label: "基地信息管理", path: "/Base" },
        { label: "产品销售管理", path: "/Sales" },
        { label: "产品产值管理", path: "/Report" },
        { label: "企业产品信息", path: "/Product" },
        { label: "专家答疑互动", path: "/Answering" },
        { label: "扶持政策信息", path: "/Support" },
        { label: "企业需求信息", path: "/Demand" },
        { label: "摄像设备信息", path: "/Camera" },
      ],
    };
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #000;
}
.message ul {
  width: 140px;
  margin-top: 50px;
}
.message ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 10px 15px;
  border: 1px solid #d3d3d3;
  margin-top: -1px;
  box-sizing: border-box;
}
.message ul li.on {
  background-color: #228fe2;
}
.message ul li.on a {
  color: #fff;
}
</style>
