import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        userInfo:undefined
    },
    mutations:{
        setUserInfo(state,userInfo) {
            state.userInfo = userInfo
            sessionStorage.setItem("userInfo",JSON.stringify(userInfo))
        },
        removeUserInfo(state){
            state.userInfo = undefined
            sessionStorage.removeItem("userInfo")
        }
    }
})