import $axios from "../../utils/axios";
// 基本请求
export const $http = (url, params) => {
  return $axios({
    url,
    params
  });
};
// 获取字典值
export const getDictData = dictType => {
  return $axios({
    url: "/p/utils/dict/getType",
    params: {
      dictType
    }
  });
};
