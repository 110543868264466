<template>
  <div>
    <div class="pc-banner">新闻动态</div>
    <div class="w">
      <!-- 手机端 -->
      <div class="banner">新闻动态</div>
      <div class="mobile-catalog">
        <div class="mobile-catalog-item active">新闻动态</div>
      </div>
      <!-- 手机端 -->
      <div class="news">
        <div class="news-content">
          <ul class="news-list">
            <template v-for="(item, index) in DynamicData">
              <li
                :key="index"
                v-if="index + 1 >= page * 20 - 19 && index + 1 <= page * 20"
              >
                <a
                  href="javascript:;"
                  @click="handleView(item)"
                  class="flex-wrap"
                >
                  <div class="news-list-title">
                    {{ item.title }}
                  </div>
                  <div class="news-list-meta">{{ item.date }}</div>
                </a>
              </li>
            </template>
          </ul>
          <el-empty
            description="该栏目下暂无文章"
            v-if="DynamicData.length == 0"
          ></el-empty>
          <div class="pagination-box">
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="pager"
              :total="DynamicData.length"
              :page-size="20"
              @current-change="handlePages"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.getList(this.$route.query.id);
  },
  data() {
    return {
      catalogInfo: {},
      DynamicData: [],
      page: 1
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/thirdApi/manuscript/getManuscriptList",
        params: {
          id: id
        }
      }).then(res => {
        const info = res.data.data;
        this.catalogInfo = info.category;
        this.DynamicData = info.articles;
      });
    },
    handlePages(page) {
      this.page = page;
    },
    handleView(item) {
      if (item.type === "url") {
        window.open(item.url);
      } else {
        this.$router.push("/Explicit?id=" + item.id);
      }
    }
  }
};
</script>
<style scoped>
.news {
  margin-bottom: 20px;
  display: flex;
}
.news .news-catalog {
  background: #38424d;
  color: #fff;
  width: 216px;
  min-height: 527px;
}
.news .news-catalog .news-catalog-title {
  background: #0453a0;
  padding: 25px 20px;
}
.news .news-catalog .news-catalog-list li {
  font-size: 14px;
  padding: 15px;
  border-bottom: 1px solid #555;
}
.news .news-content {
  flex: 1;
  margin-left: 20px;
}
.news .news-content .news-content-title {
  border-bottom: 2px solid #0453a0;
  color: #fff;
}
.news .news-content .news-content-title span {
  background: #0453a0;
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
}

.news .news-list {
  padding-left: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 50px;
}
.news .news-list li {
  width: 100%;
  list-style: disc;
  padding: 20px 0;
  border-bottom: 1px dashed #d3d3d3;
  font-size: 18px;
}
.news .news-list li a {
  text-decoration: none;
}
.news .news-list .news-list-title:hover {
  color: #0453a0;
}
.news .news-list .news-list-title {
  color: #333;
  width: 650px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.news .news-list .news-list-meta {
  color: #999;
  font-size: 14px;
}

.mobile-catalog {
  display: none;
}
@media (max-width: 768px) {
  .news-catalog,
  .news-content-title {
    display: none;
  }
  .news {
    margin: 0;
  }
  .news .news-list {
    padding: 0 20px;
    display: block;
  }
  .news .news-list li {
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px dashed #d3d3d3;
    list-style: none;
  }
  .news .news-list a {
    display: block;
  }
  .news .news-list .news-list-title {
    width: 100%;
    white-space: normal;
  }
  .news .news-list .news-list-meta {
    margin-top: 5px;
  }
  .news .news-content {
    margin: 0;
  }
  .pagination-box {
    margin: 20px 0;
    width: 100%;
  }
  .w {
    padding-top: 60px;
  }
  .mobile-catalog {
    padding: 0 20px;
    display: block;
    white-space: nowrap;
    overflow: scroll;
    background: #eee;
  }
  .mobile-catalog .mobile-catalog-item {
    margin-right: 15px;
    font-size: 14px;
    padding: 12px 0;
    display: inline-block;
  }
  .mobile-catalog .mobile-catalog-item.active {
    color: #0453a0;
    border-bottom: 2px solid #0453a0;
    box-sizing: border-box;
  }
}
</style>
