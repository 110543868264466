<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="5"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="产品销售填报"></Title>
          <!-- 表框 -->
          <el-form ref="form" :model="SalesEdit" :rules="rules">
            <div class="form">
              <div class="form-item">
                <span>年份：</span>
                <el-form-item class="form-item-content" prop="year">
                  <el-date-picker
                    v-model="SalesEdit.year"
                    type="year"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>月份：</span>
                <el-form-item class="form-item-content" prop="month">
                  <el-date-picker
                    v-model="SalesEdit.month"
                    type="month"
                    value-format="MM"
                    format="MM月"
                    style="width: 100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>销售总额：</span>
                <div class="form-item-content">
                  <el-input v-model="SalesEdit.saleGross">
                    <template slot="append">万元</template>
                  </el-input>
                </div>
              </div>
              <div class="form-item">
                <span>销售总量：</span>
                <div class="form-item-content">
                  <el-input v-model="SalesEdit.saleTotal">
                    <template slot="append">公斤</template>
                  </el-input>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="region">
              <el-table :data="SalesEdit.firstList" border>
                <el-table-column prop="indicesName" label="销售渠道">
                </el-table-column>
                <el-table-column prop="value" label="销售总额（万元）">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.value"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="region">
              <el-table :data="SalesEdit.secondList" border>
                <el-table-column prop="indicesName" label="销售地区">
                </el-table-column>
                <el-table-column prop="value" label="费用总额（万元）">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.value"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
          <div class="btn-box">
            <el-button @click="handleBack">返 回</el-button>
            <Button text="暂存" @click="handleSubmit('save')"></Button>
            <Button
              text="提交"
              class="submit"
              @click="handleSubmit('submit')"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
import Button from "../../components/assembly/Button";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar,
    Button
  },
  created() {
    this.getList(this.$route.query.id);
  },
  data() {
    return {
      SalesEdit: {},

      form: {
        operateType: "",
        year: "",
        month: "",
        saleGross: "",
        saleTotal: ""
      },
      rules: {
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        month: [{ required: true, message: "请选择月份", trigger: "change" }]
      }
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/industry/subject/salesReport/getSalesReportById",
        method: "post",
        data: {
          reportId: id
        }
      }).then(res => {
        this.SalesEdit = res.data.data;
      });
    },
    handleBack() {
      this.$router.back();
    },
    handleSubmit(type) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.SalesEdit.operateType = type;
          this.$axios({
            url: "/p/industry/subject/salesReport/saveSalesReport",
            method: "post",
            data: this.SalesEdit,
            json: true
          }).then(res => {
            if (res.data.code == 0) {
              const message = type === "save" ? "暂存" : "提交";
              this.$message({
                message: `${message}成功`,
                type: "success"
              });
              this.$router.push("/Sales");
            } else {
              this.$utils.alertMessage({
                type: "error",
                message: res.data.msg
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.import {
  padding: 20px;
  border-left: 1px solid #d2d2d2;
}
.region {
  margin: 20px 0;
}
</style>
