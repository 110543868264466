<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="9"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="扶持政策信息"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="8"
                ><div class="form-group">
                  <span>政策标题：</span>
                  <el-input v-model="detail.title" size="mini"> </el-input></div
              ></el-col>
              <el-col :span="2">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >搜索</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="SupportData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="title" label="政策标题"> </el-table-column>
              <el-table-column prop="documentNumber" label="文号" width="180">
              </el-table-column>
              <el-table-column prop="policyType" label="政策类型" width="110">
                <template slot-scope="scope">
                  {{ forpolicyType(scope.row.policyType) }}
                </template>
              </el-table-column>
              <el-table-column prop="deptName" label="发布部门" width="160">
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.policyId)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-document"
                    @click="handleRecord(scope.row.policyId)"
                    >申报记录</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="Total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList();
    this.getTypeDictData();
  },
  data() {
    return {
      policyType: "",
      detail: {
        pageNum: 1,
        pageSize: 5,
        title: ""
      },
      SupportData: [],
      Total: 0
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/support/support/getSupportList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.SupportData = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    search() {
      this.detail.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.detail.pageNum = page;
      this.getList();
    },
    handleView(id) {
      this.$router.push({
        path: "/Support/SupportList",
        query: {
          id: id
        }
      });
    },
    handleRecord(id) {
      this.$router.push({
        path: "/Support/SupportRecord",
        query: {
          id: id
        }
      });
    },
    getTypeDictData() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "support_policy_type"
        }
      }).then(res => {
        this.policyType = res.data.data;
      });
    },
    forpolicyType(value) {
      for (var i = 0; i < this.policyType.length; i++) {
        if (this.policyType[i].dictValue === value) {
          return this.policyType[i].dictLabel;
        }
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
