<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="7"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业产品信息"></Title>
          <el-form ref="form" :model="form" :rules="rules">
            <div class="form">
              <div class="form-item">
                <span><i class="color-red">*</i> 产品名称：</span>
                <el-form-item
                  class="form-item-content"
                  prop="finishedProductName"
                >
                  <el-input v-model="form.finishedProductName"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span><i class="color-red">*</i> 产品类别：</span>
                <el-form-item class="form-item-content" prop="categoryId">
                  <el-select
                    v-model="form.categoryId"
                    placeholder=""
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in mainState"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>品牌名称：</span>
                <el-form-item class="form-item-content" prop="brand">
                  <el-input v-model="form.brand"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>出产地：</span>
                <el-form-item class="form-item-content" prop="productPlace">
                  <el-input v-model="form.productPlace"></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>购买链接：</span>
                <el-form-item class="form-item-content" prop="productPlace">
                  <el-input v-model="form.buyLink"></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>产品描述：</span>
                <el-form-item class="form-item-content" prop="describes">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    v-model="form.describes"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>产品图片：</span>
                <el-form-item class="form-item-content">
                  <div class="upload-img" v-if="form.images">
                    <el-image
                      style="width: 100%; height: 100%; border-radius: 6px"
                      :src="form.images"
                      fit="cover"
                      :preview-src-list="[form.images]"
                    ></el-image>
                    <i class="el-icon-error delimg" @click="delImage"></i>
                  </div>
                  <el-upload
                    v-else
                    action="/chainer/p/utils/common/upload"
                    list-type="picture-card"
                    class="picture-img"
                    :show-file-list="false"
                    :on-success="uploadImg"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </el-form>

          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="primary" @click="handleSubmit">提 交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
import { mapState } from "vuex";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  computed: {
    ...mapState(["userInfo"])
  },
  data() {
    return {
      showImg: true,
      mainState: [],
      form: {
        brand: "",
        categoryId: "",
        chainId: "",
        describes: "",
        finishedProductId: "",
        finishedProductName: "",
        images: "",
        productPlace: "",
        subjectId: "",
        buyLink: ""
      },
      rules: {
        finishedProductName: [
          { required: true, message: "请输入产品名称", trigger: "blur" }
        ],
        categoryId: [
          { required: true, message: "请选择产品类别", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.getMainState();
  },
  methods: {
    getMainState() {
      this.$axios({
        // 查个地址
        url: "/p/utils/dict/getType",
        params: {
          // 固定名称，哪里查看这个需要请求值
          dictType: "product_category"
        }
      }).then(res => {
        this.mainState = res.data.data;
      });
    },
    getForm() {
      this.$axios({
        url: "/p/industry/product/saveProduct",
        method: "post",
        data: this.form
      }).then(res => {
        if (res.data.code == 0) {
          this.$alert("您已成功提交！", "成功", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push("/Product");
            }
          }).catch(() => {
            this.$message({
              type: "error",
              message: "成功取消"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },

    // 上传相关照片
    uploadImg(response) {
      this.form.images = "/chainer" + response.data.fileUrl;
    },
    delImage() {
      this.form.images = "";
    },
    handleExceed(files, fileList) {
      this.$confirm(
        `当前限制选择 1个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      );
    },
    handleBack() {
      this.$router.back();
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getForm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.position {
  margin-top: 40px;
}
.picture-img {
  margin: 5px;
}
.upload-img {
  position: relative;
  width: 73px;
  height: 73px;
  padding: 5px;
}
.upload-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.delimg {
  position: absolute;
  top: -9px;
  right: -9px;
  transform: translate(-30%, 30%);
  font-size: 18px;
  color: #f56c6c;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}
</style>
