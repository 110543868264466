<template>
  <div>
    <div class="pc-banner">咨询投诉详情</div>
    <div class="w">
      <Title text="反馈信息"></Title>
      <div class="form">
        <div class="form-item">
          <span>标题：</span>
          <div class="form-item-content">
            {{ submitData.title }}
          </div>
        </div>
        <div class="form-item">
          <span>类型：</span>
          <div class="form-item-content">
            {{ submitData.type == 0 ? "咨询" : "投诉" }}
          </div>
        </div>
        <div class="form-item" style="width: 100%">
          <span>内容：</span>
          <div class="form-item-content">
            {{ submitData.contents }}
          </div>
        </div>
        <div class="form-item">
          <span>提交人：</span>
          <div class="form-item-content">
            {{ submitData.name }}
          </div>
        </div>
        <div class="form-item">
          <span>手机号码：</span>
          <div class="form-item-content">
            {{ submitData.phone }}
          </div>
        </div>
        <!-- <div class="form-item" style="width: 100%">
          <span>身份证号码</span>
          <div class="form-item-content">
            {{ submitData.idCard }}
          </div>
        </div> -->
      </div>
      <Title text="回复信息"></Title>
      <div class="form">
        <div class="form-item">
          <span>回复人员：</span>
          <div class="form-item-content">
            {{ submitData.replyByName }}
          </div>
        </div>
        <div class="form-item">
          <span>回复时间：</span>
          <div class="form-item-content">
            {{ submitData.replyTime }}
          </div>
        </div>
        <div class="form-item" style="width: 100%">
          <span>回复内容：</span>
          <div class="form-item-content">
            {{ submitData.replyContents }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "../components/assembly/Title";
export default {
  components: { Title },
  data() {
    return {
      submitData: []
    };
  },
  created() {
    this.getType(this.$route.query.id);
  },
  methods: {
    getType(letterId) {
      this.$axios({
        url: "/p/web/letter/getLetter",
        method: "post",
        params: {
          letterId: letterId
        }
      }).then(res => {
        this.submitData = res.data.data;
      });
    }
  }
};
</script>
<style scoped>
.w {
  height: 100%;
  background-color: #fff;
  padding: 0 20px 20px;
  box-sizing: border-box;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
</style>
