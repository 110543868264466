<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="11"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="摄像设备信息"></Title>
          <div>
            <el-table :data="cameraList" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="cameraName" label="摄像头区域名称">
              </el-table-column>
              <el-table-column
                prop="cameraCode"
                label="摄像头编码"
              ></el-table-column>
              <el-table-column
                prop="cameraStatus"
                label="摄像头状态"
                width="200"
              >
              </el-table-column>
              <el-table-column label="操作" align="center" width="170">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.cameraId)"
                    v-if="scope.row.cameraStatus=='在线'"
                    >播放</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
import { mapState } from "vuex";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar,
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.getList();
  },
  data() {
    return {
      cameraList: [],
    };
  },
  methods: {
    getList() {
      this.$axios({
        url:
          "/p/industry/camera/getBindSubjectCameraList?subjectId=" +
          this.userInfo.subjetId,
        method: "post",
      }).then((res) => {
        res.data.data.forEach((item) => {
          if (item.cameraStatus == "online") {
            item.cameraStatus = "在线";
          } else {
            item.cameraStatus = "离线";
          }
        });
        this.cameraList = res.data.data;
      });
    },
    handleView(id) {
      this.$router.push("/Camera/CameraList?id=" + id);
    },
  },
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
