<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="3"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业项目管理"></Title>
          <!-- 表框 -->
          <div class="form">
            <div class="form-item percent">
              <span>所属产业链：</span>
              <div class="form-item-content">
                {{ detail.chainName }}
              </div>
            </div>
            <div class="form-item">
              <span>项目标题：</span>
              <div class="form-item-content">
                {{ detail.projectTitle }}
              </div>
            </div>
            <div class="form-item">
              <span>项目年份：</span>
              <div class="form-item-content">
                {{ detail.year }}
              </div>
            </div>
            <div class="form-item">
              <!-- ? -->
              <span>项目状态：</span>
              <div class="form-item-content">
                {{ forGetDiction(detail.declareStatus) }}
              </div>
            </div>
            <div class="form-item">
              <span>申报日期：</span>
              <div class="form-item-content">
                {{ detail.declareDate }}
              </div>
            </div>
            <div class="form-item">
              <span>承担主体：</span>
              <div class="form-item-content">
                {{ detail.undertakerName }}
              </div>
            </div>
            <div class="form-item">
              <span>计划投入资金：</span>
              <div class="form-item-content">
                {{ detail.totalPlanInvestAmt }}万元
              </div>
            </div>
            <div class="form-item">
              <span>负责人：</span>
              <div class="form-item-content">
                {{ detail.director }}
              </div>
            </div>
            <div class="form-item">
              <span>联系方式：</span>
              <div class="form-item-content">
                {{ detail.telephone }}
              </div>
            </div>
            <div class="form-item">
              <span>E-mail：</span>
              <div class="form-item-content">
                {{ detail.email }}
              </div>
            </div>
            <div class="form-item">
              <span>通讯地址：</span>
              <div class="form-item-content">
                {{ detail.mailAddress }}
              </div>
            </div>
            <div class="form-item">
              <span>经度：</span>
              <div class="form-item-content">
                {{ detail.longitude }}
              </div>
            </div>
            <div class="form-item">
              <span>纬度：</span>
              <div class="form-item-content">
                {{ detail.latitude }}
              </div>
            </div>
            <div class="form-item percent">
              <span>项目简介：</span>
              <div class="form-item-content">
                {{ detail.introduction }}
              </div>
            </div>
            <div class="form-item percent">
              <span>建设内容：</span>
              <div class="form-item-content">
                {{ detail.buildContent }}
              </div>
            </div>
            <div class="form-item percent">
              <span>建设目标：</span>
              <div class="form-item-content">
                {{ detail.buildTarget }}
              </div>
            </div>
            <div class="form-item percent">
              <span>效益分析：</span>
              <div class="form-item-content">
                {{ detail.benefitAnalysis }}
              </div>
            </div>
            <div class="form-item percent">
              <span>申报材料：</span>
              <div class="form-item-content">
                <div
                  v-for="(item, index) in detail.applyMaterialFileList"
                  :key="index"
                  class="relevant"
                >
                  <div class="relevant-text">{{ item.fileName }}</div>
                  <a
                    :href="'/chainer' + item.fileUrl"
                    :download="item.fileName"
                  >
                    <i class="el-icon-download"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="form-item percent">
              <span>备注：</span>
              <div class="form-item-content">
                {{ detail.remark }}
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
// import Button from "../../components/assembly/Button";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
    // Button,
  },
  created() {
    this.getList(this.$route.query.id);
    this.getDiction();
  },
  data() {
    return {
      Diction: [],
      detail: [],
      value: "",
      value1: "",
      project: [
        { label: "项目标题:" },
        { label: "项目年份:", type: "date" },
        {
          label: "项目性质:",
          type: "drop",
          dicData: [
            {
              value: "选项1",
              label: "民营"
            },
            {
              value: "选项2",
              label: "国企"
            },
            {
              value: "选项3",
              label: "私营"
            }
          ]
        },
        { label: "申报日期:", type: "date" },
        {
          label: "产业链:",
          type: "drop",
          dicData: [
            {
              value: "选项1",
              label: "民营"
            },
            {
              value: "选项2",
              label: "国企"
            },
            {
              value: "选项3",
              label: "私营"
            }
          ]
        },
        { label: "承担主体:" },
        { label: "负责人:" },
        { label: "联系方式:" },
        { label: "E-mail:" },
        { label: "通讯地址:" },
        { label: "邮政编码:" },
        { label: "建设地点:" },
        { label: "经度:" },
        { label: "纬度:" },
        { label: "计划投入资金:", append: "万元" },
        { label: "项目简介:", line: true },
        { label: "建设内容:", line: true },
        { label: "建设目标:", line: true },
        { label: "效益分析:", line: true },
        { label: "备注:", line: true },
        { label: "申报材料:", upload: "upload", line: true }
      ]
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/project/project/getProjectById",
        method: "post",
        data: { projectId: id }
      }).then(res => {
        this.detail = res.data.data;
      });
    },
    getDiction() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "project_declare_status"
        }
      }).then(res => {
        this.Diction = res.data.data;
      });
    },
    forGetDiction(value) {
      for (var i = 0; i < this.Diction.length; i++) {
        if (this.Diction[i].dictValue == value) {
          return this.Diction[i].dictLabel;
        }
      }
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.import {
  padding: 20px;
  border-left: 1px solid #d2d2d2;
}
.el-form-item {
  margin-bottom: 0;
}
.relevant {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relevant-text {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 0;
}
.relevant i {
  color: #409eff;
  cursor: pointer;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
