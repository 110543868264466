<template>
  <div class="title">
    <div>{{ text }}</div>
    <div class="btn" v-if="button">
      <el-button size="mini" icon="el-icon-plus" @click="add">增 加</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    button: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    add() {
      this.$emit("add");
    }
  }
};
</script>
<style scoped>
.title {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #575757;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.title div {
  font-size: 20px;
  font-weight: 500;
  color: #343434;
  font-weight: 600;
}
.title .btn {
  display: flex;
  /* background-color: green; */
  margin-bottom: 5px;
  color: #08070a;
}
</style>
