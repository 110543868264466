<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="10"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业需求详情"></Title>
          <el-form :model="ListData">
            <div class="form">
              <div class="form-item">
                <span> 需求标题：</span>
                <el-form-item class="form-item-content" prop="title">
                  {{ ListData.title }}
                </el-form-item>
              </div>
              <div class="form-item">
                <span> 需求状态：</span>
                <el-form-item class="form-item-content" prop="status">
                  {{ forStatus(ListData.status) }}
                </el-form-item>
              </div>
              <div class="form-item percent percent">
                <span>需求详情：</span>
                <el-form-item class="form-item-content" prop="content">
                  <pre>{{ ListData.content }}</pre>
                </el-form-item>
              </div>
              <div class="form-item percent percent">
                <span>相关文件：</span>
                <el-form-item class="form-item-content" prop="attachment">
                  <div
                    v-for="(item, index) in ListData.attachmentFileList"
                    :key="index"
                    class="relevant"
                  >
                    <div class="relevant-text">
                      {{ item.fileName }}
                    </div>
                    <a
                      :href="'/chainer' + item.fileUrl"
                      :download="item.fileName"
                    >
                      <i class="el-icon-download"></i
                    ></a>
                  </div>
                </el-form-item>
              </div>
              <template
                v-if="
                  ListData.status === 'accept_disagree' ||
                    ListData.status === 'check_disagree'
                "
              >
                <div class="form-item percent percent">
                  <span>受理意见</span>
                  <el-form-item class="form-item-content">
                    {{ ListData.acceptOpinion }}
                  </el-form-item>
                </div>
                <div class="form-item percent percent">
                  <span>受理时间</span>
                  <el-form-item class="form-item-content">
                    {{ ListData.acceptTime }}
                  </el-form-item>
                </div>
              </template>
            </div>
          </el-form>
          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList(this.$route.query.id);
    this.getStatusLabel();
  },
  data() {
    return {
      ListData: {},
      StatusLabel: {}
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/demand/getDemand",
        method: "post",
        data: {
          demandId: id
        }
      }).then(res => {
        this.ListData = res.data.data;
      });
    },
    getStatusLabel() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "industry_demand_status"
        }
      }).then(res => {
        this.StatusLabel = res.data.data;
      });
    },
    forStatus(value) {
      for (var i = 0; i < this.StatusLabel.length; i++) {
        if (this.StatusLabel[i].dictValue == value) {
          return this.StatusLabel[i].dictLabel;
        }
      }
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.all {
  background-color: #eee;
}
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.relevant {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relevant-text {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.relevant i {
  color: #409eff;
  cursor: pointer;
}
</style>
