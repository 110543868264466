<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="9"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="扶持政策详情"></Title>
          <div class="sub-title">基本信息</div>
          <div class="form">
            <div class="form-item">
              <span>政策标题：</span>
              <div class="form-item-content">
                {{ detail.title }}
              </div>
            </div>
            <div class="form-item">
              <span>文号：</span>
              <div class="form-item-content">
                {{ detail.documentNumber }}
              </div>
            </div>
            <div class="form-item">
              <span>政策类型：</span>
              <div class="form-item-content">
                {{ forpolicyType(detail.policyType) }}
              </div>
            </div>
            <div class="form-item">
              <span>产业链：</span>
              <div class="form-item-content">
                {{ detail.chainName }}
              </div>
            </div>
            <div class="form-item">
              <span>发布部门：</span>
              <div class="form-item-content">{{ detail.deptName }}</div>
            </div>
            <div class="form-item">
              <span>审核人员：</span>
              <div class="form-item-content">{{ detail.userName }}</div>
            </div>

            <div class="form-item">
              <span>起效日期：</span>
              <div class="form-item-content">
                {{ detail.startDate ? detail.startDate.substr(0, 10) : "无" }}
              </div>
            </div>
            <div class="form-item">
              <span>失效时间：</span>
              <div class="form-item-content">
                {{ detail.endDate ? detail.endDate.substr(0, 10) : "无" }}
              </div>
            </div>
            <div class="form-item percent">
              <span>正文内容：</span>
              <div class="form-item-content" v-html="detail.content"></div>
            </div>
            <div class="form-item percent">
              <span>相关文件：</span>
              <div class="form-item-content">
                <div
                  v-for="(item, index) in detail.policyFile"
                  :key="index"
                  class="relevant"
                >
                  <div class="relevant-text">{{ item.fileName }}</div>
                  <a
                    :href="'/chainer' + item.fileUrl"
                    :download="item.fileName"
                  >
                    <i class="el-icon-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-title">申报所需材料</div>
          <div>
            <el-table :data="Attachment" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="name" label="材料名称"> </el-table-column>

              <el-table-column prop="suffix" label="材料格式">
              </el-table-column>
              <el-table-column prop="attachmentType" label="材料类型">
                <template slot-scope="scope">
                  {{ forattachmentType(scope.row.attachmentType) }}
                </template>
              </el-table-column>
              <el-table-column prop="isNeed" label="是否必需">
                <template slot-scope="scope">
                  {{ scope.row.isNeed === "Y" ? "是" : "否" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList(this.$route.query.id, this.$route.query.title);
    this.getAttachment(this.$route.query.id);
    this.getTypeDictData();
    this.getAttachmentType();
  },
  data() {
    return {
      detail: [],
      Attachment: [],
      policyType: "",
      attachment: ""
    };
  },
  methods: {
    getList(id, title) {
      this.$axios({
        url: "/p/support/support/getSupport",
        method: "post",
        data: {
          id: id,
          title: title
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    },
    getAttachment(id) {
      this.$axios({
        url: "/p/support/attachment/getList",
        method: "post",
        data: {
          policyId: id
        }
      }).then(res => {
        this.Attachment = res.data.data.rows;
      });
    },
    getTypeDictData() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "support_policy_type"
        }
      }).then(res => {
        this.policyType = res.data.data;
      });
    },
    forpolicyType(value) {
      for (var i = 0; i < this.policyType.length; i++) {
        if (this.policyType[i].dictValue === value) {
          return this.policyType[i].dictLabel;
        }
      }
    },
    getAttachmentType() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "policy_apply_attachment_type"
        }
      }).then(res => {
        this.attachment = res.data.data;
      });
    },
    forattachmentType(value) {
      for (var i = 0; i < this.attachment.length; i++) {
        if (this.attachment[i].dictValue === value) {
          return this.attachment[i].dictLabel;
        }
      }
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.sub-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 8px;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.relevant {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relevant-text {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.relevant i {
  color: #409eff;
  cursor: pointer;
}
</style>
