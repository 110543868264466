<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="4"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业基地信息"></Title>
          <!-- 标题 -->
          <div class="sub-title">基本信息</div>
          <!-- 表框 -->
          <div class="form">
            <div class="form-item">
              <span>基地名称：</span>
              <div class="form-item-content">
                {{ BaseData.baseName }}
              </div>
            </div>
            <div class="form-item">
              <span>基地类型：</span>
              <div class="form-item-content">
                {{ getDictLabel(getType, BaseData.baseType) }}
              </div>
            </div>
            <div class="form-item">
              <span>产品品种：</span>
              <div class="form-item-content">
                {{ BaseData.breed }}
              </div>
            </div>
            <div class="form-item">
              <span>基地状态：</span>
              <div class="form-item-content">
                {{ getDictLabel(getStatus, BaseData.status) }}
              </div>
            </div>
            <div class="form-item">
              <span>负责人：</span>
              <div class="form-item-content">
                {{ BaseData.leader }}
              </div>
            </div>
            <div class="form-item">
              <span>联系电话：</span>
              <div class="form-item-content">
                {{ BaseData.telephone }}
              </div>
            </div>
            <div class="form-item">
              <span>所在城市：</span>
              <div class="form-item-content">
                {{ BaseData.cityName }}
              </div>
            </div>
            <div class="form-item">
              <span>所在县区：</span>
              <div class="form-item-content">
                {{ BaseData.countyName }}
              </div>
            </div>
            <div class="form-item">
              <span>所在街道/镇：</span>
              <div class="form-item-content">
                {{ BaseData.splicingArea }}
              </div>
            </div>
            <div class="form-item">
              <span>所属产业链：</span>
              <div class="form-item-content">
                {{ BaseData.chainName }}
              </div>
            </div>
            <div class="form-item percent">
              <span>详细地址：</span>
              <div class="form-item-content">
                {{ BaseData.address }}
              </div>
            </div>
            <div class="form-item">
              <span>占地面积：</span>
              <div class="form-item-content">
                {{ BaseData.coverSize }}
              </div>
            </div>
            <div class="form-item">
              <span>农机人员数量：</span>
              <div class="form-item-content">
                {{ BaseData.staffCount }}
              </div>
            </div>
            <div class="form-item">
              <span>基地成立时间：</span>
              <div class="form-item-content">
                {{ BaseData.buildDate }}
              </div>
            </div>
            <div class="form-item">
              <span>开放时间：</span>
              <div class="form-item-content">
                {{ BaseData.openHours }}
              </div>
            </div>
            <div class="form-item percent">
              <span>描述：</span>
              <div class="form-item-content">
                {{ BaseData.baseName }}
              </div>
            </div>
            <div class="form-item percent">
              <span>基地图片：</span>
              <div class="form-item-content">
                <div class="baseimg-i">
                  <el-image
                    v-for="(item, index) in BaseData.images"
                    :key="index"
                    :src="item"
                    class="baseimg-img"
                    fit="cover"
                    :preview-src-list="BaseData.images"
                  ></el-image>
                </div>
              </div>
            </div>
            <div class="form-item percent">
              <span>相关材料：</span>
              <div class="form-item-content">
                <div
                  v-for="(item, index) in BaseData.attachmentFileList"
                  :key="index"
                  class="relevant"
                >
                  <div class="relevant-text">{{ item.fileName }}</div>
                  <a
                    :href="'/chainer' + item.fileUrl"
                    :download="item.fileName"
                  >
                    <i class="el-icon-download"></i>
                  </a>
                </div>
                <!-- {{ BaseData.attachmentFileList }} -->
              </div>
            </div>
          </div>
          <div class="sub-title position">位置信息</div>
          <div class="form">
            <div class="form-item">
              <span>经度：</span>
              <!-- 进行判断样式type -->
              <div class="form-item-content">
                {{ BaseData.longitude }}
              </div>
            </div>
            <div class="form-item">
              <span>纬度：</span>
              <div class="form-item-content">
                {{ BaseData.latitude }}
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList(this.$route.query.id);
    this.getDictData("industry_base_type", "getType");
    this.getDictData("industry_subject_status", "getStatus");
  },
  data() {
    return {
      BaseData: [],
      getType: [],
      getStatus: []
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/industry/base/getBaseById",
        method: "post",
        data: {
          baseId: id
        }
      }).then(res => {
        // 照片分割
        res.data.data.images = res.data.data.images.split(",");
        this.BaseData = res.data.data;
      });
    },
    // 字典封装
    getDictData(dictType, targetData) {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: dictType
        }
      }).then(res => {
        this[targetData] = res.data.data;
      });
    },
    // 循环
    getDictLabel(dictArray, value) {
      for (var i = 0; i < dictArray.length; i++) {
        if (dictArray[i].dictValue === value) {
          return dictArray[i].dictLabel;
        }
      }
      // ES6
      // return dictArray.find((item) => item.dictValue === value).dictLabel;
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
.sub-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 8px;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.position {
  margin-top: 40px;
}
.baseimg {
  padding: 5px;
}
.baseimg-i {
  display: flex;
  flex-wrap: wrap;
}
.baseimg-img {
  padding: 5px;
  width: 73px;
  height: 73px;
}
.relevant {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relevant-text {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.relevant i {
  color: #409eff;
  cursor: pointer;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
