<template>
    <div>
        <div class="pc-banner">专题专栏</div>
        <div class="w">
            <!-- 手机端 -->
            <div class="banner">专题专栏</div>
            <div class="mobile-catalog">
                <div class="mobile-catalog-item" v-for="(item, index) in detailData"
                    :class="{ active: checked == index }" :key="index" @click="handlechecked(index, item)">
                    {{ item.name }}
                </div>
            </div>
            <!-- 手机端 -->
            <div class="specialContent">
                <div class="mainLeft">
                    <div class="LeftList">
                        <ul>
                            <li v-for="(item, index) in detailData" :key="index" :class="{ on: checked == index }"
                                @click="handlechecked(index, item)">
                                {{ item.name }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="mainRight">
                    <!-- <div class="rightTitle">
            <span>{{ detailData[checked].name }}</span>
          </div> -->
                    <div v-if="this.acticle.length != 0">
                        <div v-if="detailData[checked].catalogType == 'single'">
                            <div class="rightacticle">
                                <h2>{{ acticle[0].title }}</h2>
                                <div class="acticleData">
                                    <span>发布时间：{{
                      item.publishDate ? item.publishDate.substr(0, 10) : ""
                    }}</span>
                                </div>
                                <div v-html="acticle[0].contents" class="acticleContent"></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="marginCenter">
                                <div class="news">
                                    <ul class="news-list">
                                        <li v-for="(item, index) in acticle" :key="index">
                                            <a href="javascript:;" @click="goDetail(item)" class="flex-wrap">
                                                <div class="news-list-title">
                                                    {{ item.title }}
                                                </div>
                                                <div class="news-list-meta">
                                                    {{
                            item.publishDate
                              ? item.publishDate.substr(0, 10)
                              : ""
                          }}
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <el-pagination class="pagi" background layout="pager" :total="Total" :page-size="20"
                                    @current-change="changePage">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <el-empty description="该专题下暂无文章"></el-empty>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        created() {
            this.getList(this.$route.query.id);
            // this.getDetailCir();
        },
        data() {
            return {
                checked: 0,
                leftName: [],
                detailData: [],
                leftId: [],
                acticle: [],
                Total: 1,
                pageNum: 1
                // showList: false,
            };
        },
        methods: {
            getList(id) {
                this.$axios({
                    url: "/p/web/catalog/getWebCatalogList",
                    method: "post",
                    data: {
                        catalogSpecies: "special"
                    }
                }).then(res => {
                    this.detailData = res.data.data;
                    this.leftId = id || this.detailData[0].id;

                    this.getDetailCir();
                    for (var i = 0; i < this.detailData.length; i++) {
                        if (this.detailData[i].id == id) {
                            this.checked = i;
                        }
                    }
                });
            },
            handlechecked(index, row) {
                if (row.catalogType === "outlink") {
                    window.open(row.code)
                } else {
                    this.acticle = [];
                    this.Total = 0;
                    this.checked = index;
                    this.leftId = row.id;
                    this.getDetailCir();
                }
                // 同异步
                // if(this.acticle[this.checked].)
            },
            getDetailCir() {
                this.$axios({
                    url: "/p/web/content/getWebContentList",
                    method: "post",
                    data: {
                        catalogId: this.leftId,
                        pageNum: this.pageNum,
                        pageSize: 20
                    }
                }).then(res => {
                    this.acticle = res.data.data.rows;
                    this.Total = res.data.data.total;
                });
            },
            changePage(page) {
                this.pageNum = page;
                this.getDetailCir();
            },
            goDetail(row) {
                if (row.contentType === "link") {
                    window.open(row.url);
                } else {
                    this.$router.push("/Explicit?id=" + row.id + "&type=system");
                }
            }
        }
    };
</script>
<style scoped>
    ul {
        padding: 0;
        margin: 0;
    }

    .specialContent {}

    .mainLeft {
        width: 100%;
    }

    .LeftTitle {
        padding: 25px 20px;
        background: #0453a0;
        color: #fff;
    }

    .LeftList {
        margin-bottom: 10px;
    }

    .LeftList ul {
        display: flex;
        border-bottom: 1px solid #d3d4d5;
    }

    .LeftList li {
        display: flex;
        align-content: center;
        padding: 15px 25px 10px;
        font-size: 20px;
        text-align: center;
        color: #999;
        cursor: pointer;
    }

    .LeftList .on {
        font-weight: 700;
        color: #0b57a1;
        border-bottom: 2px solid #0654a1;
    }

    .mainRight {
        flex: 1;
    }

    .rightTitle {
        border-bottom: 2px solid #0453a0;
    }

    .rightTitle span {
        display: inline-block;
        min-width: 105px;
        color: #fff;
        background: #0453a0;
        padding: 10px 15px;
        text-align: center;
    }

    .rightacticle h2,
    .acticleData {
        display: flex;
        justify-content: center;
    }

    .acticleData {
        font-size: 14px;
    }

    .acticleContent {}

    .marginCenter .pagi {
        display: flex;
        justify-content: center;
    }

    .acticleLi {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        padding: 5px;
        list-style-type: disc;
    }

    .acticleLi:hover {
        cursor: pointer;
    }

    .news .news-list {
        padding-left: 25px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 50px;
    }

    .news .news-list li {
        width: 100%;
        list-style: disc;
        padding: 20px 0;
        border-bottom: 1px dashed #d3d3d3;
        font-size: 18px;
    }

    .news .news-list li a {
        text-decoration: none;
    }

    .news .news-list .news-list-title:hover {
        color: #0453a0;
    }

    .news .news-list .news-list-title {
        color: #333;
        width: 650px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .news .news-list .news-list-meta {
        color: #999;
        font-size: 14px;
    }

    .mobile-catalog {
        display: none;
    }

    @media (max-width: 768px) {

        .mainLeft,
        .rightTitle {
            display: none;
        }

        .specialContent,
        .mainRight {
            margin: 0;
        }

        .news .news-list {
            padding: 0 20px;
            display: block;
        }

        .news .news-list li {
            margin: 0;
            padding: 20px 0;
            border-bottom: 1px dashed #d3d3d3;
            list-style: none;
        }

        .news .news-list a {
            display: block;
        }

        .news .news-list .news-list-title {
            width: 100%;
            white-space: normal;
        }

        .news .news-list .news-list-meta {
            margin-top: 5px;
        }

        .news .news-content {
            margin: 0;
        }

        .pagi {
            margin: 20px 0;
            width: 100%;
        }

        .rightacticle {
            padding: 0 20px;
        }

        .rightacticle img {
            max-width: 100%;
            height: auto !important;
        }

        .rightacticle h2 {
            font-size: 20px;
        }

        .acticleData {
            font-size: 13px;
        }

        .w {
            padding-top: 60px;
        }

        .mobile-catalog {
            padding: 0 20px;
            display: block;
            white-space: nowrap;
            overflow: scroll;
            background: #eee;
        }

        .mobile-catalog .mobile-catalog-item {
            margin-right: 15px;
            font-size: 14px;
            padding: 12px 0;
            display: inline-block;
        }

        .mobile-catalog .mobile-catalog-item.active {
            color: #0453a0;
            border-bottom: 2px solid #0453a0;
            box-sizing: border-box;
        }
    }
</style>