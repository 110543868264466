<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="4"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="基地信息管理"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="6"
                ><div class="form-group">
                  <span>基地名称：</span>
                  <el-input v-model="detail.baseName" size="mini">
                  </el-input></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >搜索</el-button
                  ><el-button size="mini" type="primary" @click="handleAdd"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="ProjectData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="baseName" label="基地名称">
              </el-table-column>
              <el-table-column prop="chainName" label="所属产业链">
              </el-table-column>
              <el-table-column prop="address" label="地址"> </el-table-column>
              <el-table-column
                prop="status"
                label="基地状态"
                width="80"
                align="center"
              >
                <template slot-scope="scope">
                  {{ forMainState(scope.row.status) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="170">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.baseId)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleEdit(scope.row.baseId)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(scope.row.baseId)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="Total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 1. 点击事件，跳转 /Base/BaseList 页面并把 baseId 传过去
 * 2. /Base/BaseList 页面接收 baseId 参数并发送请求
 * 3. 渲染数据
 */
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList();
    this.getTypeDictData();
    this.getMainState();
  },
  data() {
    return {
      detail: {
        baseName: "",
        pageNum: 1,
        pageSize: 5
      },
      // 产品信息
      ProjectData: [],
      Total: 0,
      // 基地类型
      baseType: [],
      mainState: []
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/industry/base/getBaseList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.ProjectData = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    getTypeDictData() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "industry_base_type"
        }
      }).then(res => {
        this.baseType = res.data.data;
      });
    },
    getMainState() {
      this.$axios({
        // 查个地址
        url: "/p/utils/dict/getType",
        params: {
          // 固定名称，哪里查看这个需要请求值
          dictType: "industry_subject_status"
        }
      }).then(res => {
        this.mainState = res.data.data;
      });
    },
    formatType(value) {
      for (var i = 0; i < this.baseType.length; i++) {
        if (this.baseType[i].dictValue === value) {
          return this.baseType[i].dictLabel;
        }
      }
    },
    forMainState(value) {
      for (var i = 0; i < this.mainState.length; i++) {
        if (this.mainState[i].dictValue === value) {
          return this.mainState[i].dictLabel;
        }
      }
    },
    handleAdd() {
      this.$router.push("/Base/BaseAdd");
    },
    handleEdit(id) {
      this.$router.push("/Base/BaseEdit?id=" + id);
    },
    handleView(id) {
      this.$router.push("/Base/BaseList?id=" + id);
    },
    search() {
      this.detail.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.detail.pageNum = page;
      this.getList();
    },

    // 删除事件：
    handleDelete(baseId) {
      this.$confirm("此操作将永久删除该基地信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        // 1.点击按钮，弹窗提醒用户是否确认删除
        // 2.用户点击确认后，发删除请求
        // 3.删除请求完毕后，重新加载列表
        this.$axios({
          url: "/p/industry/base/removeBaseById",
          method: "post",
          data: {
            ids: baseId
          }
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
