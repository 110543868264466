<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="6"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="产品产值管理"></Title>
          <!-- 表框 -->
          <div class="form">
            <div class="form-item">
              <span>年份：</span>
              <div class="form-item-content">
                {{ detail.year }}
              </div>
            </div>
            <div class="form-item">
              <span>月份：</span>
              <div class="form-item-content">
                {{ detail.month }}
              </div>
            </div>
            <div class="form-item">
              <span>产值总量：</span>
              <div class="form-item-content">
                {{ detail.productionTotal }}公斤
              </div>
            </div>
            <div class="form-item">
              <span>产值总额：</span>
              <div class="form-item-content">
                {{ detail.productionGross }}万元
              </div>
            </div>
            <div class="form-item line">
              <span>库存量：</span>
              <div class="form-item-content">{{ detail.stockCount }}kg</div>
            </div>
            <div class="form-item">
              <span>一产产值：</span>
              <div class="form-item-content">{{ detail.firstWorth }}万元</div>
            </div>
            <div class="form-item">
              <span>二产产值：</span>
              <div class="form-item-content">{{ detail.secondWorth }}万元</div>
            </div>
            <div class="form-item">
              <span>二产加工量：</span>
              <div class="form-item-content">{{ detail.machineCount }}公斤</div>
            </div>
            <div class="form-item">
              <span>三产产值：</span>
              <div class="form-item-content">{{ detail.thirdWorth }}万元</div>
            </div>
            <div class="form-item">
              <span>毛利率：</span>
              <div class="form-item-content">{{ detail.grossProfitRate }}%</div>
            </div>
            <div class="form-item">
              <span>增长率：</span>
              <div class="form-item-content">{{ detail.increaseRate }}%</div>
            </div>
          </div>

          <div class="region">
            <el-table :data="detail.firstList" border>
              <el-table-column prop="indicesName" label="一产投入对象">
              </el-table-column>
              <el-table-column prop="value" label="费用总额（万元）">
              </el-table-column>
            </el-table>
          </div>

          <div class="region">
            <el-table :data="detail.secondList" border>
              <el-table-column prop="indicesName" label="二产投入对象">
              </el-table-column>
              <el-table-column prop="value" label="费用总额（万元）">
              </el-table-column>
            </el-table>
          </div>

          <div class="region">
            <el-table :data="detail.thirdList" border>
              <el-table-column prop="indicesName" label="三产投入对象">
              </el-table-column>
              <el-table-column prop="value" label="费用总额（万元）">
              </el-table-column>
            </el-table>
          </div>
          <div class="btn">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Tabbar from "../../components/assembly/Tabbar";
import Title from "../../components/assembly/Title";

export default {
  components: {
    LeftTabberVue,
    Tabbar,
    Title
  },
  created() {
    this.getList(this.$route.query.id);
  },
  data() {
    return {
      detail: []
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/industry/subject/productionReport/getProductionReportById",
        method: "post",
        data: {
          reportId: id
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-right: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}
.form-item {
  display: flex;
  width: 50%;
  box-sizing: border-box;
  border-left: 1px solid #d2d2d2;
  border-top: 1px solid #d2d2d2;
}
.form-item.line {
  width: 100%;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
  line-height: 23px;
  color: #606266;
}
.region {
  margin: 20px 0;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
