import $axios from "../../utils/axios";
import crypto from "../../utils/crypto";

const prefix = "/p";
// 注册
export const register = data => {
  return $axios({
    url: `${prefix}/register`,
    method: "post",
    data
  });
};

// 登录
export const login = data => {
  return $axios({
    url: `${prefix}/login`,
    method: "post",
    data: {
      accountNumber: data.accountNumber,
      password: crypto.encrypt(data.password),
      validateCode: data.validateCode,
      rememberMe: data.rememberMe
    }
  });
};
