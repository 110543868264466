<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="8"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业产品信息"></Title>
          <el-form ref="form" :model="form" :rules="rules">
            <div class="form">
              <div class="form-item">
                <span><i class="color-red">*</i> 疑问：</span>
                <el-form-item class="form-item-content" prop="question">
                  <el-input v-model="form.question"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span><i class="color-red">*</i> 疑问类型：</span>
                <el-form-item class="form-item-content" prop="questionType">
                  <el-select
                    v-model="form.questionType"
                    placeholder=""
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in detail"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-item percent percent">
                <span><i class="color-red">*</i>疑问详情：</span>
                <el-form-item class="form-item-content" prop="questionDetail">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    v-model="form.questionDetail"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="primary" @click="handleSubmit">提 交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getstyle();
  },
  data() {
    return {
      detail: [],
      form: {
        question: "",
        questionType: "",
        questionDetail: ""
      },
      rules: {
        question: [{ required: true, message: "请填写疑问", trigger: "blur" }],
        questionType: [
          { required: true, message: "请选择疑问类型", trigger: "change" }
        ],
        questionDetail: [
          { required: true, message: "请填写疑问详情", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    getstyle() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          // 固定名称，哪里查看这个需要请求值
          dictType: "interaction_question_type"
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    },

    getForm() {
      this.$axios({
        url: "/p/web/interaction/save",
        method: "post",
        data: this.form
      }).then(res => {
        if (res.data.code == 0) {
          this.$alert("您已成功提交！", "成功", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push("/Answering");
            }
          }).catch(() => {
            this.$message({
              type: "error",
              message: "成功取消"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getForm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.all {
  background-color: #eee;
}
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
</style>
