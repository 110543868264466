<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['咨询投诉']"></Tabbar>
      <div class="wrapper">
        <el-form
          ref="form"
          label-width="auto"
          :model="submitData"
          :rules="rules"
        >
          <Title text="反馈信息"></Title>
          <el-form-item label="类型 :" prop="title">
            <el-radio-group v-model="submitData.type">
              <el-radio :label="0">咨询</el-radio>
              <el-radio :label="1">投诉</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="反馈标题 :" prop="title">
            <el-input v-model="submitData.title"></el-input>
          </el-form-item>
          <el-form-item label="反馈内容 :" prop="contents">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              v-model="submitData.contents"
            ></el-input>
          </el-form-item>
          <Title text="个人信息"></Title>
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="submitData.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="phone">
            <el-input v-model="submitData.phone"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码 :" prop="idCard">
            <el-input v-model="submitData.idCard"></el-input>
          </el-form-item>
        </el-form>
        <!-- 按钮 -->
        <div class="form-button">
          <el-button type="primary" @click="handleSubmit">提 交</el-button>
        </div>
      </div>
    </div>
    <Vcode :show="showCode" @success="submit" @close="closeCode" />
  </div>
</template>
<script>
import Tabbar from "../components/assembly/Tabbar";
import Title from "../components/assembly/Title";
import Vcode from "vue-puzzle-vcode";

export default {
  components: { Tabbar, Title, Vcode },
  data() {
    var validateIdcard = (rule, value, callback) => {
      let regs = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (value === "") {
        callback(new Error("请输入身份证号码"));
      } else if (!regs.test(value)) {
        callback(new Error("请输入正确的身份证号码!"));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!regs.test(value)) {
        callback(new Error("请输入正确的手机号码!"));
      } else {
        callback();
      }
    };
    return {
      // 验证码
      showCode: false,

      detail: [],
      value: "",
      submitData: {
        type: 0,
        name: "",
        phone: "",
        idCard: "",
        title: "",
        contents: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入名字", trigger: "blur" },
          { min: 2, message: "长度最少2个字符", trigger: "blur" }
        ],
        idCard: [
          { required: true, validator: validateIdcard, trigger: "blur" }
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        title: [{ required: true, message: "请输入主题", trigger: "blur" }],
        contents: [
          { required: true, message: "请输入投诉内容", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.showCode = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭验证码
    closeCode() {
      this.showCode = false;
    },
    submit() {
      this.closeCode();
      this.$axios({
        url: "/p/web/letter/saveLetter",
        method: "post",
        params: this.submitData
      }).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: "提交成功",
            type: "success"
          });
          this.$router.push("/Complaint");
        } else {
          this.$utils.alertMessage({
            type: "error",
            message: res.data.msg
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.w {
  height: 100%;
  background-color: #fff;
  padding: 0 20px 20px;
  box-sizing: border-box;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
</style>
