<template>
  <div class="container">
    <div class="header">
      <div class="w header-wrap">
        <div class="header-info">
          欢迎访问揭阳市农业产业链公共服务平台！今天是：{{ getTime() }}
        </div>
        <div class="header-menu">
          <template v-if="userInfo">
            <el-dropdown trigger="click" @command="handleCommand">
              <a href="javascript:;" class="el-dropdown-link">
                {{ userInfo.nickName
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="userCenter"
                  >个人中心</el-dropdown-item
                >
                <!-- <el-dropdown-item command="view">详情</el-dropdown-item> -->
                <el-dropdown-item command="reset">重置密码</el-dropdown-item>
                <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <router-link to="/Login">登录</router-link>
            <router-link to="/Register">注册</router-link>
            <!-- <router-link to="/Basic">详情</router-link> -->
          </template>
          <router-link to="/Complaint">投诉</router-link>
        </div>
      </div>
    </div>
    <el-dialog title="重置密码" :visible.sync="dialogFormVisible" width="500px">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="85px"
      >
        <el-form-item label="旧密码:" prop="oldPwd">
          <el-input type="password" v-model="ruleForm.oldPwd"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPwd">
          <el-input type="password" v-model="ruleForm.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>
    <!-- 标题 -->

    <div class="title w">
      <div class="left">
        <img src="../assets/code/logo.png" alt="" />
      </div>
      <div class="right">
        <i
          class="el-icon-s-operation"
          @click="showMobileMenu = !showMobileMenu"
        ></i>
      </div>
    </div>
    <!-- 导航index -->
    <div class="index">
      <ul class="index-title w">
        <template v-for="(item, index) in detail">
          <li v-if="item.showHead === 'Y'" :key="index">
            <a href="javascript:;" @click="clickNav(item.catalogType, item)">{{
              item.name
            }}</a>
          </li>
        </template>
      </ul>
    </div>

    <!-- app导航 -->
    <div class="mobile-index" :class="{ on: showMobileMenu }">
      <div class="mobile-index-mask" @click="showMobileMenu = false"></div>
      <ul class="mobile-index-list">
        <li class="mobile-index-title">导航</li>
        <template v-for="(item, index) in detail">
          <li
            :class="{ on: $route.fullPath === item.activeRouter }"
            v-if="item.showHead === 'Y'"
            :key="index"
            @click="clickNav(item.catalogType, item)"
          >
            {{ item.name }}
            <i class="el-icon-arrow-right"></i>
          </li>
        </template>
      </ul>
    </div>

    <main>
      <router-view></router-view>
    </main>

    <!-- footer,这个顶部 -->
    <div class="footer">
      <div class="provide w">
        <p>主办：揭阳市农业农村局</p>
        <p>由中国联通提供技术支持</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    // 自定义规则
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      // 是否展示手机端菜单
      showMobileMenu: false,
      showmask: false,
      type: [{}, {}, {}],
      detail: [],
      news: [],
      News: {
        url: "",
      },
      places: [
        {
          name: "首页",
          code: "/",
          showHead: "Y",
          catalogType: "index",
        },
      ],
      ruleForm: {
        oldPwd: "",
        newPwd: "",
        checkPass: "",
      },
      rules: {
        oldPwd: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        newPwd: [
          {
            required: true,
            validator: validatePass,
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            required: true,
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
      dialogFormVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios({
            url: "/p/resetPwd",
            method: "post",
            data: this.ruleForm,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$alert("修改密码成功，请重新登录", "提示", {
                type: "success",
                confirmButtonText: "确定",
              });
              this.$store.commit("removeUserInfo");
              this.$router.push("/");
            } else {
              this.$alert(res.data.msg, "错误提示", {
                type: "error",
                confirmButtonText: "确定",
              });
            }
          });
        }
      });
    },

    getList() {
      this.$axios({
        url: "/p/web/catalog/getWebCatalogList",
        method: "post",
      }).then((res) => {
        const info = res.data.data;
        info.forEach((item) => {
          if (item.catalogType == "more") {
            item.activeRouter = `/News?id=${item.id}`;
          } else if (item.catalogType == "single") {
            item.activeRouter = `/Explicit?catalogId=${item.id}&type=system`;
          } else {
            item.activeRouter = item.url;
          }
        });
        this.detail = [
          {
            name: "首页",
            code: "/",
            showHead: "Y",
            catalogType: "inLink",
            activeRouter: "/",
          },
          ...info,
        ];
        this.type = res.data.data.catalogType;
      });
      // res
      //   this.list = res.data.rows;
    },
    // single跳/News，并且把code作为参数带过去
    // more跳/Policy，并且把code作为参数带过去
    // outlink 打开 code 新连接
    getTime() {
      const date = new Date();
      return `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日`;
    },
    clickNav(type, info) {
      console.log(type);
      // 隐藏手机菜单
      this.showMobileMenu = false;
      switch (type) {
        case "inLink":
          this.$router.push(info.code);
          break;
        case "outlink":
          if (info.url.includes("http")) {
            window.open(info.url);
          } else {
            this.$router.push(info.url);
          }
          break;
        case "more":
          this.$router.push("/News?id=" + info.id);
          break;
        case "single":
          this.$router.push("/Explicit?catalogId=" + info.id + "&type=system");
          break;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "userCenter":
          if (this.userInfo.industrySubject.authStatus != "certified") {
            this.$router.push("/UserCenter/Auth");
          } else {
            this.$router.push("/Basic");
          }
          break;
        case "view":
          this.$router.push("/Base");
          break;
        case "reset":
          this.dialogFormVisible = true;
          break;
        case "loginOut":
          this.$confirm("确认退出登录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.dialogFormVisible = false;
            this.$store.commit("removeUserInfo");
            this.$router.push("/");
          });
          break;
      }
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container main {
  flex: 1;
}

li {
  list-style: none;
}

.header {
  background: #f5f5f5;
  padding: 8px 0;
  margin-bottom: 20px;
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.header-menu {
  text-align: right;
}

.header-menu a {
  margin-left: 10px;
  text-decoration: none;
  color: #333;
}

.title {
  /* background-color: green; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.title .left {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title .right {
  display: none;
}

.search {
  position: absolute;
  top: 43%;
  left: 92%;
  transform: translate(-50%, -50%);
  transform: scale(0.8);
}

.index {
  background-color: #0453a0;
  margin-top: 20px;
}

.index-title {
  display: flex;
  /* background-color: #fff; */
  flex-flow: row wrap;
}

.index-title > li {
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
}

.index-title > li > a {
  display: block;
  padding: 15px 0;
  width: 120px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.footer {
  background-color: #0453a0;
  border-top: 3px solid #228fe2;
  background-color: #0453a0;
}

.provide {
  color: #fff;
  padding: 20px;
  border-top: 3px solid #e9a831;
  margin-top: -3px;
  box-sizing: border-box;
  text-align: center;
}

.provide p {
  margin: 5px 0;
}

.provide p:nth-child(1) {
  font-size: 18px;
}

.provide p:nth-child(2) {
  font-size: 14px;
}

.mobile-index {
  display: none;
}

@media (max-width: 768px) {
  .header,
  .index {
    display: none;
  }

  .title {
    box-sizing: border-box;
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .title img {
    height: 30px;
  }

  .title .right {
    display: block;
    text-align: right;
  }

  .title .right i {
    font-size: 24px;
    color: #0453a0;
  }

  .provide {
    padding: 15px;
    font-size: 14px;
  }

  .mobile-index {
    display: block;
  }

  /* 导航栏 */
  .mobile-index.on .mobile-index-mask {
    visibility: initial;
    opacity: 1;
  }

  .mobile-index.on .mobile-index-list {
    left: 0;
  }

  .mobile-index .mobile-index-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    z-index: 1000;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .mobile-index .mobile-index-list {
    position: fixed;
    left: -100%;
    height: 100%;
    width: 60%;
    background: #fff;
    z-index: 1001;
    padding: 20px;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  .mobile-index .mobile-index-list li {
    padding: 12px 15px;
    background: #f5f5f5;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-index .mobile-index-list li.mobile-index-title {
    font-size: 16px;
    padding: 0;
    background: transparent;
    margin-bottom: 15px;
  }

  .mobile-index .mobile-index-list li.on {
    background: #0453a0;
    color: #fff;
  }
}
</style>