<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="6"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="产品产值管理"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="6"
                ><div class="form-group">
                  <span>年份：</span>
                  <el-date-picker
                    v-model="detail.year"
                    type="year"
                    size="mini"
                    value-format="yyyy"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="6"
                ><div class="form-group">
                  <span>月份：</span>
                  <el-date-picker
                    v-model="detail.month"
                    type="month"
                    value-format="MM"
                    format="MM月"
                    size="mini"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="8"
                ><div class="form-group">
                  <span class="date">日期：</span>
                  <el-date-picker
                    v-model="rangeTime"
                    size="mini"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >查询</el-button
                  ><el-button size="mini" type="primary" @click="newAdd"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="reportData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="year"
                label="年份"
                width="55"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="productionGross"
                label="产值总额"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.productionGross || 0 }}万元
                </template>
              </el-table-column>

              <el-table-column
                prop="productionTotal"
                label="产值总量"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.productionTotal || 0 }}公斤
                </template> </el-table-column
              ><el-table-column prop="createTime" label="创建时间" width="160">
              </el-table-column>
              <el-table-column
                prop="status"
                label="状态"
                width="70"
                align="center"
              >
                <template slot-scope="scope">
                  {{ forGetStatus(scope.row.status) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.subjectReportId)"
                    v-if="scope.row.status === 'submitted'"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleEdit(scope.row.subjectReportId)"
                    v-if="scope.row.status === 'draft'"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(scope.row.subjectReportId)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="Total"
                @current-change="handlepages"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getDiction();
    this.getList();
  },
  data() {
    return {
      Total: 0,
      pageNum: 1,
      diction: [],
      reportData: [],
      projectData: {
        name: "",
        statue: ""
      },
      rangeTime: [],
      detail: {
        month: "",
        startTime: "",
        endTime: "",
        year: "",
        pageNum: 1,
        firstList: [],
        secondList: [],
        pageSize: 5
      }
    };
  },
  methods: {
    // 字典
    getDiction() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "industry_subject_report_status"
        }
      }).then(res => {
        this.diction = res.data.data;
      });
    },
    forGetStatus(value) {
      for (var i = 0; i < this.diction.length; i++) {
        if (this.diction[i].dictValue == value) {
          return this.diction[i].dictLabel;
        }
      }
    },
    newAdd() {
      this.$router.push("/Report/ReportAdd");
    },
    handleEdit(id) {
      this.$router.push("/Report/ReportEdit?id=" + id);
    },
    handleView(id) {
      this.$router.push("/Report/ReportList?id=" + id);
    },
    getList() {
      this.$axios({
        url: "/p/industry/subject/productionReport/getProductionReportList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.reportData = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    handlepages(page) {
      this.detail.pageNum = page;
      this.getList();
    },
    search() {
      if (this.rangeTime.length > 0) {
        this.detail.startTime = this.rangeTime[0];
        this.detail.endTime = this.rangeTime[1];
      }
      this.detail.pageNum = 1;
      this.getList();
    },
    handleDelete(subjectReportId) {
      this.$confirm("此操作将永久删除该产品产值, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url:
            "/p/industry/subject/productionReport/removeProductionReportById",
          method: "post",
          data: { ids: subjectReportId }
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
