<template>
    <div class="view">
        <div class="industry">
            <div class="tab-industry industryImg">
                <!-- 轮播图 -->
                <swiper :options="swiperOptions" class="swiper-container new-container">
                    <swiper-slide v-for="item in chainList" :key="item.id">
                        <div class="swiper-div" @click="clickChain(item.chainId)">
                            <img class="swiper-img" :src="item.homeImages" />
                            <div class="swiper-txt">
                                <div class="w">{{ item.chainName }}</div>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination swiper-news-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div></div>
        </div>
        <div class="tab">
            <div class="w">
                <!-- 新闻动态 -->
                <div class="newsPolice">
                    <div class="newsPoliceLeft">
                        <Tabbar :title="tabOne" @click="click" @hover="handleHover" trigger="hover" :posi="left">
                        </Tabbar>
                        <div class="introduction" v-for="(item, index) in first" :key="index"
                            @click="handletoexplicit(item)">
                            <div class="ellipsis" :title="item.title">{{ item.title }}</div>
                            <div class="time">{{ item.date }}</div>
                        </div>
                    </div>
                    <!-- 产业链动态 -->
                    <div class="newsPoliceRight">
                        <Tabbar :title="tabTwo" @click="click" @hover="handleHover" trigger="hover" :posi="left">
                        </Tabbar>
                        <div class="introduction PoliceBorder" v-for="(item, index) in thirdData" :key="index"
                            @click="handlePush(item)">
                            <div class="ellipsis">
                                {{ item["title"] }}
                            </div>
                            <div class="time">
                                {{ item.publishDate ? item.publishDate.substr(0, 10) : "" }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 专题专栏 -->
                <div class="special">
                    <swiper :options="specialSwiper" class="swiper-container new-container">
                        <swiper-slide v-for="(item, index) in sixData" :key="index">
                            <div @click="Go(item)" class="spe">
                                <img :src="item.imgUrl" alt="" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
        <!-- app新闻动态 -->
        <div class="mobile-box">
            <div class="mobile-title">
                <span>新闻动态</span>
                <a href="javascript:;" class="more" @click="click(2202)">更多<i class="el-icon-arrow-right"></i></a>
            </div>
            <div class="mobile-new">
                <div class="mobile-new-item" v-for="item in second" :key="item.id" @click="handletoexplicit(item)">
                    <img :src="item.cover" />
                    <div class="mobile-new-info">
                        <div class="mobile-new-title">{{ item.title }}</div>
                        <div class="mobile-meta">{{ item.date }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- app专题专栏 -->
        <div class="mobile-special mobile-box">
            <div class="mobile-title">
                <span>专题专栏</span>
                <a href="javascript:;" class="more" @click="click(2203)">更多<i class="el-icon-arrow-right"></i></a>
            </div>
            <div class="mobile-special-list">
                <img :src="item.imgUrl" v-for="(item, index) in sixData" :key="index" @click="Go(item)" />
            </div>
        </div>
        <!-- app产业链动态 -->
        <div class="mobile-box">
            <div class="mobile-title">
                <span>产业链动态</span>
                <a href="javascript:;" class="more" @click="click">更多<i class="el-icon-arrow-right"></i></a>
            </div>
            <div class="mobile-new">
                <a href="javascript:;" class="mobile-new-item" v-for="(item, index) in thirdData" :key="index"
                    @click="handlePush(item)">
                    <div class="mobile-new-title">
                        {{ item.title }}
                    </div>
                </a>
            </div>
        </div>
        <!-- places -->
        <div class="places w">
            <a class="Jump" :href="item.url" target="_blank" v-for="(item, index) in detail"
                :key="index">{{ item.name }}</a>
        </div>
    </div>
</template>
<script>
    import Tabbar from "./assembly/Tabbar";
    // import { industry } from "../config";
    export default {
        components: {
            Tabbar
        },
        created() {
            this.getList();
            this.getNews();
            this.getImg();
            this.getThird();
            this.getChainList();
        },
        data() {
            return {
                // industry,
                index: 0,
                fontSize: 30,
                idnum: 2202,
                left: "",
                detail: [],
                news: [],
                first: [],
                second: [],
                sixData: [],
                ImgData: [],
                thirdData: [],
                tabCurrent: 0,
                chainList: [],
                tab: [{
                    label: "专题专栏",
                    id: 2203
                }],
                tabOne: [{
                    label: "新闻动态",
                    id: 2202
                }],
                tabTwo: [{
                    label: "产业链动态",
                    id: 2204
                }],
                swiperOptions: {
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    autoplay: true
                },
                swiperList: [],
                list: [],

                // 手机端重点产品swiper
                industrySwiperOption: {
                    slidesPerView: 1.2,
                    spaceBetween: 15
                },

                specialSwiper: {
                    slidesPerView: 2,
                    autoplay: true

                }
            };
        },
        methods: {
            getChainList() {
                this.$axios({
                    url: "/visualize/cityView/chainsSumData",
                    method: "get"
                }).then(res => {
                    console.log(res);
                    this.chainList = res.data;
                    console.log(this.chainList);
                });
            },
            getNews() {
                this.$axios({
                    url: "/p/thirdApi/manuscript/getManuscriptList",
                    params: {
                        id: this.idnum
                    }
                }).then(res => {
                    this.news = res.data.data.articles;
                    this.first = this.news.splice(0, 1);
                    this.second = this.news.splice(0, 5);
                });
            },
            getList() {
                this.$axios({
                    url: "/p/web/link/getLinkByType",
                    method: "post"
                }).then(res => {
                    this.detail = res.data.data;
                });
            },
            handleClick(index, id) {
                this.tabCurrent = index;
                id;
                // console.log(id);
            },
            click(id) {
                this.idnum = id;
                if (id == 2202) {
                    this.$router.push("/Dynamic?id=" + id);
                } else if (id == 2203) {
                    this.$router.push("/Specialtext");
                } else {
                    this.$router.push("/News?id=3037757352510464");
                }
            },
            handleHover(index) {
                this.index = index;
            },
            // handle(text) {
            //   console.log(text);
            // },
            handletoexplicit(item) {
                if (item.type === "url") {
                    window.open(item.url);
                } else {
                    this.$router.push("/Explicit?id=" + item.id);
                }
            },

            getImg() {
                this.$axios({
                    url: "/p/web/catalog/getWebCatalogList",
                    method: "post",
                    data: {
                        catalogSpecies: "special"
                    }
                }).then(res => {
                    this.ImgData = res.data.data;
                    this.sixData = this.ImgData.slice(0, 6);
                });
            },
            getThird() {
                this.$axios({
                    url: "/p/web/content/getWebContentTopList",
                    method: "post",
                    data: {
                        id: "3037757352510464",
                        number: 1
                    }
                }).then(res => {
                    this.thirdData = res.data.data;
                });
            },
            handlePush(row) {
                if (row.contentType == "link") {
                    window.open(row.url);
                } else {
                    this.$router.push("/Explicit?id=" + row.id + "&type=system");
                }
            },
            Go(row) {
                if (row.catalogType === "outlink") {
                    window.open(row.code);
                } else {
                    this.$router.push("/Specialtext?id=" + row.id);
                }
            },

            // 点击产业链
            clickChain(id) {
                // TODO: 记得改id
                this.$router.push("/ChainView/?id=" + id);
            }
        }
    };
</script>
<style scoped>
    /* 轮播图 */
    .industryImg {
        height: 700px;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets {
        bottom: 17px;
    }

    .swiper-pagination-bullet {
        margin: 0 6px;
    }

    /* 专题专栏 */
    .w .special {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    .special .spe {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 10px 0 0;
    }

    .special .spe:nth-child(2n) {
        padding-right: 0;
    }

    .special:hover {
        cursor: pointer;
    }

    .special img {
        width: 100%;
        height: 100%;
    }

    /* 新闻 */
    .newsPolice {
        display: flex;
        justify-content: space-between;
    }

    .newsPoliceLeft,
    .newsPoliceRight {
        width: 50%;
    }

    .newsPoliceRight {
        /* padding-left: 10px; */
        margin-left: 30px;
    }

    .newsContent {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px dashed #c3cccf;
    }

    .newsContent:hover {
        cursor: pointer;
    }

    .newsContent:hover .Alltitle {
        color: #0857a1;
    }

    .newsContent:hover .textRightTitle {
        color: #0857a1;
    }

    .textImg {
        display: flex;
    }

    .textImgLfet {
        width: 160px;
        height: 100px;
        margin-right: 20px;
    }

    .textImgLfet img {
        height: 100%;
        object-fit: cover;
    }

    .textRight {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .textRightTitle {
        font-size: 16px;
        color: #535655;
    }

    .textRightDate {
        color: #979a9c;
        font-size: 12px;
    }

    .cover {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        opacity: 0.6;
    }

    /* .img {
  height: 330px;
  background: url(../assets/code/banner.png);
}
.img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} */
    .tab-industry {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
    }

    .tab {
        /* background-color: #dee7ec; */
    }

    .tab-con {
        width: 1050px;
        padding: 30px 0;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
    }

    .item {
        width: 1050px;
        display: none;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .item.on {
        display: flex;
    }

    .tab-con .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .boxWidth {
        width: 3150px;
        display: flex;
        position: relative;
    }

    /* 隔行 */
    .introduction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        /* background-color: purple; */
        border-bottom: 1px dashed #c3cccf;
    }

    .introduction :hover {
        cursor: pointer;
        color: #0857a1;
    }

    .introduction:last-child {
        border-bottom: 0;
    }

    .new-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        /* margin-right: 30px; */
    }

    /* .new-container {
  overflow: hidden;
  width: 100%;
  height: 320px;
  margin-right: 30px;
} */
    .swiper-slide img {
        height: 100%;
    }

    .swiper-div {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .swiper-txt {
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0px;
        background: rgba(0, 0, 0, 0.5);
        padding: 12px 0;
        color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 30px;
    }

    .ellipsis {
        width: 80%;
        letter-spacing: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 60px;
        color: #535655;
        font-size: 18px;
        padding: 10px 10px 10px 15px;
        position: relative;
    }

    .ellipsis::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #000;
    }

    .time {
        white-space: nowrap;
        color: #979a9c;
        font-size: 14px;
    }

    .last {
        border-bottom: none;
    }

    .places {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        margin: 20px auto;
    }

    .Jump {
        display: flex;
        justify-content: center;
        background-color: #228fe2;
        color: #fff;
        padding: 10px 20px;
        text-decoration: none;
    }

    .places>div:last-child {
        margin-right: 0;
    }

    .textAll {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .textAll .Alltitle {
        font-size: 16px;
        color: #535655;
    }

    .textAll .Allcontent {
        color: #979a9c;
        font-size: 12px;
    }

    /* 手机端 */
    .mobile-industry,
    .moblie-title,
    .mobile-new,
    .mobile-box {
        display: none;
    }

    @media (max-width: 768px) {
        .view {
            padding-top: 60px;
        }

        .tab,
        .places {
            display: none;
        }

        .mobile-title {
            font-size: 22px;
            color: #333;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mobile-title .more {
            text-decoration: none;
            font-size: 14px;
            color: #228fe2;
        }

        .mobile-box {
            padding: 20px 20px 30px;
            display: block;
        }

        .mobile-meta {
            font-size: 14px;
            color: #999;
        }

        .img {
            height: 200px;
        }

        .mobile-special,
        .mobile-industry {
            background: #dee7ec;
        }

        .industry-swiper-item {
            background: #fff;
            border-radius: 3px;
            overflow: hidden;
        }

        .industry-swiper-item img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            vertical-align: bottom;
        }

        .industry-swiper-info {
            padding: 20px;
            position: relative;
        }

        .industry-swiper-info p {
            margin: 0;
        }

        .industry-swiper-info p:nth-child(1) {
            font-size: 18px;
        }

        .industry-swiper-info p:nth-child(2) {
            margin-top: 5px;
        }

        .industry-swiper-info a {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            text-decoration: none;
            color: #228fe2;
        }

        .mobile-new {
            display: block;
        }

        .mobile-new .mobile-new-item {
            text-decoration: none;
            color: #333;
            display: flex;
            padding-bottom: 15px;
            margin-top: 15px;
            border-bottom: 1px solid #eee;
        }

        .mobile-new .mobile-new-item:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .mobile-new .mobile-new-item img {
            width: 100px;
            height: 80px;
            object-fit: cover;
            border-radius: 5px;
        }

        .mobile-new .mobile-new-info {
            flex: 1;
            margin-left: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .mobile-new .mobile-new-title {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .mobile-special-list img {
            width: 100%;
            height: 70px;
            object-fit: cover;
            margin-bottom: 10px;
        }

        .mobile-special-list img:last-child {
            margin-bottom: 0;
        }

        /* 轮播图 */
        .industryImg {
            height: 250px;
        }

        .new-container {
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        .swiper-slide img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .swiper-div {
            width: 100%;
            height: 100%;
        }

        .swiper-txt {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0px;
            background: rgba(0, 0, 0, 0.5);
            padding: 9px 100px 9px 12px;
            color: #fff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
        }
    }
</style>