<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="7"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业产品信息"></Title>
          <!-- 表框 -->
          <div class="form">
            <div class="form-item">
              <span>产品名称：</span>
              <div class="form-item-content">
                {{ ProductList.finishedProductName }}
              </div>
            </div>
            <div class="form-item">
              <span>产品类别：</span>
              <div class="form-item-content">
                {{ getDictLabel(getType, ProductList.categoryId) }}
              </div>
            </div>
            <div class="form-item">
              <span>品牌名称：</span>
              <div class="form-item-content">
                {{ ProductList.brand }}
              </div>
            </div>
            <div class="form-item">
              <span>出产地：</span>
              <div class="form-item-content">
                {{ ProductList.productPlace }}
              </div>
            </div>
            <div class="form-item percent">
              <span>产品描述：</span>
              <div class="form-item-content">
                {{ ProductList.describes }}
              </div>
            </div>
            <!-- 照片 -->
            <div class="form-item percent">
              <span>产品图片：</span>
              <div class="form-item-content">
                <div class="baseimg-i" v-if="ProductList.images">
                  <el-image
                    :src="ProductList.images"
                    class="baseimg-img"
                    :preview-src-list="[ProductList.images]"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList(this.$route.query.id);
    this.getDictData("product_category", "getType");
  },
  data() {
    return {
      ProductList: [],
      BaseData: [],
      getType: []
    };
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/industry/product/getProductById",
        method: "post",
        data: {
          productId: id
        }
      }).then(res => {
        this.ProductList = res.data.data;
      });
    },
    // 字典封装
    getDictData(dictType, targetData) {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: dictType
        }
      }).then(res => {
        this[targetData] = res.data.data;
      });
    },
    // 循环
    getDictLabel(dictArray, value) {
      for (var i = 0; i < dictArray.length; i++) {
        if (dictArray[i].dictValue === value) {
          return dictArray[i].dictLabel;
        }
      }
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  line-height: 23px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.position {
  margin-top: 40px;
}
.baseimg {
  padding: 5px;
}
.baseimg-i {
  display: flex;
  flex-wrap: wrap;
}
.baseimg-img {
  padding: 5px;
  width: 73px;
  height: 73px;
}
.relevant {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relevant-text {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.relevant i {
  color: blue;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
