<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="10"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="编辑企业需求"></Title>
          <el-form ref="form" :model="detail" :rules="rules">
            <div class="form">
              <div class="form-item percent">
                <span><i class="color-red">*</i> 需求标题：</span>
                <el-form-item class="form-item-content" prop="title">
                  <el-input v-model="detail.title"></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span><i class="color-red">*</i>需求详情：</span>
                <el-form-item class="form-item-content" prop="content">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    v-model="detail.content"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item percent percent">
                <span><i class="color-red">*</i>相关文件：</span>
                <el-form-item class="form-item-content" prop="attachment">
                  <template>
                    <el-upload
                      action="/chainer/p/utils/common/upload"
                      multiple
                      :on-success="uploadFile"
                      :on-remove="removeFile"
                      :file-list="fileList"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </template>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="info" @click="handleHold">暂 存</el-button>
            <el-button type="primary" @click="handleSubmit">提 交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: { LeftTabberVue, Title, Tabbar },
  created() {
    this.getList(this.$route.query.id);
  },
  data() {
    return {
      detail: {
        title: "",
        status: "企业需求",
        content: "",
        attachment: ""
      },
      fileList: [],
      StatusLabel: {},
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        status: [{ required: true, message: "请选择类型", trigger: "blur" }],
        content: [{ required: true, message: "请输入详情", trigger: "blur" }],
        attachment: [
          { required: true, message: "请上传相关文件", trigger: "change" }
        ]
      }
    };
  },

  methods: {
    getList(id) {
      this.$axios({
        url: "/p/demand/getDemand",
        method: "post",
        data: {
          demandId: id
        }
      }).then(res => {
        const info = res.data.data;
        if (info.attachmentFileList) {
          info.attachmentFileList.forEach(item => {
            this.fileList.push({
              id: item.fileId,
              name: item.fileName,
              url: item.fileUrl
            });
          });
        }
        this.detail = info;
      });
    },
    uploadFile(response, file, fileList) {
      this.FileData(fileList);
    },
    removeFile(file, fileList) {
      this.FileData(fileList);
    },
    FileData(fileList) {
      const ids = [];
      fileList.forEach(item => {
        if (item.response) {
          ids.push(item.response.data.fileId);
        } else {
          ids.push(item.id);
        }
      });

      this.detail.attachment = ids.join(",");
    },
    handleBack() {
      this.$router.back();
    },
    handleHold() {
      this.$confirm("是否将文件保存为暂存？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/p/demand/edit",
          method: "post",
          data: this.detail,
          json: true
        }).then(() => {
          this.$router.push("/Demand");
        });
      });
    },
    getValidate() {
      this.detail.status = "submitted";
      this.$axios({
        url: "/p/demand/edit",
        method: "post",
        data: this.detail,
        json: true
      }).then(res => {
        if (res.data.code == 0) {
          this.$alert("您已成功提交！", "成功", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push("/Demand");
            }
          }).then(() => {
            this.$message({
              type: "success",
              message: "提交成功!"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getValidate();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
.all {
  background-color: #eee;
}
.w {
  background-color: #fff;
  height: 100%;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
</style>
