import $axios from "../../utils/axios";
const prefix = "/p/web";
// 获取栏目信息
export const getWebCatalog = catalogId => {
  return $axios({
    url: `${prefix}/catalog/getWebCatalog`,
    method: "post",
    params: {
      catalogId
    }
  });
};

// 获取子栏目
export const getCatalogSpeciesList = id => {
  return $axios({
    url: `${prefix}/catalog/getcatalogSpeciesList`,
    method: "post",
    params: {
      id
    }
  });
};

// 获取栏目列表
export const getWebContentList = params => {
  return $axios({
    url: `${prefix}/content/getWebContentList`,
    method: "post",
    params
  });
};
