<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="2"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="站内信件管理"></Title>
          <div>
            <el-table :data="MailData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="mailTitle" label="标题"> </el-table-column>
              <el-table-column prop="fromDeptName" label="发送部门" width="150">
              </el-table-column>
              <el-table-column prop="fromUserName" label="发送人" width="100">
              </el-table-column>
              <el-table-column prop="sendDate" label="发送日期" width="100">
              </el-table-column>
              <el-table-column prop="isRead" label="是否已读" width="80">
                <template slot-scope="scope">
                  {{ getStatusList(scope.row.isRead) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="170">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.sendId)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="Total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Tabbar,
    Title
  },
  data() {
    return {
      Total: 0,
      MailData: [],
      statusData: "",
      detail: {
        fromDeptName: "",
        isRead: "",
        sendDate: "",
        pageNum: 1,
        pageSize: 5
      }
    };
  },
  created() {
    this.getData();
    this.getStatus();
  },
  methods: {
    getData() {
      this.$axios({
        url: "/p/station/mail/getMailList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.Total = res.data.data.total;
        this.MailData = res.data.data.rows;
      });
    },
    getStatus() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "sys_yes_no"
        }
      }).then(res => {
        this.statusData = res.data.data;
      });
    },
    getStatusList(value) {
      for (var i = 0; i < this.statusData.length; i++) {
        if (this.statusData[i].dictValue == value) {
          return this.statusData[i].dictLabel;
        }
      }
    },
    handleView(id) {
      this.$router.push("/Mail/MailList?id=" + id);
    },
    handleCurrentChange(page) {
      this.detail.pageNum = page;
      this.getData();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
</style>
