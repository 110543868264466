<template>
  <div class="chain">
    <div class="chain-bg"></div>
    <div class="chain-view">
      <div class="w">
        <div class="chain-view-head">
          <!-- 只有一张 -->
          <img :src="chainWorthData.images" alt="" />
          <div class="chain-view-title">{{ chainWorthData.chainName }}</div>
          <div class="chain-view-brief">
            主要品种：{{ chainWorthData.varieties }}
          </div>
        </div>
        <div class="chain-info">
          <div class="chain-info-item">
            <img src="../assets/icon/icon1.png" alt="" />
            <div class="chain-info-label">链长</div>
            <div class="chain-info-value">{{ chainDetailData.chainer }}</div>
          </div>
          <div class="chain-info-item">
            <img src="../assets/icon/icon2.png" alt="" />
            <div class="chain-info-label">总产值</div>
            <div class="chain-info-value">
              {{ chainDetailData.totalWorth }}亿元
            </div>
          </div>
          <div class="chain-info-item">
            <img src="../assets/icon/icon3.png" alt="" />
            <div class="chain-info-label">面积</div>
            <div class="chain-info-value">
              {{ chainDetailData.landSize }}万亩
            </div>
          </div>
          <div class="chain-info-item">
            <img src="../assets/icon/icon4.png" alt="" />
            <div class="chain-info-label">重点项目</div>
            <div class="chain-info-value">
              {{ chainDetailData.projectCount }}个
            </div>
          </div>
          <div class="chain-info-item">
            <img src="../assets/icon/icon5.png" alt="" />
            <div class="chain-info-label">龙头企业</div>
            <div class="chain-info-value">
              {{ chainDetailData.subjectTotal }}家
            </div>
          </div>
        </div>
        <!-- app 产业链介绍 -->
        <div class="mobile-info">
          <div class="mobile-info-top">
            <div class="mobile-info-top-item">
              <div class="mobile-info-top-label">链长</div>
              <div class="mobile-info-top-value">
                {{ chainDetailData.chainer }}
              </div>
            </div>
            <div class="mobile-info-top-item">
              <div class="mobile-info-top-label">总产值</div>
              <div class="mobile-info-top-value">
                {{ chainDetailData.totalWorth }}亿元
              </div>
            </div>
            <div class="mobile-info-top-item">
              <div class="mobile-info-top-label">面积</div>
              <div class="mobile-info-top-value">
                {{ chainDetailData.landSize }}万亩
              </div>
            </div>
          </div>
          <div class="mobile-info-bottom">
            <div class="mobile-info-bottom-item">
              <img src="../assets/icon/icon4.png" alt="" />
              <div>
                <div class="mobile-info-bottom-label">重点项目</div>
                <div class="mobile-info-bottom-value">
                  {{ chainDetailData.projectCount }}个
                </div>
              </div>
            </div>
            <div class="mobile-info-bottom-item">
              <img src="../assets/icon/icon5.png" alt="" />
              <div>
                <div class="mobile-info-bottom-label">龙头企业</div>
                <div class="mobile-info-bottom-value">
                  {{ chainDetailData.subjectTotal }}家
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chain-wrapper">
          <div class="chain-banner">
            <img src="../assets/code/chain-banner.png" alt="" />
          </div>
        </div>
        <div class="chain-wrapper">
          <div class="chain-layout">
            <ChainDiv title="基本信息">
              <div class="chain-media">
                <div class="chain-media-video">
                  <video
                    autoplay
                    controls="controls"
                    :src="'chainer/' + chainvideoData.fileUrl"
                  ></video>
                </div>
                <div class="chain-media-info">
                  {{ chainWorthData.overview }}
                </div>
              </div>
            </ChainDiv>
            <ChainDiv title="产值信息">
              <div id="echarts"></div>
            </ChainDiv>
          </div>
        </div>
        <div class="chain-wrapper">
          <ChainDiv title="产品信息">
            <div
              class="chain-product"
              :class="{ on: mobileMore }"
              v-if="chainProductData.length !== 0"
            >
              <div
                class="chain-product-item"
                v-for="(item, index) in chainProductData"
                :key="index"
                @click="handleLink(item.buyLink)"
              >
                <img :src="item.images" alt="" />
                <p>{{ item.finishedProductName }}</p>
              </div>
            </div>
            <div v-else>
              <el-empty description="暂无产品信息"></el-empty>
            </div>
            <div
              class="chain-product-more"
              v-if="chainProductData.length > 4"
              @click="mobileMore = !mobileMore"
            >
              {{ mobileMore ? "收起" : "展开" }}
            </div>
          </ChainDiv>
        </div>
        <div class="chain-wrapper">
          <iframe
            style="border:solid 1px #000"
            allowtransparency="true"
            frameborder="0"
            width="100%"
            height="100"
            scrolling="no"
            :src="weatherSrc"
            class="pc-weather"
          ></iframe>
          <iframe
            style="border:solid 1px #000"
            allowtransparency="true"
            frameborder="0"
            width="100%"
            height="100"
            scrolling="no"
            :src="mobileWeatherSrc"
            class="mobile-weather"
          ></iframe>
        </div>
        <div class="chain-wrapper">
          <div class="chain-table-wrap">
            <ChainDiv title="政策法规">
              <table class="chain-table">
                <thead>
                  <tr>
                    <th>政策名称</th>
                    <th width="100">发布日期</th>
                  </tr>
                </thead>
                <tbody v-if="thirdData.length !== 0">
                  <tr
                    v-for="(item, index) in thirdData"
                    :key="index"
                    @click="handlePush(item.id)"
                    class="policy"
                  >
                    <td class="title">
                      <span>
                        {{ item.title }}
                      </span>
                    </td>
                    <td>{{ item.updateTime.slice(0, 10) }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="2">
                      <el-empty description="暂无主体名称"></el-empty>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ChainDiv>
            <ChainDiv title="龙头企业">
              <table class="chain-table">
                <tr>
                  <th>主体名称</th>
                  <th width="100">成立日期</th>
                </tr>
                <tbody v-if="chainSubjectData.length !== 0">
                  <tr v-for="(item, index) in chainSubjectData" :key="index">
                    <td class="title">{{ item.subjectName }}</td>
                    <td>{{ item.createTime.slice(0, 10) }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="2">
                      <el-empty description="暂无主体名称"></el-empty>
                    </td>
                  </tr>
                </tbody>
                <div></div>
              </table>
            </ChainDiv>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts/lib/echarts");
import ChainDiv from "../components/assembly/ChainDiv.vue";
export default {
  components: {
    ChainDiv
  },
  created() {
    this.getDetailData(this.$route.query.id);
    this.getWorthData(this.$route.query.id);
    this.getProductData(this.$route.query.id);
    this.getSubjectData(this.$route.query.id);
    this.getThird();
  },
  data() {
    return {
      chainDetailData: [],
      chainWorthData: [],
      chainProductData: [],
      chainSubjectData: [],
      chainvideoData: {
        fileUrl: ""
      },
      thirdData: [],
      first: 0,
      second: 0,
      third: 0,
      subjectDataTime: "",

      // 天气预报src
      weatherSrc: "",
      mobileWeatherSrc: "",

      // 手机端展示个更多产品
      mobileMore: false
    };
  },
  methods: {
    handleLink(link) {
      if (link) {
        window.open(link);
      }
    },
    // 基础信息
    getDetailData(id) {
      this.$axios({
        url: "/visualize/chainView/basicData/" + id,
        method: "get"
      }).then(res => {
        this.chainDetailData = res.data;
        this.first = this.chainDetailData.firstWorth;
        this.second = this.chainDetailData.secondWorth;
        this.third = this.chainDetailData.thirdWorth;

        const weatherMap = {
          // 揭西
          "445222": "60379",
          // 普宁
          "445281": "60245",
          // 惠来
          "445224": "70087",
          // 揭东
          "445221": "71284",
          // 空港
          "445298": "59315",
          // 榕城
          "445202": "72050"
        };
        this.weatherSrc = `//tianqi.2345.com/plugin/widget/index.htm?s=2&z=1&t=0&v=0&d=5&bd=0&k=&f=&ltf=009944&htf=cc0000&q=1&e=0&a=0&c=${
          weatherMap[this.chainDetailData.areaCode]
        }&w=385&h=96&align=center`;
        this.mobileWeatherSrc = `//tianqi.2345.com/plugin/widget/index.htm?s=2&z=1&t=0&v=0&d=2&bd=0&k=&f=&ltf=009944&htf=cc0000&q=1&e=0&a=0&c=${
          weatherMap[this.chainDetailData.areaCode]
        }&w=385&h=96&align=center`;
        this.drawLine();
      });
    },
    // 产值信息
    getWorthData(id) {
      this.$axios({
        url: "/visualize/chainView/getChainInfo/" + id,
        method: "get"
      }).then(res => {
        this.chainWorthData = res.data.chainInfo;
        if (res.data.video) {
          this.chainvideoData = res.data.video;
        }
      });
    },
    // 产品信息
    getProductData(id) {
      this.$axios({
        url:
          "/visualize/cityView/getProductList?pageSize=999&pageNum=1&&chainId=" +
          id,
        method: "post"
      }).then(res => {
        this.chainProductData = res.data.rows;
      });
    },
    // 龙头企业列表
    getSubjectData(id) {
      this.$axios({
        url: "/visualize/chainView/lendEnterprises/" + id,
        method: "get"
      }).then(res => {
        this.chainSubjectData = res.data;
      });
    },
    // 政策法规
    getThird() {
      this.$axios({
        url: "/p/web/content/getWebContentTopList",
        method: "post",
        data: {
          id: 2909302671066112,
          number: 5
        }
      }).then(res => {
        this.thirdData = res.data.data;
        console.log(this.thirdData);
      });
    },
    handlePush(id) {
      this.$router.push("/Explicit?id=" + id + "&type=system");
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("echarts"));
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        legend: {
          bottom: 0
        },
        series: [
          {
            name: "产值信息",
            type: "pie",
            radius: ["25%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2
            },
            roseType: "angle",
            data: [
              { value: this.first, name: "一产产值" },
              { value: this.second, name: "二产产值" },
              { value: this.third, name: "三产产值" }
            ]
          }
        ]
      });
    }
  }
};
</script>

<style scoped>
@import "../styles/chain.css";
/* 单独处理背景图 */
.chain-bg {
  background: url("../assets/code/chainView-bg.jpg") no-repeat;
  background-size: cover;
}

#echarts {
  width: 100%;
  height: 100%;
}
.policy {
  cursor: pointer;
}
.mobile-weather,
.mobile-info,
.chain-product-more {
  display: none;
}
@media (max-width: 768px) {
  #echarts {
    height: 250px;
  }
  .mobile-weather,
  .mobile-info {
    display: block;
  }
  .pc-weather,
  .chain-info {
    display: none;
  }
  .chain {
    padding-top: 60px;
  }
  .chain-view-head {
    margin-top: -60px;
  }
  .chain-view-head img {
    width: 90px;
    height: 90px;
  }
  .chain-view .chain-view-title {
    font-size: 20px;
    margin: 10px 0 5px;
  }
  .chain-view-brief {
    font-size: 14px;
  }
  .chain-wrapper {
    margin: 20px;
  }
  .chain-product {
    height: 315px;
    overflow: hidden;
    grid-template-columns: repeat(2, 1fr);
  }
  .chain-product.on {
    height: auto;
  }
  .chain-product .chain-product-item img {
    height: 150px;
  }
  .chain-media,
  .chain-layout {
    display: block;
  }
  .chain-media .chain-media-video {
    width: 100%;
  }
  .chain .chain-banner img {
    height: 100px;
    object-fit: cover;
  }
  .chain-media .chain-media-info {
    font-size: 16px;
  }
  .chain-media .chain-media-video {
    height: 200px;
  }
  .chain-table-wrap {
    display: block;
  }
  .chain-table td {
    font-size: 14px;
  }
  .chain-table td.title span {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .mobile-info {
    padding: 20px;
  }
  .mobile-info-top {
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  .mobile-info-top-item {
    text-align: center;
  }
  .mobile-info-top-label {
    font-size: 14px;
    color: #999;
  }
  .mobile-info-top-value {
    margin-top: 5px;
    font-size: 18px;
  }
  .mobile-info-bottom {
    display: flex;
    background: #f5f5f5;
    padding: 10px 0;
    border-radius: 5px;
  }
  .mobile-info-bottom-item {
    flex: 1;
    border-right: 1px solid #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-info-bottom-item img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  .mobile-info-bottom-item > div {
  }
  .mobile-info-bottom-item:last-child {
    border-right: 0;
  }
  .mobile-info-bottom-label {
    color: #999;
    font-size: 14px;
  }
  .mobile-info-bottom-value {
    margin-top: 5px;
    font-size: 18px;
  }

  .chain-product-more {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 15px;
    background: #f5f5f5;
    line-height: 40px;
    font-size: 14px;
    color: #0453a0;
  }
}
</style>
