<template>
  <div class="btn">
    <div @click="handleClick">{{ text }}</div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>
<style scoped>
.btn {
  margin: 50px 0;
}
.btn div {
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  background-color: #0353a0;
  display: flex;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 5px;
  border-radius: 5px;
}
</style>
