<template>
  <div class="home">
    <div class="w">
      <div class="login">
        <div class="login-pic">
          <img src="../assets/login.png" alt="" />
        </div>
        <!-- 登录表单 -->
        <div class="login-form">
          <h2>登 录</h2>
          <div class="form-control">
            <label>
              <i class="el-icon-user"></i>
              <input
                type="text"
                placeholder="请输入账号"
                v-model="form.accountNumber"
              />
            </label>
          </div>
          <div class="form-control">
            <label>
              <i class="el-icon-lock"></i>
              <input
                type="password"
                placeholder="请输入密码"
                v-model="form.password"
              />
            </label>
          </div>
          <div class="form-control">
            <label>
              <i class="el-icon-mobile"></i>
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="form.validateCode"
                @keydown.enter="handleSubmit"
              />
            </label>
            <img
              class="form-control-captcha"
              :src="captcha"
              @click="handleCaptcha"
            />
          </div>
          <div class="login-form-footer">
            <Button text="登 录" @click="handleSubmit"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "../api/login";
import Button from "../components/assembly/Button";
export default {
  components: {
    Button
  },
  data() {
    return {
      form: {
        accountNumber: "",
        password: "",
        validateCode: "",
        rememberMe: true
      },
      // 验证码
      captcha: `/chainer/captcha/captchaImage?type=math`
    };
  },
  methods: {
    // 改变验证码
    handleCaptcha() {
      this.captcha = `/chainer/captcha/captchaImage?type=math&s=${Math.random()}`;
    },
    handleSubmit() {
      login(this.form).then(res => {
        const info = res.data;
        if (info.code == 0) {
          this.$store.commit("setUserInfo", info.data);
          // 未认证企业则需要跳转到认证页面
          if (info.data.industrySubject.authStatus === "not_certified") {
            this.$router.push("/UserCenter/Auth");
          } else {
            this.$router.push("/Basic");
          }
        } else {
          // 登录失败时更新验证码
          this.$utils.alertMessage({
            message: info.msg,
            type: "error",
            callback: () => {
              this.form.validateCode = "";
              this.handleCaptcha();
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.login {
  display: flex;
  padding: 80px 0;
}
.login > div {
  width: 50%;
}
.login .login-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login .login-form {
  padding: 100px;
  background: #fff;
  box-sizing: border-box;
}
.login .login-form-footer {
  margin-top: 50px;
}
.login .login-form-footer a {
  display: block;
  text-decoration: none;
  margin: 30px 0 0;
  text-align: center;
  color: #999;
}

.form-control {
  display: flex;
  margin-bottom: 20px;
}
.form-control label {
  flex: 1;
  position: relative;
}
.form-control label i {
  position: absolute;
  left: 5px;
  top: 8px;
  font-size: 18px;
}
.form-control label input {
  outline: none;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  padding: 10px 12px 10px 30px;
  box-sizing: border-box;
}
.form-control .form-control-captcha {
  height: 39px;
  margin-left: 10px;
}
</style>
