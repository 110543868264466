<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabber :active="8"></LeftTabber>
        </div>
        <div class="right">
          <Title text="专家答疑互动"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="6"
                ><div class="form-group">
                  <span>疑问：</span>
                  <el-input v-model="detail.question" size="mini">
                  </el-input></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >搜索</el-button
                  ><el-button size="mini" type="primary" @click="handleAdd"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="answeringData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="chainName" label="产业链名称">
              </el-table-column>
              <el-table-column prop="subjectName" label="主体名称">
              </el-table-column>
              <el-table-column prop="question" label="疑问"> </el-table-column>
              <el-table-column prop="expertName" label="具体专家" width="80">
              </el-table-column>
              <el-table-column prop="status" label="状态" width="80">
                <template slot-scope="scope">
                  {{ formatType(scope.row.status) }}
                </template>
              </el-table-column>

              <el-table-column prop="questionType" label="疑问类型" width="80">
                <template slot-scope="scope">
                  {{ forquestionType(scope.row.questionType) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="70">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleView(scope.row.interactionId)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="1"
                :total="Total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
import LeftTabber from "../../components/assembly/LeftTabber";

export default {
  components: {
    Title,
    Tabbar,
    LeftTabber
  },
  created() {
    this.getList();
    this.getTypeDictData();
    this.getQuestionType();
  },
  data() {
    return {
      input: "",
      answeringData: [],
      Total: 0,
      detail: {
        pageNum: 1,
        pageSize: 5,
        question: ""
      },
      statusType: "",
      questionType: ""
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/web/interaction/getInteractionList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.answeringData = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    getTypeDictData() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "interaction_status"
        }
      }).then(res => {
        this.statusType = res.data.data;
      });
    },
    formatType(value) {
      for (var i = 0; i < this.statusType.length; i++) {
        if (this.statusType[i].dictValue === value) {
          return this.statusType[i].dictLabel;
        }
      }
    },
    getQuestionType() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "interaction_question_type"
        }
      }).then(res => {
        this.questionType = res.data.data;
      });
    },
    forquestionType(value) {
      for (var i = 0; i < this.questionType.length; i++) {
        if (this.questionType[i].dictValue === value) {
          return this.questionType[i].dictLabel;
        }
      }
    },
    handleCurrentChange(page) {
      this.detail.pageNum = page;
      this.getList();
    },
    search() {
      this.detail.pageNum = 1;
      this.getList();
    },
    handleView(id) {
      this.$router.push("/Answering/AnsweringList?id=" + id);
    },
    handleAdd() {
      this.$router.push("/Answering/AnsweringAdd");
    }
  }
};
</script>
<style scoped>
.all {
  background-color: #eee;
}
.w {
  background-color: #fff;
  height: 100%;
}
</style>
