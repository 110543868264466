<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="10"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业需求信息"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="8"
                ><div class="form-group">
                  <span>需求标题：</span>
                  <el-input v-model="detail.title" size="mini"> </el-input></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >搜索</el-button
                  >
                  <el-button size="mini" type="primary" @click="add"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="demandData" border>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="title" label="需求标题"> </el-table-column>
              <el-table-column prop="subjectName" label="企业名称">
              </el-table-column>
              <el-table-column
                prop="status"
                label="需求状态"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  {{ getTextLabel(scope.row.status) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="170">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.demandId)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleEdit(scope.row.demandId)"
                    v-if="scope.row.status === 'draft'"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDel(scope.row.demandId)"
                    v-if="
                      scope.row.status !== 'accepted' &&
                        scope.row.status !== 'finished'
                    "
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="Total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getList();
    this.getText();
  },
  data() {
    return {
      demandData: [],
      Total: 0,
      getTextcontent: "",
      detail: {
        pageNum: 1,
        pageSize: 5,
        title: ""
      }
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/demand/getList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.demandData = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    handleCurrentChange(page) {
      this.detail.pageNum = page;
      this.getList();
    },
    getText() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "industry_demand_status"
        }
      }).then(res => {
        this.getTextcontent = res.data.data;
      });
    },
    getTextLabel(value) {
      for (var i = 0; i < this.getTextcontent.length; i++) {
        if (this.getTextcontent[i].dictValue == value) {
          return this.getTextcontent[i].dictLabel;
        }
      }
    },
    search() {
      this.detail.pageNum = 1;
      this.getList();
    },
    add() {
      this.$router.push("/Demand/DemandAdd");
    },
    handleView(id) {
      this.$router.push("/Demand/DemandList?id=" + id);
    },
    handleEdit(id) {
      this.$router.push("/Demand/DemandEdit?id=" + id);
    },
    handleDel(id) {
      this.$confirm("是否删除该文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/p/demand/delete",
          method: "post",
          data: {
            demandId: id
          }
        }).then(() => {
          if ((this.Total - 1) % 5 == 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
