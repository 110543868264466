import Vue from "vue";
import App from "./App.vue";

import Router from "vue-router";
import router from "./router";

import vueSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import ElementUI from "element-ui";
// 全部的
import "element-ui/lib/theme-chalk/index.css";
// 使用axios
import $axios from "./utils/axios";
Vue.prototype.$axios = $axios;

// 挂载公共库
import $utils from "./utils/utils";
Vue.prototype.$utils = $utils;

import echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 引入vuex
import store from "./utils/store";

Vue.use(Router);
Vue.use(vueSwiper);
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
