<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    const userInfo = this.$utils.getUserInfo();
    if (userInfo) {
      this.$store.commit("setUserInfo", JSON.parse(userInfo));
    }
  },
};
</script>

<style>
@import url("./styles/common.css");
html,
body,
#app {
  height: 100%;
}
</style>
