<template>
  <div class="chain">
    <div class="chain-bg">产业链农特产品</div>
    <!-- 手机筛选 -->
    <div class="mobile-screen">
      <div class="mobile-screen-item">
        <span>地区：</span>
        <select @change="mobileAreaChange">
          <option
            :value="item.code"
            v-for="(item, index) in city"
            :key="index"
            >{{ item.name }}</option
          >
        </select>
      </div>
      <div class="mobile-screen-item">
        <span>产业链：</span>
        <select @change="mobileChainChaneg">
          <option value="">全部</option>
          <option
            :value="item.chainId"
            v-for="(item, index) in areaChain"
            :key="index"
            >{{ item.chainName }}</option
          >
        </select>
      </div>
    </div>
    <div class="chain-body">
      <div class="w">
        <div class="screen">
          <div class="screen-item">
            <span class="screen-item-title">地区：</span>
            <div class="screen-item-content">
              <div class="screen-area">
                <a
                  href="javascript:;"
                  class="screen-area-item"
                  v-for="(item, index) in city"
                  :key="index"
                  @click="clickArea(item.code, index)"
                  :class="{ areaClass: showNum == index }"
                  >{{ item.name }}</a
                >
              </div>
            </div>
          </div>
          <div class="screen-item">
            <span class="screen-item-title">产业链：</span>
            <div
              class="screen-item-content"
              v-if="areaChain && areaChain.length !== 0"
            >
              <div class="screen-chain">
                <a
                  href="javascript:;"
                  class="screen-chain-item"
                  v-for="(item, index) in areaChain"
                  :key="index"
                  @click="handleCheck(city[showNum].code, item.chainId, index)"
                  :class="{ show: showChain == index }"
                >
                  <img :src="item.images" alt />
                  <p>{{ item.chainName }}</p>
                </a>
              </div>
            </div>
            <div v-else class="noneChain">
              该地区暂无产业链
            </div>
          </div>
        </div>
        <div class="chain-list" v-if="chainList && chainList.length !== 0">
          <div
            class="chain-list-item"
            v-for="(item, index) in chainList"
            :key="index"
            @click="goPages(item.buyLink)"
          >
            <img :src="item.images" alt />
            <div class="chain-list-info">
              <div class="chain-list-title" :title="item.finishedProductName">
                {{ item.finishedProductName }}
              </div>
              <div class="chain-list-brief" :title="item.subjectName">
                {{ item.subjectName }}
              </div>
            </div>
            <div class="chain-list-btn" v-if="item.buyLink">立即购买</div>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无产品信息"></el-empty>
        </div>
        <div class="chain-pagination">
          <el-pagination
            v-if="Total && Total.length !== 0"
            background
            layout="pager"
            :total="Total"
            :current-page="pageNum"
            :page-size="pageSize"
            @current-change="change"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getCity();
    this.getChainList();
    this.getAreaData();
  },
  data() {
    return {
      city: [],
      all: [{ name: "全部", code: "" }],
      showNum: 0,
      areaChain: [],
      pageSize: 24,
      pageNum: 1,
      chainList: [],
      Total: 1,
      showChain: -1,

      // 手机端参数
      areaCode: "",
      chainId: ""
    };
  },
  methods: {
    // 手机城市筛选
    mobileAreaChange(e) {
      this.areaCode = e.target.value;
      this.clickArea(this.areaCode);
    },
    // 手机产业链筛选
    mobileChainChaneg(e) {
      this.chainId = e.target.value;
      this.pageNum = 1;
      this.$axios({
        url: "/visualize/cityView/getProductList",
        method: "post",
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          countyCode: this.areaCode,
          chainId: this.chainId
        }
      }).then(res => {
        this.chainList = res.data.rows;
        this.Total = res.data.total;
      });
    },
    // 城市获取
    getCity() {
      this.$axios({
        url: "/visualize/cityView/getCountyList",
        method: "get"
      }).then(res => {
        this.city = res.data.rows;
        this.city = this.all.concat(this.city);
      });
    },
    // 产业链获取
    getChainList() {
      this.$axios({
        url: "/visualize/cityView/getChainByCounty",
        method: "post",
        params: {
          countyCode: ""
        }
      }).then(res => {
        this.areaChain = res.data;
      });
    },
    // 全部产品获取
    getAreaData() {
      console.log(this.areaCode);
      console.log(this.chainId);
      this.$axios({
        url: "/visualize/cityView/getProductList",
        method: "post",
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          countyCode: this.areaCode,
          chainId: this.chainId
        }
      }).then(res => {
        this.chainList = res.data.rows;
        this.Total = res.data.total;
      });
    },
    // 点击地区时对应产业链
    clickArea(code, index) {
      if (index && this.showNum === index) return;
      this.areaCode = code;
      this.handleCheck(code);
      this.showNum = index;
      this.$axios({
        url: "/visualize/cityView/getChainByCounty",
        method: "post",
        data: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          countyCode: code
        }
      }).then(res => {
        this.areaChain = res.data;
      });
      this.pageNum = 1;
    },
    // // 分页
    change(page) {
      this.pageNum = page;
      this.getAreaData();
    },
    // 点击产业链对应的商品
    handleCheck(code, ids, index) {
      this.pageNum = 1;
      this.showChain = this.showChain === index ? -1 : index;
      const chainId = this.showChain === index ? ids : "";
      this.chainId = chainId;
      this.$axios({
        url: "/visualize/cityView/getProductList",
        method: "post",
        params: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          countyCode: code,
          chainId: chainId
        }
      }).then(res => {
        this.chainList = res.data.rows;
        this.Total = res.data.total;
      });
    },
    // 跳转到产品对应外界面
    goPages(link) {
      if (link.length !== 0) {
        window.open(link, "_blank");
      }
    }
  }
};
</script>

<style scoped>
@import "../styles/chain.css";
.screen .screen-area .screen-area-item {
  padding: 5px 20px;
  margin: 0;
  align-items: center;
  text-decoration: none;
  color: #0453a0;
  border-radius: 5px;
}
.screen .screen-area .areaClass {
  color: #fff;
  background-color: #0453a0;
  box-sizing: border-box;
}
.noneChain {
  color: #999;
  margin-left: 20px;
}

.mobile-screen {
  display: none;
}

@media (max-width: 768px) {
  .chain-bg {
    background: url("../assets/code/banner2.png") no-repeat;
    background-size: cover;
  }
  .screen {
    display: none;
  }
  .mobile-screen {
    display: block;
    display: flex;
    background: #eee;
    padding: 0 20px;
  }
  .mobile-screen .mobile-screen-item {
    flex: 1;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 45px;
  }
  .mobile-screen .mobile-screen-item span {
    white-space: nowrap;
  }
  .mobile-screen .mobile-screen-item select {
    flex: 1;
    height: 45px;
    font-size: 14px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background: none;
  }
  .chain {
    padding-top: 60px;
  }

  .chain .chain-body {
    margin: 0;
    padding: 20px;
  }

  .chain .chain-list {
    margin: 0 0 20px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  .chain .chain-list .chain-list-item img {
    height: 150px;
  }
}
</style>
