<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="3"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业项目管理"></Title>
          <!-- 表框 -->
          <el-form ref="form" :model="detail" :rules="rules">
            <div class="form">
              <div class="form-item">
                <span> <i class="color-red">*</i>项目标题：</span>
                <div class="form-item-content">
                  <el-form-item prop="projectTitle">
                    <el-input v-model="detail.projectTitle"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span> <i class="color-red">*</i>项目年份：</span>
                <div class="form-item-content">
                  <el-form-item prop="year">
                    <el-date-picker
                      v-model="detail.year"
                      type="year"
                      value-format="yyyy"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span><i class="color-red">*</i> 项目状态：</span>
                <el-form-item class="form-item-content" prop="declareStatus">
                  <el-select
                    v-model="detail.declareStatus"
                    style="width: 100%"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in diction"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-item">
                <span> <i class="color-red">*</i>申报日期：</span>
                <div class="form-item-content">
                  <el-form-item prop="declareDate">
                    <el-date-picker
                      v-model="detail.declareDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span> <i class="color-red">*</i>承担主体：</span>
                <div class="form-item-content">
                  <el-form-item prop="undertakerName">
                    <el-input v-model="detail.undertakerName"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span> <i class="color-red">*</i>计划投入资金：</span>
                <div class="form-item-content">
                  <el-form-item prop="totalPlanInvestAmt">
                    <el-input v-model="detail.totalPlanInvestAmt">
                      <template slot="append">万元</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span>负责人：</span>
                <div class="form-item-content">
                  <el-form-item prop="director">
                    <el-input v-model="detail.director"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span>联系方式：</span>
                <div class="form-item-content">
                  <el-form-item prop="telephone">
                    <el-input v-model="detail.telephone"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span>E-mail：</span>
                <div class="form-item-content">
                  <el-form-item prop="email">
                    <el-input v-model="detail.email"></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="form-item">
                <span>通讯地址：</span>
                <div class="form-item-content">
                  <el-form-item prop="mailAddress">
                    <el-input v-model="detail.mailAddress"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span>邮政编码：</span>
                <div class="form-item-content">
                  <el-form-item prop="postalCode">
                    <el-input v-model="detail.postalCode"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span> <i class="color-red">*</i>建设地点：</span>
                <div class="form-item-content">
                  <el-form-item prop="buildAddress">
                    <el-input v-model="detail.buildAddress"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span>经度：</span>
                <div class="form-item-content">
                  <el-form-item prop="longitude">
                    <el-input v-model="detail.longitude"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <span>纬度：</span>
                <div class="form-item-content">
                  <el-form-item prop="latitude">
                    <el-input v-model="detail.latitude"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item percent">
                <span>项目简介：</span>
                <div class="form-item-content">
                  <el-form-item prop="introduction">
                    <el-input
                      type="textarea"
                      v-model="detail.introduction"
                      :autosize="{ minRows: 3 }"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item percent">
                <span> <i class="color-red">*</i>建设内容：</span>
                <div class="form-item-content">
                  <el-form-item prop="buildContent">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 3 }"
                      v-model="detail.buildContent"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item percent">
                <span>建设目标：</span>
                <div class="form-item-content">
                  <el-form-item prop="buildTarget">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 3 }"
                      v-model="detail.buildTarget"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item percent">
                <span>效益分析：</span>
                <div class="form-item-content">
                  <el-form-item prop="benefitAnalysis">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 3 }"
                      v-model="detail.benefitAnalysis"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item percent">
                <span>申报材料：</span>
                <el-form-item class="form-item-content" prop="attachment">
                  <el-upload
                    action="/chainer/p/utils/common/upload"
                    :on-success="uploadFile"
                    :on-remove="removeFile"
                  >
                    <el-button icon="el-icon-upload2" size="small"
                      >点击上传
                    </el-button>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>备注：</span>
                <div class="form-item-content">
                  <el-form-item prop="remark">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 3 }"
                      v-model="detail.remark"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- <div class="form-item percent">
                <span>申报材料：</span>
                <div class="form-item-content"></div>
              </div> -->
            </div>
          </el-form>
          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回</el-button>
            <el-button type="info" @click="getForm('save')">暂 存</el-button>
            <el-button type="primary" @click="getForm('submit')"
              >提 交</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  created() {
    this.getDiction();
  },
  data() {
    return {
      diction: [],
      detail: {
        operateType: "",
        projectTitle: "",
        year: "",
        declareStatus: "",
        declareDate: "",
        undertakerName: "",
        director: "",
        telephone: "",
        email: "",
        postalCode: "",
        mailAddress: "",
        buildAddress: "",
        longitude: "",
        latitude: "",
        totalPlanInvestAmt: "",
        introduction: "",
        buildContent: "",
        buildTarget: "",
        benefitAnalysis: "",
        remark: "",
        applyMaterial: ""
      },
      rules: {
        projectTitle: [
          { required: true, message: "请填写项目标题", trigger: "blur" }
        ],
        year: [{ required: true, message: "请选择年份", trigger: "change" }],
        declareStatus: [
          { required: true, message: "请选择项目性质", trigger: "change" }
        ],
        declareDate: [
          { required: true, message: "请填写申报日期", trigger: "change" }
        ],
        undertakerName: [
          { required: true, message: "请填写承担主体", trigger: "blur" }
        ],
        buildAddress: [
          { required: true, message: "请填写建设地点", trigger: "blur" }
        ],
        buildContent: [
          { required: true, message: "请填写建设内容", trigger: "blur" }
        ],
        totalPlanInvestAmt: [
          { required: true, message: "请填写计划投资金", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    getDiction() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "project_declare_status"
        }
      }).then(res => {
        this.diction = res.data.data;
      });
    },
    forGetDiction(value) {
      for (var i = 0; i < this.diction.length; i++) {
        if (this.diction[i].dictValue == value) {
          return this.diction[i].dictLabel;
        }
      }
    },
    // 上传相关材料
    uploadFile(response, file, fileList) {
      // 赋值给 form.attachment
      this.dealFile(fileList);
    },
    // 删除相关材料
    removeFile(file, fileList) {
      this.dealFile(fileList);
    },

    dealFile(fileList) {
      const fileIds = [];
      fileList.forEach(item => {
        fileIds.push(item.response.data.fileId);
      });
      this.detail.applyMaterial = fileIds.join(",");
    },
    getForm(type) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.detail.operateType = type;
          this.$axios({
            url: "/p/project/project/saveProject",
            method: "post",
            data: this.detail,
            json: true
          }).then(res => {
            if (res.data.code == 0) {
              this.$alert("您已成功提交！", "成功", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                callback: () => {
                  this.$router.push("/Project");
                }
              }).catch(() => {
                this.$message({
                  type: "error",
                  message: "成功取消"
                });
              });
            } else {
              this.$alert(res.data.msg, "错误提示", {
                confirmButtonText: "我知道了"
              });
            }
          });
        }
      });
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.import {
  padding: 20px;
  border-left: 1px solid #d2d2d2;
}
.el-form-item {
  margin-bottom: 0;
}
</style>
