<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="2"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="站内信件管理"></Title>
          <div class="sub-title">基本信息</div>
          <div class="form">
            <div class="form-item percent">
              <span>标题：</span>
              <div class="form-item-content">
                {{ mailListData.mailTitle }}
              </div>
            </div>
            <div class="form-item percent">
              <span>内容:</span>
              <div
                class="form-item-content contentOver"
                v-html="mailListData.content"
              ></div>
            </div>
            <div class="form-item percent">
              <span>备注:</span>
              <div class="form-item-content">
                {{ mailListData.remark }}
              </div>
            </div>
          </div>
          <div class="sub-title">信件信息</div>
          <div class="form">
            <div class="form-item percent">
              <span>发送者:</span>
              <div class="form-item-content">
                {{ mailListData.fromUserName }}
              </div>
            </div>
            <div class="form-item percent">
              <span>发送部门:</span>
              <div class="form-item-content">
                {{ mailListData.fromDeptName }}
              </div>
            </div>
            <div class="form-item percent">
              <span>发送时间:</span>
              <div class="form-item-content">
                {{ mailListData.sendDate }}
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button @click="handleBack">返 回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    Title,
    LeftTabberVue,
    Tabbar
  },
  data() {
    return {
      mailListData: []
    };
  },
  created() {
    this.getList(this.$route.query.id);
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/station/mail/getMailById",
        method: "post",
        data: {
          sendId: id
        }
      }).then(res => {
        this.mailListData = res.data.data;
        if (this.mailListData.isRead === "N") {
          this.$axios({
            url: "/p/station/mail/markAsReaded",
            method: "post",
            data: {
              sendId: this.mailListData.sendId
            }
          });
        }
      });
    },
    handleBack() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.sub-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 8px;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  font-size: 14px;
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
  word-break: break-all;
}
.percent {
  width: 100%;
}
.contentOver {
  width: 100%;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
