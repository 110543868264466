<template>
  <div class="allshow">
    <div class="maintext w">
      <h2>{{ detail.title }}</h2>
      <div class="source">
        <p>
          来源：{{
            detail.source == "本网" ? "揭阳市人民政府门户" : detail.source
          }}
        </p>
        <p>发布时间：{{ detail.date || detail.createTime }}</p>
      </div>

      <div class="active" v-html="detail.content || detail.contents"></div>

      <div
        v-if="detail.attachment && detail.attachment.length > 0"
        class="annex"
      >
        <a
          :href="item.url"
          v-for="(item, index) in detail.attachment"
          :key="index"
          >{{ item.name }}</a
        >
      </div>

      <div class="video" v-if="detail.videoFile">
        <video :src="'chainer/' + detail.videoFile[0].fileUrl" controls></video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: []
    };
  },
  created() {
    const { id, catalogId, type } = this.$route.query;
    // 是否为系统后台文章
    if (id) {
      this.getList(id, type);
    }
    if (catalogId) {
      this.getCatalogList(catalogId);
    }
  },
  methods: {
    getCatalogList(catalogId) {
      this.$axios({
        url: "/p/web/content/getWebContentList",
        method: "POST",
        params: {
          catalogId
        }
      }).then(res => {
        const info = res.data.data;
        if (info.rows.length > 0) {
          const id = res.data.data.rows[0].id;
          this.getList(id, "system");
        } else {
          this.$utils.alertMessage({
            type: "error",
            message: "该栏目下没有挂载文章",
            callback: () => {
              this.$router.back();
            }
          });
        }
      });
    },
    getList(id, type) {
      const url =
        type === "system"
          ? "/p/web/content/getWebContent"
          : "/p/thirdApi/manuscript/getManuscriptDetail";
      const method = type === "system" ? "POST" : "GET";

      this.$axios({
        url,
        method,
        params: {
          id: id
        }
      }).then(res => {
        this.detail = res.data.data;
      });
    }
  }
};
</script>
<style>
.allshow {
  height: 100%;
}
.maintext {
  padding: 50px 0;
  box-sizing: border-box;
  background: #fff;
}
.maintext h2 {
  display: flex;
  justify-content: center;
  color: #222;
  margin: 0;
  text-align: center;
  font-size: 26px;
}
.source {
  display: flex;
  justify-content: center;
}
.source p {
  padding: 0 5px;
  font-size: 14px;
}
.active {
  color: #333;
  font-size: 22px;
}

.active table {
  max-width: 100%;
}

.sizenum {
  display: flex;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.sizenum div {
  font-size: 16px;
}
.annex {
  color: #409eff;
}

.video {
  display: flex;
  justify-content: center;
}
.video video {
  width: 50%;
}

@media (max-width: 768px) {
  .maintext {
    padding: 80px 20px 20px;
  }

  .maintext h2 {
    font-size: 20px;
  }

  .active img {
    max-width: 100%;
    height: auto !important;
  }

  .source p {
    margin-bottom: 0;
    font-size: 13px;
  }

  .video {
    display: block;
  }
  .video video {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
