<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="9"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="申报记录"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="8"
                ><div class="form-group">
                  <span>流水号：</span>
                  <el-input v-model="billNo" size="mini"> </el-input></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button
                    v-model="billNo"
                    size="mini"
                    type="primary"
                    @click="reconrdSearch"
                    >搜索</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    align="center"
                    @click="recordAdd($route.query.id, $route.query.title)"
                    >申报</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="detail" border
              ><el-table-column prop="billNo" label="流水号"> </el-table-column>
              <el-table-column prop="policyName" label="政策名称">
              </el-table-column>
              <el-table-column
                prop="applyBy"
                label="申请人"
                align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="declareTime"
                label="申报时间"
                align="center"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="policyType"
                label="状态"
                align="center"
                width="80"
              >
                <template slot-scope="scope">
                  {{ forpolicyType(scope.row.status) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="130" align="center">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click="handleView(scope.row.acceptId)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handledel(scope.row.acceptId)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="Total"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  created() {
    this.getList();
    this.getTypeDictData();
  },
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  data() {
    return {
      detail: [],
      SupportData: [],
      Total: 0,
      pageNum: 1,
      policyStatus: "",
      billNo: ""
    };
  },

  methods: {
    getList() {
      this.$axios({
        url: "/p/support/apply/getList",
        method: "post",
        data: {
          policyId: this.$route.query.id,
          billNo: this.billNo,
          pageNum: this.pageNum,
          pageSize: 5
        }
      }).then(res => {
        this.detail = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    reconrdSearch() {
      this.pageNum = 1;
      this.getList();
    },
    recordAdd(id, title) {
      this.$router.push({
        path: "/Support/SupportRecord/SupportRecordAdd",
        query: {
          id: id,
          title: title
        }
      });
    },
    handleView(id) {
      this.$router.push({
        path: "/Support/SupportRecord/SupportRecordList",
        query: {
          id: id,
          pageId: this.$route.query.id
        }
      });
    },
    handledel(id) {
      this.$confirm("此操作将永久删除该申报记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/p/support/applyAttachment/delete",
          method: "post",
          data: {
            attachmentId: id
          }
        }).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.pageNum = 1;
          }
          this.getList();
        });
      });
    },
    handleCurrentChange(page) {
      this.pageNum = page;
      this.getList();
    },
    getTypeDictData() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "support_policy_accept_status"
        }
      }).then(res => {
        this.policyStatus = res.data.data;
      });
    },
    forpolicyType(value) {
      for (var i = 0; i < this.policyStatus.length; i++) {
        if (this.policyStatus[i].dictValue === value) {
          return this.policyStatus[i].dictLabel;
        }
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
</style>
