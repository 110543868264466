<template>
  <div class="home">
    <div class="w">
      <div class="login">
        <div class="login-pic">
          <img src="../assets/login.png" alt="" />
        </div>
        <!-- 登录表单 -->
        <div class="login-form">
          <h2>注 册</h2>
          <div class="form-control">
            <label>
              <i class="el-icon-postcard"></i>
              <input
                type="text"
                placeholder="请输入账户昵称"
                v-model="addForm.nickName"
              />
            </label>
          </div>
          <div class="form-control">
            <label>
              <i class="el-icon-user"></i>
              <input
                type="text"
                placeholder="请输入登录账号"
                v-model="addForm.accountNumber"
              />
            </label>
          </div>
          <div class="form-control">
            <label>
              <i class="el-icon-lock"></i>
              <input
                type="password"
                placeholder="请输入登录密码"
                v-model="addForm.password"
              />
            </label>
          </div>
          <div class="form-control">
            <label>
              <i class="el-icon-lock"></i>
              <input
                type="password"
                placeholder="请确认登录密码"
                v-model="addForm.checkpass"
              />
            </label>
          </div>
          <div class="login-form-footer">
            <Button text="提 交" @click="register"></Button>
          </div>
        </div>
      </div>
      <!-- <div class="wrapper">
        <el-form
          ref="form"
          label-width="auto"
          :model="addForm"
          :rules="addFormRules"
        >
          <Title text="账号信息"></Title>
          <el-form-item label="账号昵称 :" prop="nickName">
            <el-input v-model="addForm.nickName"></el-input>
          </el-form-item>
          <el-form-item label="登录账号 :" prop="accountNumber">
            <el-input v-model="addForm.accountNumber"></el-input>
          </el-form-item>
          <el-form-item label="登录密码 :" prop="password">
            <el-input type="password" v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码 :" prop="checkpass">
            <el-input type="password" v-model="addForm.checkpass"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="register('ruleForm')"
              >注 册</el-button
            >
          </el-form-item>
        </el-form>
      </div> -->
    </div>
  </div>
</template>
<script>
import Button from "../components/assembly/Button";

export default {
  components: {
    Button
  },
  data() {
    return {
      addForm: {
        nickName: "",
        accountNumber: "",
        password: "",
        checkpass: ""
      }
    };
  },
  methods: {
    register() {
      if (this.addForm.nickName == "") {
        return this.$alert("请输入账户昵称", "提示", {
          type: "error",
          confirmButtonText: "确定"
        });
      }
      if (this.addForm.accountNumber == "") {
        return this.$alert("请输入登录账号", "提示", {
          type: "error",
          confirmButtonText: "确定"
        });
      }
      if (this.addForm.password == "") {
        return this.$alert("请输入登录密码", "提示", {
          type: "error",
          confirmButtonText: "确定"
        });
      }
      if (this.addForm.password !== this.addForm.checkpass) {
        return this.$alert("两次密码不一致", "提示", {
          type: "error",
          confirmButtonText: "确定"
        });
      }
      this.handleSubmit();
    },
    handleSubmit() {
      this.$axios({
        url: "/p/register",
        method: "post",
        data: this.addForm
      }).then(res => {
        if (res.data.code == 0) {
          // 成功
          this.$alert(res.data.msg, "提示", {
            confirmButtonText: "前往登录界面",
            type: "success",
            callback: () => {
              this.$router.push({ path: "/Login" });
            }
          });
        } else {
          // 失败
          this.$alert(res.data.msg, "提示", {
            confirmButtonText: "我知道了",
            type: "error"
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.login {
  display: flex;
  padding: 80px 0;
}
.login > div {
  width: 50%;
}
.login .login-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login .login-form {
  padding: 100px;
  background: #fff;
  box-sizing: border-box;
}
.login .login-form-footer {
  margin-top: 50px;
}
.login .login-form-footer a {
  display: block;
  text-decoration: none;
  margin: 30px 0 0;
  text-align: center;
  color: #999;
}

.form-control {
  display: flex;
  margin-bottom: 20px;
}
.form-control label {
  flex: 1;
  position: relative;
}
.form-control label i {
  position: absolute;
  left: 5px;
  top: 8px;
  font-size: 18px;
}
.form-control label input {
  outline: none;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  padding: 10px 12px 10px 30px;
  box-sizing: border-box;
}
.form-control .form-control-captcha {
  height: 39px;
  margin-left: 10px;
}
</style>
