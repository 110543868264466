<template>
  <div>
    <div class="pc-banner">{{ catalogInfo.name }}</div>
    <div class="w">
      <!-- 手机端 -->
      <div class="banner">{{ catalogInfo.name }}</div>
      <div class="mobile-catalog">
        <div
          class="mobile-catalog-item"
          :class="{ active: checked == -1 }"
          @click="handlechecked(-1, catalogInfo)"
        >
          {{ catalogInfo.name }}
        </div>
        <div
          class="mobile-catalog-item"
          v-for="(item, index) in catalogList"
          :class="{ active: checked == index }"
          :key="index"
          @click="handlechecked(index, item)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 手机端 -->
      <div class="news">
        <div class="news-catalog" v-if="catalogList.length > 0">
          <ul class="news-catalog-list">
            <li
              v-for="(item, index) in catalogList"
              :key="index"
              :class="{ on: checked == index }"
              @click="handlechecked(index, item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="news-content">
          <div v-if="list.length > 0">
            <div v-if="single">
              <div class="rightacticle">
                <h2>{{ list[0].title }}</h2>
                <div class="acticleData">
                  <span>发布时间：{{ list[0].updateTime }}</span>
                </div>
                <div v-html="list[0].contents" class="acticleContent"></div>
              </div>
            </div>
            <template v-else>
              <ul class="news-list">
                <li v-for="(item, index) in list" :key="index">
                  <a
                    href="javascript:;"
                    @click="goDetail(item)"
                    class="flex-wrap"
                  >
                    <div class="news-list-title">
                      {{ item.title }}
                    </div>
                    <div class="news-list-meta">
                      {{
                        item.publishDate ? item.publishDate.substr(0, 10) : ""
                      }}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="pagination-box">
                <el-pagination
                  background
                  layout="pager"
                  :total="total"
                  :page-size="params.pageSize"
                >
                </el-pagination>
              </div>
            </template>
          </div>
          <el-empty description="该栏目下暂无文章" v-else></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getWebCatalog,
  getCatalogSpeciesList,
  getWebContentList
} from "../api/news";
export default {
  created() {
    const catalogId = this.$route.query.id;
    this.getWebCatalog(catalogId);
    this.params.catalogId = catalogId;
    this.getWebContentList();
  },
  data() {
    return {
      // 当前栏目信息
      catalogInfo: {},
      parentCatalog: {},
      // 同级栏目
      catalogList: [],
      // 列表参数
      params: {
        pageNum: 1,
        pageSize: 20,
        catalogId: undefined
      },
      total: 0,
      list: [],
      checked: -1,
      // 展示文章
      single: false
    };
  },
  watch: {
    $route() {
      this.catalogInfo = {};
      this.parentCatalog = {};
      this.catalogList = [];
      const catalogId = this.$route.query.id;
      this.getWebCatalog(catalogId);
      this.params.catalogId = catalogId;
      this.getWebContentList();
    }
  },
  methods: {
    // 获取当前栏目信息
    getWebCatalog(id) {
      getWebCatalog(id).then(res => {
        this.catalogInfo = res.data.data;
        this.getCatalogSpeciesList(this.catalogInfo.id);
        if (this.catalogInfo.pid > 0) {
          this.getParentCatalog(this.catalogInfo.pid);
        }
      });
    },
    // 获取父栏目信息
    getParentCatalog(id) {
      getWebCatalog(id).then(res => {
        this.parentCatalog = res.data.data;
      });
    },
    // 获取同级栏目
    getCatalogSpeciesList(id) {
      getCatalogSpeciesList(id).then(res => {
        this.catalogList = res.data.data;
      });
    },
    // 获取栏目列表
    getWebContentList() {
      getWebContentList(this.params).then(res => {
        const info = res.data.data;
        this.total = info.total;
        this.list = info.rows;
      });
    },
    handlechecked(index, item) {
      this.list = [];
      this.total = 0;
      this.checked = index;
      this.params.catalogId = item.id;
      this.params.pageNum = 1;

      this.single = item.catalogType === "single";
      this.getWebContentList();
    },
    goDetail(row) {
      if (row.contentType === "link") {
        window.open(row.url);
      } else {
        this.$router.push("/Explicit?id=" + row.id + "&type=system");
      }
    }
  }
};
</script>

<style scoped>
.news {
  margin-bottom: 20px;
}
.news .news-catalog {
  margin-bottom: 10px;
}
.news .news-catalog .news-catalog-list {
  display: flex;
  border-bottom: 1px solid #d3d4d5;
}
.news .news-catalog .news-catalog-list li {
  display: flex;
  align-content: center;
  padding: 15px 25px 10px;
  font-size: 20px;
  text-align: center;
  color: #999;
  cursor: pointer;
}
.news .news-catalog .news-catalog-list li.on {
  font-weight: 700;
  color: #0b57a1;
  border-bottom: 2px solid #0654a1;
}
.news .news-content {
  flex: 1;
}
.news .news-content .news-content-title {
  border-bottom: 2px solid #0453a0;
  color: #fff;
}
.news .news-content .news-content-title span {
  background: #0453a0;
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
}

.news .news-list {
  padding-left: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 50px;
}
.news .news-list li {
  width: 100%;
  list-style: disc;
  padding: 20px 0;
  border-bottom: 1px dashed #d3d3d3;
  font-size: 18px;
}
.news .news-list li a {
  text-decoration: none;
}
.news .news-list .news-list-title:hover {
  color: #0453a0;
}
.news .news-list .news-list-title {
  color: #333;
  width: 650px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.news .news-list .news-list-meta {
  color: #999;
  font-size: 14px;
}

.rightTitle {
  border-bottom: 2px solid #0453a0;
}
.rightTitle span {
  display: inline-block;
  min-width: 105px;
  color: #fff;
  background: #0453a0;
  padding: 10px 15px;
  text-align: center;
}
.rightacticle h2,
.acticleData {
  display: flex;
  justify-content: center;
}
.acticleData {
  font-size: 14px;
}
.mobile-catalog {
  display: none;
}
@media (max-width: 768px) {
  .news-catalog,
  .news-content-title {
    display: none;
  }
  .news {
    margin: 0;
  }
  .news .news-list {
    padding: 0 20px;
    display: block;
  }
  .news .news-list li {
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px dashed #d3d3d3;
    list-style: none;
  }
  .news .news-list a {
    display: block;
  }
  .news .news-list .news-list-title {
    width: 100%;
    white-space: normal;
  }
  .news .news-list .news-list-meta {
    margin-top: 5px;
  }
  .news .news-content {
    margin: 0;
  }
  .pagination-box {
    margin: 20px 0;
    width: 100%;
  }
  .rightacticle {
    padding: 0 20px;
  }
  .rightacticle img {
    max-width: 100%;
    height: auto !important;
  }
  .rightacticle h2 {
    font-size: 20px;
  }
  .acticleData {
    font-size: 13px;
  }
  .w {
    padding-top: 60px;
  }
  .mobile-catalog {
    padding: 0 20px;
    display: block;
    white-space: nowrap;
    overflow: scroll;
    background: #eee;
  }
  .mobile-catalog .mobile-catalog-item {
    margin-right: 15px;
    font-size: 14px;
    padding: 12px 0;
    display: inline-block;
  }
  .mobile-catalog .mobile-catalog-item.active {
    color: #0453a0;
    border-bottom: 2px solid #0453a0;
    box-sizing: border-box;
  }
}
</style>
