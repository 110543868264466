<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="7"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业产品信息"></Title>
          <div class="table-search">
            <el-row :gutter="5">
              <el-col :span="6"
                ><div class="form-group">
                  <span>产品名称：</span>
                  <el-input v-model="detail.productName" size="mini">
                  </el-input></div
              ></el-col>
              <el-col :span="4">
                <div class="rightBox">
                  <el-button size="mini" type="primary" @click="search"
                    >搜索</el-button
                  ><el-button size="mini" type="primary" @click="addPage"
                    >新增</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- 产品信息 -->
          <el-table :data="product" border>
            <el-table-column
              type="index"
              label="序号"
              width="50"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="finishedProductName" label="产品名称">
            </el-table-column>
            <el-table-column prop="categoryId" label="产品类别">
              <template slot-scope="scope">
                {{ getCategory(scope.row.categoryId) }}
              </template>
            </el-table-column>
            <el-table-column prop="brand" label="品牌名称"> </el-table-column>
            <el-table-column prop="productPlace" label="出产地">
            </el-table-column>
            <el-table-column label="操作" width="170px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-view"
                  @click="handleView(scope.row.finishedProductId)"
                  >查看</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row.finishedProductId)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.finishedProductId)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="5"
              :total="Total"
              @current-change="handelChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  // 挂载
  created() {
    this.getList();
    this.category();
  },
  data() {
    return {
      Total: 0,
      getType: [],
      detail: {
        productName: "",
        pageNum: 1,
        pageSize: 5
      },
      // 产品信息
      product: []
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/p/industry/product/getProductList",
        method: "post",
        data: this.detail
      }).then(res => {
        this.product = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    // 删除事件
    handleDelete(finishedProductId) {
      this.$confirm("此操作将永久删除该企业产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios({
          url: "/p/industry/product/removeProductById",
          method: "post",
          data: {
            ids: finishedProductId
          }
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          if ((this.Total - 1) % 5 === 0) {
            this.detail.pageNum = 1;
          }
          this.getList();
        });
      });
    },
    // 查看事件
    handleView(id) {
      this.$router.push("/Product/ProductList?id=" + id);
    },
    handleEdit(id) {
      this.$router.push("/Product/ProductEdit?id=" + id);
    },
    // 新增页面
    addPage() {
      this.$router.push("/Product/ProductAdd");
    },
    // 分页：本身返回page
    handelChange(page) {
      this.detail.pageNum = page;
      this.getList();
    },
    // 搜索
    search() {
      this.detail.pageNum = 1;
      this.getList();
    },
    // 字典
    category() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "product_category"
        }
      }).then(res => {
        this.getType = res.data.data;
      });
    },
    // dried_fruit
    getCategory(value) {
      for (var i = 0; i < this.getType.length; i++) {
        if (this.getType[i].dictValue === value) {
          console.log(this.getType[i].dictLabel);
          return this.getType[i].dictLabel;
        }
      }
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
  height: 100%;
}
</style>
