<template>
  <div class="all">
    <div class="w">
      <Tabbar :title="['企业工作台']"></Tabbar>
      <div class="main">
        <div class="left">
          <LeftTabberVue :active="4"></LeftTabberVue>
        </div>
        <div class="right">
          <Title text="企业基地信息"></Title>
          <!-- 标题 -->
          <div class="sub-title">基本信息</div>
          <!-- 表框 -->
          <el-form ref="form" :model="form" :rules="rules">
            <div class="form">
              <div class="form-item">
                <span><i class="color-red">*</i> 基地名称：</span>
                <el-form-item class="form-item-content" prop="baseName">
                  <el-input v-model="form.baseName"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>产品品种：</span>
                <el-form-item class="form-item-content" prop="breed">
                  <el-input v-model="form.breed"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>基地状态：</span>
                <el-form-item class="form-item-content" prop="status">
                  <el-select v-model="form.status" style="width: 100%">
                    <el-option
                      v-for="item in mainState"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-item">
                <span><i class="color-red">*</i> 负责人：</span>
                <el-form-item class="form-item-content" prop="leader">
                  <el-input v-model="form.leader"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span><i class="color-red">*</i> 联系电话：</span>
                <el-form-item class="form-item-content" prop="telephone">
                  <el-input v-model="form.telephone"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>所属街道/镇：</span>
                <el-form-item class="form-item-content" prop="townCode">
                  <el-select
                    v-model="form.townCode"
                    placeholder=""
                    style="width: 100%"
                    @change="changeTown"
                  >
                    <el-option
                      v-for="item in townDate"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>详细地址：</span>
                <el-form-item class="form-item-content" prop="address">
                  <el-input
                    v-model="form.address"
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>占地面积：</span>
                <el-form-item class="form-item-content" prop="coverSize">
                  <el-input v-model="form.coverSize"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>农技人员数量：</span>
                <el-form-item class="form-item-content" prop="staffCount">
                  <el-input v-model="form.staffCount"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>基地成立日期：</span>
                <el-form-item class="form-item-content" prop="buildDate">
                  <el-date-picker
                    v-model="form.buildDate"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd HH:mm:dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>开放时间：</span>
                <el-form-item class="form-item-content" prop="openHours">
                  <el-date-picker
                    v-model="form.openHours"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd HH:mm:dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>描述：</span>
                <el-form-item class="form-item-content" prop="describes">
                  <el-input
                    v-model="form.describes"
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>基地图片：</span>
                <el-form-item class="form-item-content">
                  <div class="upload-flex">
                    <template v-if="form.images">
                      <div
                        class="upload-img"
                        v-for="(item, index) in form.images.split(',')"
                        :key="index"
                      >
                        <el-image
                          style="width: 73px; height: 73px; border-radius: 6px"
                          :src="item"
                          fit="cover"
                          :preview-src-list="form.images.split(',')"
                        ></el-image>
                        <i
                          class="el-icon-error delimg"
                          @click="removeImg(index)"
                        ></i>
                      </div>
                    </template>

                    <el-upload
                      action="/chainer/p/utils/common/upload"
                      list-type="picture-card"
                      class="picture-img"
                      :show-file-list="false"
                      :on-success="uploadImg"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                </el-form-item>
              </div>
              <div class="form-item percent">
                <span>相关材料：</span>
                <el-form-item class="form-item-content" prop="attachment">
                  <el-upload
                    action="/chainer/p/utils/common/upload"
                    :on-success="uploadFile"
                    :on-remove="removeFile"
                    :file-list="fileList"
                  >
                    <el-button icon="el-icon-upload2" size="small"
                      >点击上传
                    </el-button>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
            <div class="sub-title position">位置信息</div>
            <div class="form">
              <div class="form-item">
                <span>经度：</span>
                <el-form-item class="form-item-content" prop="longitude">
                  <el-input v-model="form.longitude"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <span>纬度：</span>
                <el-form-item class="form-item-content" prop="latitude">
                  <el-input v-model="form.latitude"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>

          <!-- 按钮 -->
          <div class="form-button">
            <el-button @click="handleBack">返 回 </el-button>
            <el-button type="primary" @click="handleSubmit">提 交 </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftTabberVue from "../../components/assembly/LeftTabber";
import Title from "../../components/assembly/Title";
import Tabbar from "../../components/assembly/Tabbar";
import { mapState } from "vuex";

export default {
  components: {
    LeftTabberVue,
    Title,
    Tabbar
  },
  computed: {
    ...mapState(["userInfo"])
  },
  data() {
    return {
      // 基地状态
      mainState: [],
      townDate: [],
      form: {
        baseName: "",
        status: "",
        chainId: "",
        breed: "",
        leader: "",
        telephone: "",

        townCode: "",
        townName: "",

        address: "",
        coverSize: "",
        staffCount: "",
        buildDate: "",
        openHours: "",
        describes: "",
        images: "",
        attachment: "",
        longitude: "",
        latitude: ""
      },
      rules: {
        baseName: [
          { required: true, message: "请输入基地名称", trigger: "blur" }
        ],
        leader: [{ required: true, message: "请输入负责人", trigger: "blur" }],
        telephone: [
          { required: true, message: "请输入联系电话", trigger: "blur" }
        ]
      },
      fileList: []
    };
  },
  created() {
    this.getList(this.$route.query.id);
    this.getTownData();
    this.getMainState();
  },
  methods: {
    getList(id) {
      this.$axios({
        url: "/p/industry/base/getBaseById",
        method: "post",
        data: { baseId: id }
      }).then(res => {
        const info = res.data.data;
        if (info.attachmentFileList) {
          info.attachmentFileList.forEach(item => {
            this.fileList.push({
              id: item.fileId,
              name: item.fileName,
              url: item.fileUrl
            });
          });
        }
        this.form = info;
      });
    },
    getMainState() {
      this.$axios({
        url: "/p/utils/dict/getType",
        params: {
          dictType: "industry_subject_status"
        }
      }).then(res => {
        this.mainState = res.data.data;
      });
    },
    getTownData() {
      const { areaCode } = this.userInfo.industrySubject;
      this.$axios({
        url: "/p/common/getTownsByCountyCode",
        params: {
          areaCode: areaCode
        }
      }).then(res => {
        this.townDate = res.data.data;
      });
    },
    getForm() {
      this.$axios({
        url: "/p/industry/base/saveBase",
        method: "post",
        data: this.form,
        json: true
      }).then(res => {
        if (res.data.code == 0) {
          this.$alert("您已成功提交！", "成功", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: () => {
              this.$router.push("/Base");
            }
          }).catch(() => {
            this.$message({
              type: "error",
              message: "成功取消"
            });
          });
        } else {
          this.$alert(res.data.msg, "错误提示", {
            confirmButtonText: "我知道了"
          });
        }
      });
    },

    // 上传相关材料
    uploadFile(response, file, fileList) {
      // 赋值给 form.attachment
      this.dealFile(fileList);
    },
    // 删除相关材料
    removeFile(file, fileList) {
      this.dealFile(fileList);
    },

    dealFile(fileList) {
      const fileIds = [];
      fileList.forEach(item => {
        if (item.response) {
          fileIds.push(item.response.data.fileId);
        } else {
          fileIds.push(item.id);
        }
      });
      this.form.attachment = fileIds.join(",");
    },
    // 上传相关照片
    uploadImg(response, file) {
      const fileIds = this.form.images.split(",");
      fileIds.unshift("/chainer" + file.response.data.fileUrl);
      this.form.images = fileIds.join(",");
    },
    // 删除相关照片
    removeImg(index) {
      const fileIds = this.form.images.split(",");
      fileIds.splice(index, 1);
      this.form.images = fileIds.join(",");
    },
    changeTown(value) {
      this.townDate.forEach(item => {
        if (item.code === value) {
          this.form.townName = item.name;
        }
      });
    },
    handleBack() {
      this.$router.back();
    },
    // 提交的验证
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log("success");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      this.getForm();
    }
  }
};
</script>
<style scoped>
.w {
  background-color: #fff;
}
.sub-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0 8px;
}
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form .el-form-item {
  margin-bottom: 0;
}
.form .el-form-item__error {
  font-size: 11px;
  right: 5px;
  left: initial;
  top: 0px;
}
.form-item {
  display: flex;
  width: 50%;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  margin-top: -1px;
  margin-left: -1px;
}
.form-item span {
  font-size: 14px;
  background-color: #eee;
  display: flex;
  align-items: center;
  color: #333;
  width: 150px;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
}
.form-item-content {
  flex: 1;
  padding: 12px 10px;
  border-left: 1px solid #d2d2d2;
}
.percent {
  width: 100%;
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 0 80px;
}
.submit {
  margin-left: 40px;
}
.position {
  margin-top: 40px;
}

.picture-img {
  padding: 5px;
}
.upload-flex {
  display: flex;
  flex-wrap: wrap;
}
.upload-img {
  position: relative;
  width: 73px;
  height: 73px;
  padding: 5px;
  display: flex;
}
.delimg {
  position: absolute;
  top: -9px;
  right: -9px;
  transform: translate(-30%, 30%);
  font-size: 18px;
  color: #f56c6c;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}
</style>
