// import router from "../router";
import axios from "axios";
import qs from "qs";
// 引入vuex
// import store from "./store";
// import { BASE_URL } from "../config";
// axios.defaults.baseURL = `${BASE_URL}/chainer/p`;
axios.defaults.baseURL = `/chainer`;
axios.defaults.headers = {
  "Request-source": "portal"
};
// 自动写入cookie
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function(response) {
    // const info = response.data;
    // if (info.code === 501 && info.msg === "未登陆!") {
    //   store.commit("removeUserInfo");
    //   sessionStorage.removeItem("userInfo");
    //   router.push("/Login");
    // }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);
const $axios = ({ url, data, params, method = "get", json = false } = {}) => {
  return axios({
    url,
    params,
    method,
    data: json ? data : qs.stringify(data),
    headers: {
      "Content-Type": json
        ? "application/json"
        : "application/x-www-form-urlencoded"
    }
  });
};
export default $axios;
